import { FunctionComponent, useEffect, useState } from 'react';

import CalculateIcon from '@mui/icons-material/Calculate';
import {
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Box,
  Typography,
  Alert,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Card,
  FormHelperText,
  Divider
} from '@mui/material';

import { Averaging, Position, SimulatorSettings } from '../../../types/entities';
import { setThousenSeparator } from '../../../utils/currency-utils';

import { AveragingResult, testAveraging } from './averaging-test-utils_v3';

interface Props {
  position: Position;
  averagingForm: Averaging;
  onSave: (settings: SimulatorSettings) => void;
}

const SimulatorPanel: FunctionComponent<Props> = ({ position, averagingForm, onSave }) => {
  const initialSettings: SimulatorSettings = {
    initialTradePrice: position.currentMarketPrice || 0,
    volatilityPct: 1,
    trendBiasPct: 45,
    useBiasRangeBound: true,
    maxTurns: 0
  };
  const [simulatorSettingsForm, setSimulatorSettingsForm] = useState(position.simulator ?? initialSettings);
  useEffect(() => {
    setSimulatorSettingsForm(position.simulator ?? initialSettings);
  }, [position]);

  const [avgResult, setAvgResult] = useState<AveragingResult | undefined>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSimulatorSettingsForm = (field: keyof SimulatorSettings, value: any) => {
    const copy = {
      ...simulatorSettingsForm,
      [field]: value
    } as SimulatorSettings;
    setSimulatorSettingsForm(copy);
  };

  const [initialTradePriceError, setInitialTradePriceError] = useState(false);

  const handleCalculateBtnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const initialTradePriceRequired = simulatorSettingsForm.initialTradePrice === 0;
    setInitialTradePriceError(initialTradePriceRequired);
    if (initialTradePriceRequired) {
      return;
    }

    const multiplier = position.details.contract.multiplier;
    const multiplierNum = multiplier ? Number(multiplier) : 1;

    const result = testAveraging(multiplierNum, averagingForm, simulatorSettingsForm);
    setAvgResult(result);

    onSave(simulatorSettingsForm);
  };

  return (
    <Card sx={{ mt: 2 }}>
      <Box p={2}>
        <Typography variant="h6" component="div">
          Automation Simulator
        </Typography>
      </Box>
      <Grid p={2} container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl sx={{ width: '100%' }} error={initialTradePriceError}>
            <InputLabel>Initial Trade Price</InputLabel>
            <OutlinedInput
              value={simulatorSettingsForm.initialTradePrice}
              onChange={(e) => handleSimulatorSettingsForm('initialTradePrice', Number(e.target.value))}
              size="small"
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Initial Trade Price"
            />
            {initialTradePriceError && <FormHelperText>Initial Trade Price must have a value</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            label="Volatility Pct"
            variant="outlined"
            type="number"
            value={simulatorSettingsForm.volatilityPct}
            onChange={(e) => handleSimulatorSettingsForm('volatilityPct', Number(e.target.value))}
            helperText="How much the price fluctuates (in %) for each turn"
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid p={2} container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={6}>
          <TextField
            size="small"
            label="Trend Bias Pct"
            variant="outlined"
            type="number"
            value={simulatorSettingsForm.trendBiasPct}
            onChange={(e) => handleSimulatorSettingsForm('trendBiasPct', Number(e.target.value))}
            helperText="The speculative strength of the trend (in %)"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', gap: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Switch
                sx={{ ml: 1 }}
                onChange={() => handleSimulatorSettingsForm('useBiasRangeBound', !simulatorSettingsForm.useBiasRangeBound)}
                checked={simulatorSettingsForm.useBiasRangeBound}
              />
            }
            label={<Typography variant="caption">Use Bias Range Bound</Typography>}
          />
        </Grid>
      </Grid>
      {avgResult && (
        <Alert sx={{ mb: 2, mx: 1, px: 1 }} severity={avgResult.sumPnl > 0 ? 'success' : 'error'} variant="outlined">
          Calculation Details:
          <Grid container mt={1} spacing={1} alignItems="center">
            <Grid item xs={8} sm={6} textAlign="right">
              Flattening (subtracting)
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              {avgResult.numFlattening} times
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Averaging (adding)
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              {avgResult.numAveraging} times
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Max Position Size
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              {avgResult.maxPositionSize}
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Total Turns
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              {avgResult.turns}
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Lowest Filled
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              {Math.min(...avgResult.trades.map((x) => x.filledPrice)).toFixed(2)}
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Highest Filled
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              {Math.max(...avgResult.trades.map((x) => x.filledPrice || 0)).toFixed(2)}
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Sum Profit And Loss
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              ${setThousenSeparator(avgResult.sumPnl.toFixed(0))}
            </Grid>
            <Grid item xs={8} sm={6} textAlign="right">
              Average PnL
            </Grid>
            <Grid item xs={4} sm={6} sx={{ fontWeight: 'bold', pl: 1 }}>
              ${setThousenSeparator(avgResult.avgPnl.toFixed(0))}
            </Grid>
          </Grid>
        </Alert>
      )}
      <Grid px={2} pb={2} container justifyContent="flex-end">
        <Button endIcon={<CalculateIcon />} variant="outlined" onClick={handleCalculateBtnClick}>
          Calculate
        </Button>
      </Grid>
    </Card>
  );
};

export default SimulatorPanel;
