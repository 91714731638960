import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Box,
  Card,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
  TextField
} from '@mui/material';

import { useCheckMarginAsync } from '../../components/hooks/useCheckMarginAsync';
import { usePlaceOrderAsync } from '../../components/hooks/usePlaceOrderAsync';
import GutterBox from '../../components/ui/GutterBox';
import Spinner from '../../components/ui/Spinner';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getMarketDataStateByConId } from '../../store/market-data/selectors';
import { getMarketDataAsync } from '../../store/market-data/service';
import { MarketDataParams, OrderAction, OrderState, OrderType, PlaceOrderParams, Position, PositionPortfolio } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import OrderChartPanel from './OrderChartPanel';
import OrderOptionPanel from './OrderOptionPanel';
import { getOrderCombinationErrorMessage, getOrderCombinationHelperMessage } from './utils';

interface Props {
  position: Position;
  portfolio: PositionPortfolio;
}

const PlaceOrderPanel: FunctionComponent<Props> = ({ position, portfolio }: Props) => {
  const { placeOrderAsync, loading: placeOrderLoading } = usePlaceOrderAsync();
  const { checkMarginAsync, loading: checkMarginLoading } = useCheckMarginAsync();
  const { contract } = position.details;
  const underlyingConId = position.details.underConId;
  const isOptionContract = contract.secType === 'OPT' || contract.secType === 'FOP';
  const isStockOrStockOption = contract.secType === 'STK' || contract.secType === 'OPT';

  const { useFrozen } = useAppSelector((gs) => gs.userSettingsState);
  const marketDataState = useAppSelector((gs) => gs.marketDataState);
  const { marketData, loading, error } = getMarketDataStateByConId(marketDataState, contract.conId);
  const {
    marketData: underlyingMarketData,
    loading: loadingUnderlying,
    error: errorUnderlying
  } = getMarketDataStateByConId(marketDataState, underlyingConId);

  const [orderAction, setOrderAction] = useState<OrderAction>(portfolio.size < 0 ? 'BUY' : 'SELL');
  const [orderType, setOrderType] = useState<OrderType>('MKT');
  const [tif, setTif] = useState<'GTC' | 'IOC'>('IOC');
  const [orderSize, setOrderSize] = useState(Math.abs(portfolio.size) || 1);
  const [outsideRth, setOutsideRth] = useState(!isStockOrStockOption);
  const [triggerPrice, setTriggerPrice] = useState<number>(0);
  const [marketPrice, setMarketPrice] = useState<number>(0);

  const [usePriceCondition, setUsePriceCondition] = useState(false);
  const [underlyingTriggerPrice, setUnderlyingTriggerPrice] = useState(0);
  const [underlyingMarketPrice, setUnderlyingMarketPrice] = useState(0);
  const [isAbovePrice, setIsAbovePrice] = useState(true);
  const [marginRequirement, setMarginRequirement] = useState(0);

  useEffect(() => {
    if (marketData) {
      if (orderAction === 'BUY') {
        setMarketPrice(marketData.askPrice);
        setTriggerPrice(marketData.askPrice);
      } else {
        setMarketPrice(marketData.bidPrice);
        setTriggerPrice(marketData.bidPrice);
      }
    }
  }, [orderAction, marketData]);

  useEffect(() => {
    if (underlyingMarketData) {
      if (orderAction === 'BUY') {
        setUnderlyingMarketPrice(underlyingMarketData.askPrice);
        setUnderlyingTriggerPrice(underlyingMarketData.askPrice);
      } else {
        setUnderlyingMarketPrice(underlyingMarketData.bidPrice);
        setUnderlyingTriggerPrice(underlyingMarketData.bidPrice);
      }
    }
  }, [orderAction, underlyingMarketData]);

  const orderCombinationHelperMessage = useMemo(() => {
    return getOrderCombinationHelperMessage(orderAction, orderType, portfolio.size);
  }, [orderType, orderAction, portfolio]);

  const orderCombinationErrorMessage = useMemo(() => {
    return getOrderCombinationErrorMessage(orderAction, orderType, triggerPrice, marketPrice);
  }, [orderAction, orderType, triggerPrice, marketPrice]);

  const limitPrice = useMemo(() => {
    if (isOptionContract) {
      return isAbovePrice ? underlyingTriggerPrice : undefined;
    } else if (!isOptionContract && orderType == 'LMT') {
      return triggerPrice;
    }
  }, [isAbovePrice, orderType, isOptionContract, triggerPrice, underlyingTriggerPrice]);

  const stopPrice = useMemo(() => {
    if (isOptionContract) {
      return !isAbovePrice ? underlyingTriggerPrice : undefined;
    } else if (!isOptionContract && orderType == 'STP') {
      return triggerPrice;
    }
  }, [isAbovePrice, orderType, isOptionContract, triggerPrice, underlyingTriggerPrice]);

  const dispatch = useAppDispatch();

  const refreshMarketData = (conId: number, secType: string, localSymbol: string) => {
    const params: MarketDataParams = {
      conId,
      symbol: contract.symbol,
      localSymbol,
      exchange: contract.exchange,
      currency: contract.currency,
      secType: secType,
      right: contract.right,
      strike: contract.strike,
      lastTradeDateOrContractMonth: contract.lastTradeDateOrContractMonth,
      multiplier: contract.multiplier,
      pricesOnly: false,
      noIndicators: true,
      useFrozen
    };
    dispatch(getMarketDataAsync(params));
  };

  const handleRefreshBtnClick = () => {
    refreshMarketData(contract.conId, contract.secType, contract.localSymbol);
  };

  const handleRefreshUnderlyingBtnClick = () => {
    refreshMarketData(position.details.underConId, position.details.underSecType, position.details.underSymbol);
  };

  const handleOrderTypeBtnClick = (type: 'MKT' | 'LMT' | 'STP') => {
    const tif = orderType === 'MKT' ? 'IOC' : 'GTC';
    setTif(tif);
    setOrderType(type);
  };

  const handlePlaceOrderBtnClick = () => {
    const params = {
      details: {
        ...position.details,
        contract: { ...position.details.contract }
      },
      orderAction,
      orderType,
      orderSize,
      triggerPrice,
      outsideRth,
      tif,
      usePriceCondition,
      underlyingTriggerPrice,
      isAbove: isAbovePrice
    } as PlaceOrderParams;

    placeOrderAsync(params);
  };

  // todo: fix loading variable for update margin req
  const handleUpdateMarginReqBtnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const params = {
      details: {
        ...position.details,
        contract: { ...position.details.contract }
      },
      orderAction,
      orderType: 'MKT',
      orderSize,
      triggerPrice: 0,
      outsideRth: false,
      tif: 'GTC',
      usePriceCondition: false,
      underlyingTriggerPrice: 0,
      isAbove: false
    } as PlaceOrderParams;
    checkMarginAsync(params, (orderState: OrderState) => {
      Logger.log(orderState);
      const { initMarginChange } = orderState;
      const initMarginChangeNum = Number(initMarginChange) || 0;
      const initMarginChangeNumFormatted = Number(initMarginChangeNum.toFixed(0));
      setMarginRequirement(initMarginChangeNumFormatted);
    });
  };

  return (
    <>
      <OrderChartPanel position={position} orderAction={orderAction} limitPrice={limitPrice} stopPrice={stopPrice} />

      <Card sx={{ mb: 2 }}>
        <Box sx={{ p: 2 }}>
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }} component="div">
              Order Setup
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12} md={6} lg={orderType === 'MKT' ? 6 : 4}>
                <ButtonGroup variant="outlined" disableElevation fullWidth>
                  <Button onClick={() => setOrderAction('BUY')} variant={orderAction === 'BUY' ? 'contained' : 'outlined'}>
                    Buy
                  </Button>
                  <Button onClick={() => setOrderAction('SELL')} variant={orderAction === 'SELL' ? 'contained' : 'outlined'}>
                    Sell
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={12} md={6} lg={orderType === 'MKT' ? 6 : 4}>
                <>
                  <ButtonGroup variant="outlined" fullWidth>
                    <Button onClick={() => handleOrderTypeBtnClick('MKT')} variant={orderType === 'MKT' ? 'contained' : 'outlined'}>
                      Market
                    </Button>
                    <Button onClick={() => handleOrderTypeBtnClick('LMT')} variant={orderType === 'LMT' ? 'contained' : 'outlined'}>
                      Limit
                    </Button>
                    <Button onClick={() => handleOrderTypeBtnClick('STP')} variant={orderType === 'STP' ? 'contained' : 'outlined'}>
                      Stop
                    </Button>
                  </ButtonGroup>
                  {orderCombinationHelperMessage && <FormHelperText>{orderCombinationHelperMessage}</FormHelperText>}
                </>
              </Grid>
              {orderType !== 'MKT' && (
                <Grid item xs={12} md={6} lg={4}>
                  <Box sx={{ position: 'relative', display: 'flex', gap: 1, alignItems: 'center' }}>
                    <FormControl sx={{ width: '100%' }} error={!!orderCombinationErrorMessage}>
                      <InputLabel>Trigger Price</InputLabel>
                      <OutlinedInput
                        sx={{ paddingRight: '32px' }}
                        value={triggerPrice}
                        onChange={(e) => setTriggerPrice(Number(e.target.value))}
                        size="small"
                        type="number"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        label="Trigger Price"
                      />
                      {orderCombinationErrorMessage && <FormHelperText>{orderCombinationErrorMessage}</FormHelperText>}
                    </FormControl>
                    <Tooltip title="Refresh Market Data" sx={{ position: 'absolute', top: '5px', right: '8px' }}>
                      <IconButton size="small" onClick={handleRefreshBtnClick}>
                        <RefreshIcon sx={{ fontSize: 20 }} />
                        {loading && (
                          <CircularProgress
                            size={30}
                            sx={{
                              color: 'white',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              zIndex: 1
                            }}
                          />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
              )}
              <Grid item xs={6}>
                <ButtonGroup variant="outlined" disableElevation fullWidth>
                  <Tooltip title="Regular Trading Hours">
                    <Button onClick={() => setOutsideRth(false)} variant={!outsideRth ? 'contained' : 'outlined'}>
                      RTH
                    </Button>
                  </Tooltip>
                  <Tooltip title="All Trading Hours (including pre-/post-market)">
                    <Button onClick={() => setOutsideRth(true)} variant={outsideRth ? 'contained' : 'outlined'}>
                      Outside
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </Grid>
              <Grid item xs={6}>
                <ButtonGroup variant="outlined" disableElevation fullWidth>
                  <Tooltip title="Good Til Cancel">
                    <Button onClick={() => setTif('GTC')} variant={tif === 'GTC' ? 'contained' : 'outlined'}>
                      GTC
                    </Button>
                  </Tooltip>
                  <Tooltip title="Instant Or Cancel">
                    <Button onClick={() => setTif('IOC')} variant={tif === 'IOC' ? 'contained' : 'outlined'}>
                      IOC
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }} error={orderSize < 1}>
                  <TextField
                    size="small"
                    label="Order Size"
                    variant="outlined"
                    type="number"
                    value={orderSize}
                    onChange={(e) => setOrderSize(Number(e.target.value))}
                    fullWidth
                    error={orderSize < 1}
                  />
                  {orderSize < 1 && <FormHelperText>Order size must be greater than 1</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel>Margin Requirement</InputLabel>
                  <OutlinedInput
                    value={marginRequirement}
                    onChange={(e) => setMarginRequirement(Number(e.target.value))}
                    size="small"
                    type="number"
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label="Margin Requirement"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <Button variant="outlined" onClick={handleUpdateMarginReqBtnClick}>
                    Check Margin {checkMarginLoading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            {isOptionContract && (
              <OrderOptionPanel
                orderType={orderType}
                usePriceCondition={usePriceCondition}
                setUsePriceCondition={setUsePriceCondition}
                underlyingMarketPrice={underlyingMarketPrice}
                underlyingTriggerPrice={underlyingTriggerPrice}
                setUnderlyingTriggerPrice={setUnderlyingTriggerPrice}
                isAbovePrice={isAbovePrice}
                setIsAbovePrice={setIsAbovePrice}
                onRefreshUnderlying={handleRefreshUnderlyingBtnClick}
                loadingUnderlying={loadingUnderlying}
              />
            )}
          </Box>
        </Box>
        {(error || errorUnderlying) && (
          <Alert sx={{ mb: 2, mx: 2 }} color="error">
            Error loading market data..
          </Alert>
        )}
      </Card>

      <GutterBox sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="success" variant="contained" onClick={handlePlaceOrderBtnClick}>
          Place Order
        </Button>
      </GutterBox>
      <Spinner loading={placeOrderLoading} />
    </>
  );
};

export default PlaceOrderPanel;
