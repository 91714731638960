import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { GRAY_COLOR, INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR, INDICATOR_VLT_COLOR } from '../../../assets/colors';
import { OrderAction } from '../../../types/entities';
import KeyIndicator from '../../ui/KeyIndicator';

const getStrikeVltColor = (
  orderAction: OrderAction,
  entryToExpireHistVlt: number | undefined,
  entryToExpireImpliedVlt: number | undefined,
  entryToStrikeVlt: number | undefined
) => {
  if (!entryToStrikeVlt || !entryToExpireHistVlt || !entryToExpireImpliedVlt) {
    return undefined;
  }
  if (orderAction === 'BUY') {
    const isHalfOrLower = entryToStrikeVlt <= entryToExpireHistVlt / 2 && entryToStrikeVlt <= entryToExpireImpliedVlt / 2;
    if (isHalfOrLower) {
      return INDICATOR_GREEN_COLOR;
    }
    const isEqualOrMore = entryToStrikeVlt >= entryToExpireHistVlt || entryToStrikeVlt >= entryToExpireImpliedVlt;
    if (isEqualOrMore) {
      return INDICATOR_RED_COLOR;
    }
  } else {
    const isTwiceOrMore = entryToStrikeVlt >= entryToExpireHistVlt * 2 && entryToStrikeVlt >= entryToExpireImpliedVlt * 2;
    if (isTwiceOrMore) {
      return INDICATOR_GREEN_COLOR;
    }
    const isEqualOrLess = entryToStrikeVlt <= entryToExpireHistVlt || entryToStrikeVlt <= entryToExpireImpliedVlt;
    if (isEqualOrLess) {
      return INDICATOR_RED_COLOR;
    }
  }
  return GRAY_COLOR;
};

const createKeyIndicator = (title: string, vlt: number | undefined, color?: string | undefined) => {
  if (!vlt) {
    return undefined;
  }

  const vltPct = vlt * 100;
  const vltLabel = `${vltPct.toFixed(2)}%`;
  return <KeyIndicator title={title} value={vltLabel} valueColor={color} />;
};

interface Props {
  orderAction: OrderAction;
  entryToExpireHistVlt?: number | undefined;
  entryToExpireImpliedVlt?: number | undefined;
  entryToStrikeVlt?: number | undefined;
  entryToDateVlt?: number | undefined;
}

const OptionVolatilityPanel: FunctionComponent<Props> = ({
  orderAction,
  entryToExpireHistVlt,
  entryToExpireImpliedVlt,
  entryToStrikeVlt,
  entryToDateVlt
}: Props) => {
  const historicVltIndicator = createKeyIndicator('Hist Vlt', entryToExpireHistVlt);
  const impliedVltIndicator = createKeyIndicator(
    'Impl Vlt',
    entryToExpireImpliedVlt,
    entryToExpireHistVlt && entryToExpireImpliedVlt && entryToExpireImpliedVlt > entryToExpireHistVlt ? INDICATOR_VLT_COLOR : GRAY_COLOR
  );
  const strikeVltColor = getStrikeVltColor(orderAction, entryToExpireHistVlt, entryToExpireImpliedVlt, entryToStrikeVlt);
  const strikeVltIndicator = createKeyIndicator('Strike Vlt', entryToStrikeVlt, strikeVltColor);
  const currentVltIndicator = createKeyIndicator('Curr Vlt', entryToDateVlt);

  const hasIndicators = !!historicVltIndicator || !!impliedVltIndicator || !!strikeVltIndicator || !!currentVltIndicator;

  return (
    <Box>
      {hasIndicators && (
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 1 }}>
          {historicVltIndicator}
          {impliedVltIndicator}
          {strikeVltIndicator}
          {currentVltIndicator}
        </Box>
      )}
    </Box>
  );
};

export default OptionVolatilityPanel;
