import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

import { useAppDispatch } from '../../../store/hooks';
import { createTrendLineAsync, removeTrendLineAsync, updateTrendLineAsync } from '../../../store/trend-line/service';
import { Contract, TrendLine } from '../../../types/entities';
import { BarSize, PriceAlertType } from '../../../types/enums';
import DialogCloseIconButton from '../../ui/DialogCloseIconButton';

import { TREND_LINE_COLOR_OPTIONS } from './color-options';

interface Props {
  contract: Contract;
  isOpen: boolean;
  onClose: () => void;
  trendLine?: TrendLine;
  yAxis?: number | undefined;
}

const TrendLineDialog: FunctionComponent<Props> = ({ contract, isOpen, onClose, trendLine, yAxis }: Props) => {
  const exchange = contract.exchange ?? contract.primaryExch ?? '';
  const { secType } = contract;
  const tradeLineTemplate: TrendLine = useMemo(
    () => ({
      id: '',
      conId: contract.conId,
      symbol: contract.symbol,
      exchange,
      secType,
      currency: contract.currency,
      name: '',
      color: '#ff9800',
      price: yAxis ?? 0,
      alertType: PriceAlertType.None,
      specificOnly: false,
      specificBarSize: BarSize._1_DAY
    }),
    [contract, exchange, secType, yAxis]
  );

  const [tradeLineForm, setTradeLineForm] = useState<TrendLine>(trendLine || tradeLineTemplate);

  useEffect(() => {
    if (trendLine) {
      setTradeLineForm(trendLine);
    } else {
      setTradeLineForm(tradeLineTemplate);
    }
  }, [tradeLineTemplate, trendLine]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateTradeLineForm = (field: keyof TrendLine, value: any) => {
    const copy = {
      ...tradeLineForm,
      [field]: value
    } as TrendLine;
    setTradeLineForm(copy);
  };

  const dispatch = useAppDispatch();

  const handleSaveLine = () => {
    const isNewLine = !trendLine;
    if (isNewLine) {
      dispatch(createTrendLineAsync(tradeLineForm));
    } else {
      dispatch(updateTrendLineAsync(tradeLineForm));
    }
    onClose();
  };

  const handleRemoveLine = () => {
    const isNewLink = !trendLine;
    if (!isNewLink) {
      dispatch(removeTrendLineAsync(tradeLineForm));
    }
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogTitle sx={{ pl: 2 }}>Trend Line</DialogTitle>
      <DialogCloseIconButton onClose={onClose} />
      <DialogContent sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <Typography variant="caption" component="div" ml={1} mb={1}>
            Color
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
            {TREND_LINE_COLOR_OPTIONS.map((opt) => (
              <IconButton
                key={opt.value}
                onClick={() => updateTradeLineForm('color', opt.value)}
                sx={{
                  backgroundColor: opt.value,
                  height: '32px',
                  width: '32px',
                  outline: opt.value === tradeLineForm.color ? '3px solid white' : 'none'
                }}
                aria-label={opt.colorName}
              />
            ))}
          </Box>
        </Box>

        <FormControl>
          <TextField
            name="price"
            size="small"
            label="Price"
            variant="outlined"
            type="number"
            value={tradeLineForm.price || ''}
            onChange={(e) => updateTradeLineForm('price', parseFloat(e.target.value))}
          />
        </FormControl>

        <Grid container>
          <Grid item xs={12}>
            <ButtonGroup variant="outlined" disableElevation fullWidth>
              <Button
                onClick={() => updateTradeLineForm('alertType', PriceAlertType.None)}
                variant={tradeLineForm.alertType === PriceAlertType.None ? 'contained' : 'outlined'}
              >
                No Alert
              </Button>
              <Button
                onClick={() => updateTradeLineForm('alertType', PriceAlertType.Above)}
                variant={tradeLineForm.alertType === PriceAlertType.Above ? 'contained' : 'outlined'}
              >
                Alert Above
              </Button>
              <Button
                onClick={() => updateTradeLineForm('alertType', PriceAlertType.Below)}
                variant={tradeLineForm.alertType === PriceAlertType.Below ? 'contained' : 'outlined'}
              >
                Alert Below
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>

        <FormControl>
          <TextField
            name="name"
            size="small"
            label="Comment"
            variant="outlined"
            type="text"
            value={tradeLineForm.name || ''}
            onChange={(e) => updateTradeLineForm('name', e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={tradeLineForm.specificOnly}
                onChange={() => updateTradeLineForm('specificOnly', !tradeLineForm.specificOnly)}
              />
            }
            label="Specific Chart Type Only"
            sx={{ minWidth: '200px' }}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="security-label" size="small">
            Type
          </InputLabel>
          <Select
            name="specificBarSize"
            labelId="type"
            value={tradeLineForm.specificBarSize}
            label="Bar Size"
            onChange={(e) => updateTradeLineForm('specificBarSize', e.target.value)}
            disabled={!tradeLineForm.specificOnly}
            size="small"
          >
            <MenuItem value={BarSize._1_DAY}>DAY</MenuItem>
            <MenuItem value={BarSize._1_HR}>HOUR</MenuItem>
            <MenuItem value={BarSize._15_MIN}>15MIN</MenuItem>
          </Select>
        </FormControl>
        <Box display="flex" justifyContent="flex-end">
          <FormControl>
            <Tooltip title="Toggle Hide/Show Trend Line">
              <IconButton size="small" onClick={() => updateTradeLineForm('hidden', !tradeLineForm.hidden)}>
                {tradeLineForm.hidden === true ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Tooltip>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {trendLine && (
          <Box sx={{ display: 'flex', gap: 1, flexGrow: 1, justifyContent: 'flex-start' }}>
            <Button variant="contained" color="error" onClick={handleRemoveLine}>
              Remove
            </Button>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={handleSaveLine}>
            Save
          </Button>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default TrendLineDialog;
