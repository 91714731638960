import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { RESEARCH_PAGE_DAILY_CHART_ID, RESEARCH_PAGE_HOUR_CHART_ID } from '../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setDailyGraphCollapsed, setHourGraphCollapsed, setTrendLinesCollapsed } from '../../store/user-settings/reducer';
import { Contract } from '../../types/entities';
import { BarSize } from '../../types/enums';
import BarChartPanel from '../panels/BarChartPanel';
import TrendLinePanel from '../panels/TrendLinePanel';
import AccordionWrapper from '../ui/AccordionWrapper';

interface Props {
  contract: Contract;
  entryPrice?: number | undefined;
  strikePrice?: number | undefined;
}
const MultiChartPanel: FunctionComponent<Props> = ({ contract, entryPrice, strikePrice }: Props) => {
  const dispatch = useAppDispatch();
  const userSettingsState = useAppSelector((gs) => gs.userSettingsState);
  const { hourGraphCollapsed, dailyGraphCollapsed } = userSettingsState;
  const toggleDailyGraphCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setDailyGraphCollapsed(!expanded));
  const toggleHourGraphCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setHourGraphCollapsed(!expanded));
  const { trendLinesCollapsed } = useAppSelector((gs) => gs.userSettingsState);
  const toggleTrendLinesCollapsed = () => dispatch(setTrendLinesCollapsed(!trendLinesCollapsed));

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <AccordionWrapper title="Daily Barchart" collapsed={dailyGraphCollapsed} onChange={toggleDailyGraphCollapsed}>
          <BarChartPanel
            barChartId={RESEARCH_PAGE_DAILY_CHART_ID}
            symbol={contract.symbol}
            conId={contract.conId}
            title={contract.localSymbol}
            exchange={contract.exchange}
            initialBarSize={BarSize._1_DAY}
            initialDuration={100}
            entryPrice={entryPrice}
            strikePrice={strikePrice}
            secType={contract.secType}
          />
        </AccordionWrapper>
      </Box>
      <Box sx={{ mb: 2 }}>
        <AccordionWrapper title="Hour Barchart" collapsed={hourGraphCollapsed} onChange={toggleHourGraphCollapsed}>
          <BarChartPanel
            barChartId={RESEARCH_PAGE_HOUR_CHART_ID}
            symbol={contract.symbol}
            conId={contract.conId}
            title={contract.localSymbol}
            exchange={contract.exchange}
            initialBarSize={BarSize._1_HR}
            initialDuration={5}
            entryPrice={entryPrice}
            strikePrice={strikePrice}
            loadTrendLines={false}
            secType={contract.secType}
          />
        </AccordionWrapper>
      </Box>
      <AccordionWrapper title="Trend Lines" collapsed={trendLinesCollapsed} onChange={toggleTrendLinesCollapsed}>
        <TrendLinePanel contract={contract} />
      </AccordionWrapper>
    </Box>
  );
};

export default MultiChartPanel;
