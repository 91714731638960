export const SPECIAL_BTN_COLOR = '#ff4f00';
export const SPECIAL_BTN_HOVER_COLOR = '#b33700';

export const INDICATOR_GREEN_COLOR = '#00a86b';
export const INDICATOR_RED_COLOR = '#e52b50';
export const INDICATOR_YELLOW_COLOR = '#ffea00';
export const INDICATOR_INFO_COLOR = '#00bfff';
export const INDICATOR_VLT_COLOR = '#ff9800';
export const INDICATOR_NA_COLOR = '#ccc';

export const WHITE_COLOR = '#fff';
export const GRAY_COLOR = '#808080';
export const GOLD_COLOR = '#ffd700';
export const SIGNAL_GREEN_COLOR = '#1ff462';
export const STRIKE_COLOR = '#ffd700';
export const AVG_COLOR = '#ace1af';

export const TABLE_BORDER_COLOR = '#515151';
