import { configureStore, ThunkAction, Action, combineReducers, PreloadedState } from '@reduxjs/toolkit';

import accountInfoReducer from './account-info/reducer';
import authReducer from './auth/reducer';
import dashboardReducer from './dashboard/reducer';
import favoriteContractReducer from './favorite-contract/reducer';
import foundationDataReducer from './foundation-data/reducer';
import fundamentalLinkReducer from './fundamental-link/reducer';
import fundamentalNoteReducer from './fundamental-note/reducer';
import historicalDataReducer from './historical-data/reducer';
import marketDataReducer from './market-data/reducer';
import openOrderReducer from './open-order/reducer';
import orderSummaryReducer from './order-summary/reducer';
import positionReducer from './position/reducer';
import positionPortfolioReducer from './position-portfolio/reducer';
import priceAlertReducer from './price-alert/reducer';
import quickNoteReducer from './quick-note/reducer';
import securityReducer from './security/reducer';
import securityContractReducer from './security-contract/reducer';
import tradeHistoryReducer from './trade-history/reducer';
import tradingClassReducer from './trading-class/reducer';
import trendLineReducer from './trend-line/reducer';
import userSettingsReducer from './user-settings/reducer';

export const rootReducer = combineReducers({
  userSettingsState: userSettingsReducer,
  authState: authReducer,
  accountInfoState: accountInfoReducer,
  tradingClassState: tradingClassReducer,
  securityContractState: securityContractReducer,
  marketDataState: marketDataReducer,
  positionState: positionReducer,
  openOrderState: openOrderReducer,
  historicalDataState: historicalDataReducer,
  securityState: securityReducer,
  fundamentalNoteState: fundamentalNoteReducer,
  dashboardState: dashboardReducer,
  favoriteContractState: favoriteContractReducer,
  tradeHistoryState: tradeHistoryReducer,
  fundamentalLinkState: fundamentalLinkReducer,
  trendLineState: trendLineReducer,
  orderSummaryState: orderSummaryReducer,
  foundationDataState: foundationDataReducer,
  quickNoteState: quickNoteReducer,
  positionPortfolioState: positionPortfolioReducer,
  priceAlertState: priceAlertReducer
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
