import { useState } from 'react';

import { toast } from 'react-toastify';

import { backtestPosition } from '../../api';
import { BacktestParams, BacktestResult } from '../../types/entities';
import { Logger } from '../../utils/Logger';

export const useBacktestPosition = () => {
  const [loading, setLoading] = useState(false);

  const backtestPositionCall = (params: BacktestParams) => {
    return new Promise<BacktestResult>((resolve, reject) => {
      backtestPosition(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const msg = error.response?.data.message || error.message;
          toast.error(msg);
          reject();
        });
    });
  };

  const backtestPositionAsync = async (params: BacktestParams) => {
    setLoading(true);
    try {
      return await backtestPositionCall(params);
    } catch (error) {
      Logger.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    backtestPositionAsync,
    loading
  };
};
