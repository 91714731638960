import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { exchanges } from '../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { createSecurityAsync, updateSecurityAsync, removeSecurityAsync } from '../../store/security/service';
import { Security } from '../../types/entities';
import { SecurityCategory, SecuritySector } from '../../types/enums';
import { useConfirm } from '../hooks/useConfirm';

interface Props {
  security?: Security;
  securities: Security[];
  category?: SecurityCategory | undefined;
  onClose?: () => void;
}

const SecurityGeneralPanel: FunctionComponent<Props> = ({ security, securities, category, onClose }: Props) => {
  const navigate = useNavigate();
  const { loaded, error } = useAppSelector((gs) => gs.securityState);
  const [actionPressed, setActionPressed] = useState(false);
  const [confirm, ConfirmDialog] = useConfirm();

  const securityTemplate: Security = {
    id: '',
    name: '',
    symbol: '',
    secType: 'STK',
    exchange: 'SMART',
    currency: 'USD',
    category: category ?? SecurityCategory.Stock,
    sector: SecuritySector.Unspecificed,
    shortList: false,
    subscribed: true,
    disabled: false
  };

  const [securityForm, setSecurityForm] = useState<Security>(security || securityTemplate);

  useEffect(() => {
    if (security) {
      setSecurityForm(security);
    }
  }, [security]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSecurityForm = (field: keyof Security, value: any) => {
    const copy = {
      ...securityForm,
      [field]: value
    } as Security;
    setSecurityForm(copy);
  };

  const setSecurityFormCategory = (category: SecurityCategory) => {
    const copy = {
      ...securityForm,
      category,
      secType: category === SecurityCategory.Stock ? 'STK' : 'FUT'
    } as Security;
    setSecurityForm(copy);
  };

  const handleCloseDialog = () => {
    setActionPressed(false);
    if (onClose) {
      onClose();
    }
  };

  // auto-close on save successfully
  useEffect(() => {
    if (actionPressed && loaded && !error) {
      handleCloseDialog();
    }
  });

  const dispatch = useAppDispatch();

  const handleSaveSecurity = async () => {
    setActionPressed(true);
    const isNewSecurity = !security;
    if (isNewSecurity) {
      const exists = securities.find((x) => x.symbol === securityForm.symbol);
      if (exists) {
        toast('Security symbol already exists.');
      } else {
        dispatch(createSecurityAsync(securityForm));
      }
    } else {
      dispatch(updateSecurityAsync(securityForm));
    }
  };

  const confirmDeleteSecurity = async () => {
    if (await confirm('Are you sure you want to delete this security?')) {
      dispatch(removeSecurityAsync(securityForm));
      navigate('/securities');
    }
  };

  const handleDeleteSecurityBtnClick = () => {
    setActionPressed(true);
    const isNewSecurity = !security;
    if (isNewSecurity) {
      handleCloseDialog();
    } else {
      confirmDeleteSecurity();
    }
  };

  const sortByName = (a: Security, b: Security) => {
    if (!a || !b) {
      return 0;
    }
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  };

  const sortedSecurities = useMemo(() => {
    if (!securities || securities.length == 0) {
      return [];
    }
    return [...securities].sort(sortByName);
  }, [securities]);

  const sectors = Object.keys(SecuritySector)
    .filter((v) => isNaN(Number(v)))
    .map((key, index) => (
      <MenuItem key={index} value={index}>
        {key}
      </MenuItem>
    ));

  return (
    <Box>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                name="name"
                size="small"
                label="Name"
                variant="outlined"
                type="text"
                value={securityForm.name || ''}
                onChange={(e) => updateSecurityForm('name', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                name="symbol"
                size="small"
                label="Symbol"
                variant="outlined"
                type="text"
                value={securityForm.symbol || ''}
                onChange={(e) => updateSecurityForm('symbol', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                name="seeking-alpha-symbol"
                size="small"
                label="Seeking Aplha Symbol"
                variant="outlined"
                type="text"
                value={securityForm.seekingAlphaSymbol || ''}
                onChange={(e) => updateSecurityForm('seekingAlphaSymbol', e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                name="yahoo-symbol"
                size="small"
                label="Yahaoo Symbol"
                variant="outlined"
                type="text"
                value={securityForm.yahooSymbol || ''}
                onChange={(e) => updateSecurityForm('yahooSymbol', e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="exchange-label" size="small">
                Exchange
              </InputLabel>
              <Select
                name="exchange-label"
                labelId="exchange-label"
                value={securityForm.exchange}
                label="Exchange"
                onChange={(e) => updateSecurityForm('exchange', e.target.value)}
                size="small"
              >
                {exchanges.map((exhange) => (
                  <MenuItem key={exhange} value={exhange}>
                    {exhange}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="sectors-label" size="small">
                Sector
              </InputLabel>
              <Select
                name="sectors-label"
                labelId="sectors-label"
                value={securityForm.sector}
                label="Sector"
                onChange={(e) => updateSecurityForm('sector', Number(e.target.value))}
                size="small"
              >
                {sectors}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <FormControl>
          <ButtonGroup variant="outlined" disableElevation fullWidth>
            <Button
              onClick={() => setSecurityFormCategory(SecurityCategory.Stock)}
              variant={securityForm.category === SecurityCategory.Stock ? 'contained' : 'outlined'}
            >
              Stock
            </Button>
            <Button
              onClick={() => setSecurityFormCategory(SecurityCategory.Futures)}
              variant={securityForm.category === SecurityCategory.Futures ? 'contained' : 'outlined'}
            >
              Futures
            </Button>
            <Button
              onClick={() => setSecurityFormCategory(SecurityCategory.MicroFutures)}
              variant={securityForm.category === SecurityCategory.MicroFutures ? 'contained' : 'outlined'}
            >
              Micro Futures
            </Button>
          </ButtonGroup>
        </FormControl>

        {securityForm.category === SecurityCategory.MicroFutures && (
          <FormControl fullWidth>
            <InputLabel id="parent-label" size="small">
              Parent
            </InputLabel>
            <Select
              name="parent-label"
              labelId="parent-label"
              value={securityForm.parentSymbol ?? ''}
              label="Parent"
              onChange={(e) => updateSecurityForm('parentSymbol', e.target.value)}
              size="small"
            >
              {sortedSecurities.map((sec) => (
                <MenuItem key={sec.symbol} value={sec.symbol}>
                  {sec.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl>
          <ButtonGroup variant="outlined" disableElevation fullWidth>
            <Button
              onClick={() => updateSecurityForm('subscribed', true)}
              variant={securityForm.subscribed ? 'contained' : 'outlined'}
              color={securityForm.subscribed ? 'success' : 'primary'}
            >
              Subscribed
            </Button>
            <Button
              onClick={() => updateSecurityForm('subscribed', false)}
              variant={!securityForm.subscribed ? 'contained' : 'outlined'}
              color={!securityForm.subscribed ? 'error' : 'primary'}
            >
              Unsubscribed
            </Button>
          </ButtonGroup>
        </FormControl>

        <FormControl>
          <ButtonGroup variant="outlined" disableElevation fullWidth>
            <Button
              onClick={() => updateSecurityForm('disabled', false)}
              variant={!securityForm.disabled ? 'contained' : 'outlined'}
              color={!securityForm.disabled ? 'success' : 'primary'}
            >
              Enabled
            </Button>
            <Button
              onClick={() => updateSecurityForm('disabled', true)}
              variant={securityForm.disabled ? 'contained' : 'outlined'}
              color={securityForm.disabled ? 'error' : 'primary'}
            >
              Disabled
            </Button>
          </ButtonGroup>
        </FormControl>

        <FormControl>
          <ButtonGroup variant="outlined" disableElevation fullWidth>
            <Button
              onClick={() => updateSecurityForm('shortList', true)}
              variant={securityForm.shortList ? 'contained' : 'outlined'}
              color={securityForm.shortList ? 'success' : 'primary'}
            >
              Shortlist
            </Button>
            <Button onClick={() => updateSecurityForm('shortList', false)} variant={!securityForm.shortList ? 'contained' : 'outlined'}>
              Ordinary List
            </Button>
          </ButtonGroup>
        </FormControl>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between' }}>
          <Button variant="contained" color="error" onClick={handleDeleteSecurityBtnClick}>
            Delete
          </Button>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={handleSaveSecurity}>
              Save
            </Button>
            {onClose && (
              <Button onClick={handleCloseDialog} variant="outlined">
                Cancel
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <ConfirmDialog />
    </Box>
  );
};

export default SecurityGeneralPanel;
