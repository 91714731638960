import { FunctionComponent, useState } from 'react';

import { Alert, AlertTitle, Box, Button, Container, Typography } from '@mui/material';

import axios from '../../api/axios-api';

interface TestDateResponse {
  myDate: Date;
}

const TestDatePage: FunctionComponent = () => {
  const [myDateString, setMyDateString] = useState<Date | undefined>();
  const [myDate, setMyDate] = useState<Date | undefined>();
  const [myDate2, setMyDate2] = useState<Date | undefined>();

  const fetchDateAsync = async () => {
    return axios.get<TestDateResponse>('/api/test-date');
  };

  const postDateAsync = async (dt: Date) => {
    return axios.post<TestDateResponse>('/api/test-date', { myDate: dt });
  };

  const getDateHandler = async () => {
    try {
      const res: TestDateResponse = (await fetchDateAsync()).data;
      setMyDateString(res.myDate);

      if (typeof res.myDate === 'string') {
        const dt = new Date(res.myDate);
        setMyDate(dt);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const setDateHandler = async () => {
    try {
      if (!myDate) {
        return;
      }
      const res: TestDateResponse = (await postDateAsync(myDate)).data;
      if (typeof res.myDate === 'string') {
        const dt = new Date(res.myDate);
        setMyDate2(dt);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" sx={{ my: 3 }}>
        Test Date
      </Typography>

      <Box sx={{ my: 2 }}>
        <Button onClick={getDateHandler}>Get Date</Button>
        {!!myDateString && (
          <p>
            {myDateString.toString()} <i>-- Raw date-string fra server DTO</i>
          </p>
        )}
        {!!myDate && (
          <p>
            {myDate.toString()} <i>-- new Date(string) med raw date-string som param</i>
          </p>
        )}
      </Box>

      <Box sx={{ my: 2 }}>
        <Button onClick={setDateHandler}>Set Date (Roundtrip)</Button>
        {!!myDate2 && <p>{myDate2.toString()}</p>}
      </Box>

      <Box sx={{ my: 2 }}>
        <Alert severity="info" variant="outlined">
          Så lenge dato er UTC på server så blir dato rendret riktig (altså til riktig tidssone) på klient. I DTO fra server til klient så
          er dato av type string. Når dato-sting brukes som param for nytt DateTime objekt så endres utc-dato til lokal dato.
        </Alert>
      </Box>

      <Box sx={{ my: 2 }}>
        <Alert severity="info" variant="outlined">
          <AlertTitle>Roundtrip</AlertTitle>
          Når man sender lokal dato i DTO til server så tas det i mot som UTC. Hvis man sender UTC tilbake igjen så får man samme local-date
          dersom man initierer et nytt date object (new Date(string))
        </Alert>
      </Box>

      <Box sx={{ my: 2 }}>
        <Alert severity="warning" variant="outlined">
          Hvis man skal lagre en dato med en annen tidssone enn UTC, så må den gjøres om til UTC på server før den lagres, slik at den kan
          bli vist riktig på klient
        </Alert>
      </Box>
    </Container>
  );
};

export default TestDatePage;
