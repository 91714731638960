import { FunctionComponent } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { useAppSelector } from '../../store/hooks';
import { Position, PositionPortfolio } from '../../types/entities';
import { setThousenSeparator } from '../../utils/currency-utils';

interface Props {
  positions: Position[];
  portfolios: PositionPortfolio[];
}
export const CashOverviewPanel: FunctionComponent<Props> = ({ positions, portfolios }: Props) => {
  const accountInfoState = useAppSelector((gs) => gs.accountInfoState);
  const cashBalance = accountInfoState.accountInfo?.cashBalanceUsd;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getMarketValueForAllPositions = () => {
    const filteredList = portfolios.filter((x) => x.size !== 0);
    if (filteredList.length === 0) {
      return 0;
    }
    return filteredList
      .map((x) => x.marketValue)
      .reduce((total: number, marketValue: number) => {
        return total + marketValue;
      });
  };

  // inconsistant value from FUT contrcats
  const getMarketValueForAllPositionsExceptFutures = () => {
    const filteredList = positions.filter((x) => x.details.contract.secType !== 'FUT');
    if (filteredList.length === 0) {
      return 0;
    }
    let totalMarketValue = 0;
    filteredList.forEach((pos) => {
      const marketValue = portfolios.find((x) => x.conId === pos.details.contract.conId)?.marketValue ?? 0;
      totalMarketValue += marketValue;
    });
    return totalMarketValue;
  };

  const totalBalance = (cashBalance ?? 0) + getMarketValueForAllPositionsExceptFutures();

  const formatedCashBalance = totalBalance ? `$${setThousenSeparator(Math.round(totalBalance))}` : '-';
  const availableFunds = accountInfoState.accountInfo?.availableFundsUsd;
  const formatedAvailableFunds = availableFunds ? `$${setThousenSeparator(Math.round(availableFunds))}` : '-';

  const theme = useTheme();
  const isXsViewPort = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Box sx={{ textAlign: 'right' }}>
      <Typography variant={isXsViewPort ? 'body1' : 'h6'}>
        Cash Balance: <span>{formatedCashBalance}</span>
      </Typography>
      <Typography variant="caption">
        Available Funds: <span>{formatedAvailableFunds}</span>
      </Typography>
    </Box>
  );
};
