import { FunctionComponent, useState } from 'react';

import { Button, Card } from '@mui/material';

import { SECURITY_OPTIONS_TAB_CHART_ID } from '../../assets/constants';
import BarChartPanel from '../../components/panels/BarChartPanel';
import OptionChainPanel from '../../components/panels/OptionChainPanel';
import OptionIndicatorPanel from '../../components/panels/OptionIndicatorPanel';
import TradingClassPanel from '../../components/panels/TradingClassPanel';
import GutterBox from '../../components/ui/GutterBox';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { createPositionAsync } from '../../store/position/service';
import { setSelectedOptionRight, setSelectedTradingClass } from '../../store/user-settings/reducer';
import { Contract, OptionContract, OptionRight, Security, TradingClass } from '../../types/entities';
import { createOptionPosition } from '../../utils/position-utils';

interface Props {
  security?: Security;
  underlying: Contract;
}

const ContractOptionsTab: FunctionComponent<Props> = ({ security, underlying }: Props) => {
  const { symbol } = underlying;
  const userSettingsState = useAppSelector((gs) => gs.userSettingsState);
  const selectedTradingClass = userSettingsState.optionSettings[symbol]?.selectedTradingClass;
  const optionRight = userSettingsState.optionSettings[symbol]?.selectedRight || 'CALL';

  const [optionSelected, setOptionSelected] = useState<OptionContract | undefined>();

  const dispatch = useAppDispatch();

  const handleSelectTradingClass = (tradingClass: TradingClass | undefined) => {
    dispatch(setSelectedTradingClass({ symbol, tradingClass }));
  };

  const handleSelectOptionRight = (right: OptionRight) => {
    dispatch(setSelectedOptionRight({ symbol, right }));
  };

  const handleCreatePositionBtnClick = () => {
    if (security && optionSelected) {
      const pos = createOptionPosition(optionSelected);
      dispatch(createPositionAsync(pos));
    }
  };

  const goBack = () => handleSelectTradingClass(undefined);

  return (
    <>
      <Card sx={{ mb: 2 }}>
        {!selectedTradingClass && <TradingClassPanel underlying={underlying} onSelect={handleSelectTradingClass} />}
        {selectedTradingClass && (
          <OptionChainPanel
            underlying={underlying}
            tradingClass={selectedTradingClass}
            goBack={goBack}
            right={optionRight}
            selected={optionSelected}
            onOptionSelected={setOptionSelected}
            onOptionRightChanged={handleSelectOptionRight}
          />
        )}
      </Card>
      {optionSelected && <OptionIndicatorPanel option={optionSelected} orderAction={'BUY'} />}
      {optionSelected && (
        <Card sx={{ mt: 2, py: 1 }}>
          <BarChartPanel
            barChartId={SECURITY_OPTIONS_TAB_CHART_ID}
            symbol={symbol}
            conId={underlying.conId}
            title={underlying.localSymbol}
            exchange={underlying.exchange}
            secType={underlying.secType}
            strikePrice={optionSelected.strikePrice}
          />
        </Card>
      )}
      <GutterBox sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="success" variant="contained" onClick={handleCreatePositionBtnClick} disabled={!optionSelected}>
          Create Position
        </Button>
      </GutterBox>
    </>
  );
};

export default ContractOptionsTab;
