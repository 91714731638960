export interface BarData {
  x: Date | number;
  y: number[];
}

export interface GraphData {
  data: BarData[];
  series: ApexAxisChartSeries;
  strokes: StrokeType[];
  lineWidth: LineWidth[];
}

export interface ApexAxisChartSerie {
  name: string;
  color: string;
  type: string;
  data: BarData[];
}

export type SeriesType = 'candlestick' | 'horizontal-line' | 'vertical-line';

export enum StrokeType {
  Solid = 0,
  Dotted = 3,
  Dashed = 8
}

export enum LineWidth {
  Narrow = 1,
  Standard = 2,
  Fat = 3
}

export const createHorizontalLine = (name: string, yAxis: number, color: string, barData: BarData[]) => {
  const firstBar = barData[0];
  const lastBar = barData[barData.length - 1];
  const data = [
    {
      x: firstBar.x,
      y: [yAxis]
    },
    {
      x: lastBar.x,
      y: [yAxis]
    }
  ];

  return {
    name,
    type: 'line',
    color,
    data
  };
};

export const createVerticalLine = (name: string, xAxis: Date, color: string, barData: BarData[]) => {
  const firstBar = barData[0];
  if (firstBar.x > xAxis) {
    return undefined;
  }
  const lowestValues = barData.map((x) => Math.min(...x.y));
  const lowestValue = Math.min(...lowestValues);
  const highestValues = barData.map((x) => Math.max(...x.y));
  const higestValue = Math.max(...highestValues);
  const data = [
    {
      x: xAxis,
      y: [lowestValue]
    },
    {
      x: xAxis,
      y: [higestValue]
    }
  ];

  return {
    name,
    type: 'line',
    color,
    data
  };
};

const createCandleStickChart = (name: string, color: string, data: BarData[]) => {
  return {
    name,
    color,
    type: 'candlestick',
    data
  };
};

export const addSeries = (
  graphData: GraphData,
  type: SeriesType,
  name: string,
  color: string,
  stroke: StrokeType,
  width: LineWidth,
  yAxisPoint?: number,
  xAxisPoint?: Date
) => {
  let serie: ApexAxisChartSerie | undefined;
  switch (type) {
    case 'candlestick':
      serie = createCandleStickChart(name, color, graphData.data);
      break;
    case 'horizontal-line':
      if (yAxisPoint) {
        serie = createHorizontalLine(name, yAxisPoint, color, graphData.data);
      }
      break;
    case 'vertical-line':
      if (xAxisPoint) {
        serie = createVerticalLine(name, xAxisPoint, color, graphData.data);
      }

      break;
  }
  if (serie) {
    graphData.series.push(serie);
    graphData.strokes.push(stroke);
    graphData.lineWidth.push(width);
  }
};
