import { FunctionComponent, useState } from 'react';

// import FavoriteIcon from '@mui/icons-material/Favorite';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AddCommentIcon from '@mui/icons-material/AddComment';
import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';

import { useAppDispatch } from '../../../store/hooks';
import { createQuickNoteAsync } from '../../../store/quick-note/service';
import { QuickNote, Security } from '../../../types/entities';

import QuickNoteList from './QuickNoteList';

interface Props {
  security: Security;
}

const QuickNotePanel: FunctionComponent<Props> = ({ security }) => {
  const [quickNote, setQuickNote] = useState('');
  const [showQuickNote, setShowQuickNote] = useState(false);
  const toggleShowQuickNote = () => {
    setShowQuickNote(!showQuickNote);
  };

  const dispatch = useAppDispatch();

  function handleSendQuickNoteBtnClick(): void {
    const payload: QuickNote = {
      id: '',
      created: new Date(),
      symbol: security.symbol,
      note: quickNote
    };
    dispatch(createQuickNoteAsync(payload));
    setShowQuickNote(false);
    setQuickNote('');
  }

  return (
    <Box>
      <Box sx={{ pt: 2, px: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6">Quick Notes</Typography>
        </Box>
        <Tooltip title="Add Quick Note" placement="left-start">
          <IconButton sx={{ mt: 0.5 }} size="small" color="primary" onClick={toggleShowQuickNote}>
            <AddCommentIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <QuickNoteList symbol={security.symbol} />
      {showQuickNote && (
        <Box sx={{ pt: 2, px: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <TextField
            name="Quick Note"
            size="small"
            label="Quick Note"
            variant="outlined"
            type="text"
            value={quickNote}
            onChange={(e) => setQuickNote(e.target.value)}
            fullWidth
            sx={{ pr: 1 }}
          />
          <IconButton size="small" color="primary" onClick={handleSendQuickNoteBtnClick}>
            <SendIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default QuickNotePanel;
