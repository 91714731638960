import { FunctionComponent } from 'react';

import { Box, Dialog, DialogContent, Typography } from '@mui/material';

import SecurityGeneralPanel from '../../components/panels/SecurityGeneralPanel';
import DialogCloseIconButton from '../../components/ui/DialogCloseIconButton';
import { Security } from '../../types/entities';
import { SecurityCategory } from '../../types/enums';

interface Props {
  security?: Security;
  securities: Security[];
  category?: SecurityCategory | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const SecurityDialog: FunctionComponent<Props> = ({ security, securities, category, isOpen, onClose }: Props) => {
  const title = `${security ? 'Edit' : 'New'} Security`;

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogContent sx={{ p: 0, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <Typography variant="h6" sx={{ pt: 2, px: 2 }}>
            {title}
          </Typography>
          <DialogCloseIconButton onClose={onClose} />
        </Box>

        <SecurityGeneralPanel security={security} securities={securities} category={category} onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default SecurityDialog;
