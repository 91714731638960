import { FunctionComponent, useState } from 'react';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import { IconButton, Tooltip } from '@mui/material';

import { Position } from '../../types/entities';
import PositionGraphDialog from '../dialogs/PositionGraphDialog';

interface Props {
  position: Position;
  filledDt?: Date | string | undefined;
  stopPrice?: number | undefined;
  limitPrice?: number | undefined;
  avgPrice?: number | undefined;
}

const ShowGraphIconButton: FunctionComponent<Props> = ({ position, filledDt, stopPrice, limitPrice, avgPrice }: Props) => {
  const [showGraphDialog, setShowGraphDialog] = useState(false);
  const handleGraphBtnClick = () => setShowGraphDialog(true);
  const closeGraphDialog = () => setShowGraphDialog(false);

  return (
    <>
      <Tooltip title="Barchart" placement="top">
        <IconButton size="small" onClick={handleGraphBtnClick} sx={{ color: (theme) => theme.palette.grey[200] }}>
          <ShowChartIcon />
        </IconButton>
      </Tooltip>
      {showGraphDialog && (
        <PositionGraphDialog
          isOpen={showGraphDialog}
          onClose={closeGraphDialog}
          position={position}
          filledDt={filledDt}
          stopPrice={stopPrice}
          limitPrice={limitPrice}
          avgPrice={avgPrice}
        />
      )}
    </>
  );
};

export default ShowGraphIconButton;
