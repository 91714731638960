import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { getPositionHistory } from '../../api';
import { Logger } from '../../utils/Logger';

import { success, fail, loading } from './reducer';

export const getTradeHistoryAsync = (year: number, month: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ year, month, loading: true }));
    getPositionHistory(year, month)
      .then((response) => {
        const positions = response.data;
        Logger.log(positions);
        dispatch(success({ year, month, positions }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail({ year, month, error: msg }));
        toast.error(msg);
      });
  };
};
