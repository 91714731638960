import { ContractDetails, OptionContract, OptionRight, OrderAction, OrderType, Position, PositionOld } from '../types/entities';
import { MarketTradingHours } from '../types/enums';

export const deepCopyPosition = (position: Position) => {
  return {
    ...position,
    details: {
      ...position.details,
      contract: {
        ...position.details.contract
      }
    },
    averaging: position.averaging ? { ...position.averaging } : undefined,
    simulator: position.simulator ? { ...position.simulator } : undefined,
    trades: [...position.trades],
    logs: [...position.logs]
  };
};

export const createUnderlyingPosition = (details: ContractDetails): Position => {
  const underSymbol = details.underSymbol ?? details.contract.symbol;
  const underSecType = details.underSymbol ?? details.contract.secType;
  const contractMonth = details.contractMonth ?? '';
  const lastTradeTime = details.lastTradeTime ?? '';
  const realExpirationDate = details.realExpirationDate ?? '';
  const lastTradeDateOrContractMonth = details.contract.lastTradeDateOrContractMonth ?? '';
  const multiplier = details.contract.multiplier ?? '';
  return {
    id: '',
    sortOrder: 0,
    created: new Date().toISOString(), // redux: not accepting non-serializable
    details: {
      ...details,
      underSymbol,
      underSecType,
      contractMonth,
      lastTradeTime,
      realExpirationDate,
      contract: {
        ...details.contract,
        lastTradeDateOrContractMonth,
        multiplier
      }
    },
    targetProfit: 0,
    closed: false,
    completed: false,
    trades: [],
    logs: []
  };
};

export const createOptionPosition = (option: OptionContract): Position => {
  const { details } = option;
  const underSymbol = details.underSymbol ?? details.contract.symbol;
  const underSecType = details.underSymbol ?? details.contract.secType;
  const contractMonth = details.contractMonth ?? '';
  const lastTradeTime = details.lastTradeTime ?? '';
  const realExpirationDate = details.realExpirationDate ?? '';
  const lastTradeDateOrContractMonth = details.contract.lastTradeDateOrContractMonth ?? '';
  const multiplier = details.contract.multiplier ?? '';
  return {
    id: '',
    sortOrder: 0,
    created: new Date().toISOString(), // redux: not accepting non-serializable
    details: {
      ...details,
      underSymbol,
      underSecType,
      contractMonth,
      lastTradeTime,
      realExpirationDate,
      contract: {
        ...details.contract,
        lastTradeDateOrContractMonth,
        multiplier
      }
    },
    optionExpireDate: option.expires,
    targetProfit: 0,
    closed: false,
    completed: false,
    trades: [],
    logs: []
  };
};

// TODO: Fix option-chain call to fetch all fields in position
export const createOptionEntryPositionOld = (
  option: OptionContract,
  optionRight: OptionRight,
  orderAction: OrderAction,
  orderType: OrderType,
  orderSize: number,
  entryPrice: number,
  marketPrice: number
): PositionOld => {
  const pos: PositionOld = {
    id: '',
    groupId: '',
    sortOrder: 0,
    targetProfit: 0,
    closed: false,
    completed: false,
    lockPnl: false,
    marketTradingHours: MarketTradingHours.Open,

    /* Order */
    orderType,
    orderAction,
    entryDate: new Date().toISOString(), // redux: not accepting non-serializable
    entryPrice: entryPrice,
    orderSize,
    filledSize: 0,
    strikePrice: option.strikePrice,
    premium: option.premium,
    right: optionRight,

    /* Portfolio */
    size: 0,
    marketPrice,
    marketValue: 0,
    unrealizedPnl: 0,
    realizedPnl: 0,
    underMarketPrice: 0,
    portfolioRlzPnl: 0,

    /* schedule */
    // useSmallProfit: tradePreset?.useSmallProfit || false,
    // smallProfitDelayHours: tradePreset?.smallProfitDelayHours || 0,
    // smallProfit: tradePreset?.smallProfit || 0,
    // useTimeOut: tradePreset?.useTimeOut || false,
    // timeOutDelayHours: tradePreset?.timeOutDelayHours || 0,

    /* contract */
    conId: option.conId,
    symbol: option.symbol,
    localSymbol: option.localSymbol,
    secType: option.secType,
    exchange: option.exchange,
    timeZone: '',
    expiration: '',
    lastTradeTime: '',
    expirationDate: option.expires,
    lastTradeDateOrContractMonth: option.lastTradeDateOrContractMonth,

    /* underlying contract */
    underConId: option.underConId,
    underSymbol: option.underSymbol,
    underSecType: option.underSecType,
    multiplier: option.multiplier,
    tickIncrement: option.tickIncrement,
    priceMagnifier: option.priceMagnifier,

    /* vlt stats */
    entryToExpireHistVlt: option.historicVolatility,
    entryToExpireImpliedVlt: option.impliedVolatility,
    entryToStrikeVlt: option.priceDiffVlt
  };

  return pos;
};

export const createOptionExitOrderOld = (optionPosition: PositionOld, orderType: OrderType, exitPrice = 0): PositionOld => {
  // if exit order is based on not submitted order, then use order-size instead of position size
  const orderSize = optionPosition.size != 0 ? Math.abs(optionPosition.size) : optionPosition.orderSize;
  const pos: PositionOld = {
    id: optionPosition.id,
    groupId: '',
    sortOrder: 0,
    targetProfit: 0,
    closed: false,
    completed: false,
    lockPnl: false,
    marketTradingHours: MarketTradingHours.Open,

    /* Order */
    orderType,
    orderAction: optionPosition.orderAction === 'BUY' ? 'SELL' : 'BUY',
    entryDate: undefined,
    entryPrice: exitPrice,
    orderSize,
    filledSize: 0,
    strikePrice: optionPosition.strikePrice,
    premium: 0,
    right: optionPosition.right,

    /* Portfolio */
    size: orderSize, // crucial for defining exit-pos on backend
    marketPrice: 0,
    marketValue: 0,
    unrealizedPnl: 0,
    realizedPnl: 0,
    underMarketPrice: 0,
    portfolioRlzPnl: 0,

    /* schedule */
    // useSmallProfit: false,
    // smallProfitDelayHours: 0,
    // smallProfit: 0,
    // useTimeOut: false,
    // timeOutDelayHours: 0,

    /* contract */
    conId: optionPosition.conId,
    symbol: optionPosition.symbol,
    localSymbol: optionPosition.localSymbol,
    secType: optionPosition.secType,
    exchange: optionPosition.exchange,
    timeZone: '',
    expiration: '',
    lastTradeTime: '',
    expirationDate: optionPosition.expirationDate,
    lastTradeDateOrContractMonth: optionPosition.lastTradeDateOrContractMonth,

    /* underlying contract */
    underConId: optionPosition.underConId,
    underSymbol: optionPosition.underSymbol,
    underSecType: optionPosition.underSecType,
    multiplier: optionPosition.multiplier,
    tickIncrement: optionPosition.tickIncrement,
    priceMagnifier: optionPosition.priceMagnifier,

    /* vlt stats */
    entryToExpireHistVlt: optionPosition.entryToExpireHistVlt,
    entryToExpireImpliedVlt: optionPosition.entryToExpireImpliedVlt,
    entryToStrikeVlt: optionPosition.entryToStrikeVlt
  };

  return pos;
};

export const createUnderlyingEntryPositionOld = (
  contractDetails: ContractDetails,
  orderAction: OrderAction,
  orderType: OrderType,
  orderSize: number,
  entryPrice: number,
  marketPrice: number
): PositionOld => {
  const contract = contractDetails.contract;

  const pos: PositionOld = {
    id: '',
    groupId: '',
    sortOrder: 0,
    targetProfit: 0,
    closed: false,
    completed: false,
    lockPnl: false,
    marketTradingHours: MarketTradingHours.Open,

    /* Order */
    orderType,
    orderAction,
    entryDate: new Date().toISOString(), // redux: not accepting non-serializable
    entryPrice: entryPrice,
    orderSize,
    filledSize: 0,
    strikePrice: 0,
    premium: 0,

    /* Portfolio */
    size: 0,
    marketPrice,
    marketValue: 0,
    unrealizedPnl: 0,
    realizedPnl: 0,
    underMarketPrice: 0,
    portfolioRlzPnl: 0,

    /* schedule */
    // useSmallProfit: tradePreset?.useSmallProfit || false,
    // smallProfitDelayHours: tradePreset?.smallProfitDelayHours || 0,
    // smallProfit: tradePreset?.smallProfit || 0,
    // useTimeOut: tradePreset?.useTimeOut || false,
    // timeOutDelayHours: tradePreset?.timeOutDelayHours || 0,

    /* contract */
    conId: contract.conId,
    symbol: contract.symbol,
    localSymbol: contract.localSymbol,
    secType: contract.secType,
    exchange: contract.exchange,
    timeZone: contractDetails.timeZoneId,
    expiration: contractDetails.realExpirationDate,
    lastTradeTime: contractDetails.lastTradeTime,

    /* underlying contract */
    underConId: contractDetails.underConId,
    underSymbol: contractDetails.underSymbol,
    underSecType: contractDetails.underSecType,
    multiplier: contract.multiplier,
    tickIncrement: contractDetails.minTick,
    priceMagnifier: contractDetails.priceMagnifier
  };

  return pos;
};

export const createUnderlyingExitOrderOld = (underlyingPosition: PositionOld, orderType: OrderType, exitPrice = 0): PositionOld => {
  const pos: PositionOld = {
    id: underlyingPosition.id,
    groupId: '',
    sortOrder: 0,
    targetProfit: 0,
    closed: false,
    completed: false,
    lockPnl: false,
    marketTradingHours: MarketTradingHours.Open,

    /* Order */
    orderType,
    orderAction: underlyingPosition.orderAction === 'BUY' ? 'SELL' : 'BUY',
    entryDate: undefined,
    entryPrice: exitPrice,
    orderSize: Math.abs(underlyingPosition.size),
    filledSize: 0,
    strikePrice: 0,
    premium: 0,

    /* Portfolio */
    size: underlyingPosition.size,
    marketPrice: 0,
    marketValue: 0,
    unrealizedPnl: 0,
    realizedPnl: 0,
    underMarketPrice: 0,
    portfolioRlzPnl: 0,

    /* schedule */
    // useSmallProfit: false,
    // smallProfitDelayHours: 0,
    // smallProfit: 0,
    // useTimeOut: false,
    // timeOutDelayHours: 0,

    /* contract */
    conId: underlyingPosition.conId,
    symbol: underlyingPosition.symbol,
    localSymbol: underlyingPosition.localSymbol,
    secType: underlyingPosition.secType,
    exchange: underlyingPosition.exchange,
    timeZone: underlyingPosition.timeZone,
    expiration: underlyingPosition.expiration,
    lastTradeTime: underlyingPosition.lastTradeTime,

    /* underlying contract */
    underConId: underlyingPosition.underConId,
    underSymbol: underlyingPosition.underSymbol,
    underSecType: underlyingPosition.underSecType,
    multiplier: underlyingPosition.multiplier,
    tickIncrement: underlyingPosition.tickIncrement,
    priceMagnifier: underlyingPosition.priceMagnifier
  };

  return pos;
};
