import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { createTrendLine, fetchTrendLines, removeTrendLine, updateTrendLine } from '../../api';
import { TrendLine } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading, add, update, remove } from './reducer';

export const getTrendLinesAsync = (conId: number) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ conId, loading: true }));
    fetchTrendLines(conId)
      .then((response) => {
        const trendLines = response.data;
        Logger.log(trendLines);
        dispatch(success({ conId, trendLines }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail({ conId, error: msg }));
        toast.error(msg);
      });
  };
};

export const createTrendLineAsync = (trendLine: TrendLine) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ conId: trendLine.conId, loading: true }));
    createTrendLine(trendLine)
      .then((response) => {
        const tradeLineWithId = response.data;
        dispatch(add({ conId: trendLine.conId, trendLine: tradeLineWithId }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const updateTrendLineAsync = (trendLine: TrendLine) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ conId: trendLine.conId, loading: true }));
    updateTrendLine(trendLine)
      .then(() => {
        Logger.log(trendLine);
        dispatch(update({ conId: trendLine.conId, trendLine }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const removeTrendLineAsync = (trendLine: TrendLine) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading({ conId: trendLine.conId, loading: true }));
    removeTrendLine(trendLine)
      .then(() => {
        dispatch(remove({ conId: trendLine.conId, trendLine }));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
