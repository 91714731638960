import { useState } from 'react';

import { useAppSelector } from '../../store/hooks';
import { Contract, ContractMarketData } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { useMarketDataQuery } from './useMarketDataQuery';

export interface CustomizedMarketDataResult {
  marketPrice: number;
  premium: number;
  dayVolume: number;
  volumeRatePerMinute: number;
  averageVolume90Days: number;
  impliedVolatilityPct: number;
  historicalVolatilityPct: number;
}

export const useRefreshMarketData = () => {
  const getMarketDataAsync = useMarketDataQuery();

  const { useFrozen } = useAppSelector((gs) => gs.userSettingsState);
  const [loading, setLoading] = useState(false);

  const getMarketPrice = (marketData: ContractMarketData) => {
    if (marketData.lastPrice) {
      return marketData.lastPrice;
    }
    const sum = marketData.askPrice + marketData.bidPrice;
    if (sum !== 0) {
      return sum / 2;
    }
    return undefined;
  };

  /*
  when accessed through the Interactive Brokers API, volatility is expressed as a percentage, 
  meaning it represents the potential percentage price fluctuation of an asset over a given time period
  -----
  to multiply a decimal number and a fraction together, write the decimal as a fraction with a whole number 
  as the numerator and a product of 10s as the denominator. For example: 0.48 x 3/4. = (48 / 100) x (3 / 4)
  */
  const calulateDailyVolatilityPct = (vlt: number) => {
    if (vlt === 0) {
      return -1;
    }
    const part = vlt / 30;
    const pctPerDay = part * 100;
    return Number(Math.round(parseFloat(pctPerDay + 'e' + 2)) + 'e-' + 2);
  };

  const refreshMarketData = async (
    contract: Contract,
    callback: (result: CustomizedMarketDataResult | undefined) => void,
    pricesOnly = true,
    noIndicators = true
  ) => {
    setLoading(true);
    try {
      const marketData = await getMarketDataAsync(contract, useFrozen, pricesOnly, noIndicators);
      const marketPrice = getMarketPrice(marketData) ?? 0;
      const { dayVolume, volumeRatePerMinute, averageVolume90Days, impliedVolatility, historicalVolatility } = marketData;

      let premium = 0;
      if (contract.secType === 'FOP' || contract.secType === 'OPT') {
        if (contract.multiplier) {
          premium = marketPrice * Number(contract.multiplier);
        }
      }

      if (callback) {
        callback({
          marketPrice,
          premium,
          dayVolume,
          volumeRatePerMinute,
          averageVolume90Days,
          impliedVolatilityPct: calulateDailyVolatilityPct(impliedVolatility),
          historicalVolatilityPct: calulateDailyVolatilityPct(historicalVolatility)
        });
      }
    } catch (error) {
      Logger.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    refreshMarketData,
    loading
  };
};
