import { FunctionComponent, useEffect } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import { Fab } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { KEYCODE_R } from '../../assets/constants';
import { loadDashboardAsync } from '../../store/dashboard/service';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import useKeyDownEvent from '../hooks/useKeyDownEvent';

const ReloadDashboardFab: FunctionComponent = () => {
  const { loading, loaded } = useAppSelector((gs) => gs.dashboardState);

  const userSettingsState = useAppSelector((gs) => gs.userSettingsState);
  const { ignoreMissingUnderMarketPrice, useFrozen } = userSettingsState;

  const dispatch = useAppDispatch();
  const reloadDashboard = () => {
    dispatch(loadDashboardAsync(ignoreMissingUnderMarketPrice, useFrozen));
  };

  useKeyDownEvent(KEYCODE_R, () => {
    reloadDashboard();
  });

  useEffect(() => {
    if (!loading && !loaded) {
      reloadDashboard();
    }
  }, [loading, loaded]);

  return (
    <Fab
      color="primary"
      aria-label="refresh"
      sx={{
        position: 'fixed',
        bottom: 24,
        right: 16
      }}
      onClick={reloadDashboard}
    >
      {loading ? <CircularProgress color="inherit" size="1.5rem" /> : <RefreshIcon />}
    </Fab>
  );
};

export default ReloadDashboardFab;
