import { FunctionComponent, useEffect } from 'react';

import { Box, Card, Divider, Skeleton, Typography } from '@mui/material';

import NewsPanel from '../../../components/panels/NewsPanel';
import { getFundamentalNotesAsync } from '../../../store/fundamental-note/service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import SeasonalAccordion from './SeasonalAccordion';

interface Props {
  mt: number;
}

const DashboardFundamentalsPanel: FunctionComponent<Props> = ({ mt = 5 }: Props) => {
  const dispatch = useAppDispatch();

  const { securities } = useAppSelector((gs) => gs.securityState);

  const { loading: notesLoading, loaded: notesLoaded, notes } = useAppSelector((gs) => gs.fundamentalNoteState);
  useEffect(() => {
    if (!notesLoading && !notesLoaded) {
      dispatch(getFundamentalNotesAsync());
    }
  }, []);

  return (
    <Box sx={{ mt }}>
      <Divider sx={{ mb: 2 }}>
        <Typography variant="caption" sx={{ color: '#ccc' }}>
          FUNDAMENTALS
        </Typography>
      </Divider>
      <Card sx={{ mb: 2 }}>
        {notesLoading ? (
          <Skeleton sx={{ m: 2 }} variant="rounded" animation="wave" height={80} />
        ) : (
          <SeasonalAccordion securities={securities} notes={notes} />
        )}
      </Card>
      <Card>
        <NewsPanel />
      </Card>
    </Box>
  );
};

export default DashboardFundamentalsPanel;
