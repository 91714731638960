import { FunctionComponent } from 'react';

import { Box, Dialog, DialogContent, Typography } from '@mui/material';

import { Position } from '../../../types/entities';
import DialogCloseIconButton from '../../ui/DialogCloseIconButton';

import PositionLogTable from './PositionLogTable';

interface Props {
  show: boolean;
  onClose: () => void;
  position: Position;
}

const PositionLogDialog: FunctionComponent<Props> = ({ show, onClose, position: position }: Props) => {
  return (
    <Dialog fullWidth open={show} maxWidth="md" onClose={onClose} classes={{ paper: 'group-schedule-dialog__paper' }}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5">Position Log</Typography>
        <Typography variant="caption" component="div" sx={{ mt: 0.5 }}>
          {position.details.contract.localSymbol}
        </Typography>
        <DialogCloseIconButton onClose={onClose} />
      </Box>

      <DialogContent sx={{ p: 0, pb: 2, maxHeight: '50vh' }}>
        <PositionLogTable logs={position.logs ?? []} />
      </DialogContent>
    </Dialog>
  );
};

export default PositionLogDialog;
