export class Logger {
  static devMode = process.env.REACT_APP_DEVELOPER_MODE === 'true';

  public static log(output: unknown | object | string) {
    if (Logger.devMode) {
      // eslint-disable-next-line no-console
      console.log(output);
    }
  }
}
