import { PositionOld } from '../../types/entities';

export interface DashboardState {
  loading: boolean;
  loaded: boolean;
  initialLoaded: boolean;
  error?: string;
  selected?: PositionOld | undefined;
}

export const initialDashboardState: DashboardState = {
  loading: false,
  initialLoaded: false,
  loaded: false,
  error: undefined
};
