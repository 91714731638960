import React, { FunctionComponent, useEffect, useMemo } from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Box, Button, Card, Container, IconButton, Skeleton, Tab, Typography } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import MarketDataPanel from '../../components/panels/MarketDataPanel';
import MultiChartPanel from '../../components/panels/MultiChartPanel';
import PositionListPanel from '../../components/panels/PositionListPanel';
import SecurityGeneralPanel from '../../components/panels/SecurityGeneralPanel';
import { CustomTabPanel } from '../../components/ui/CustomTabPanel';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import Spinner from '../../components/ui/Spinner';
import WrappingTabs from '../../components/ui/WrappingTabs';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { updateSecurityAsync } from '../../store/security/service';
import { getContractsByUnderlyingSymbolState } from '../../store/security-contract/selectors';
import { getSecurityContractsAsync } from '../../store/security-contract/service';
import { setSelectedSecurityTab } from '../../store/user-settings/reducer';
import { Security } from '../../types/entities';

import ContractOptionsTab from './ContractOptionsTab';
import ContractsTab from './ContractsTab';
import FundamentalsTab from './FundamentalsTab';
import { fromTabIndex, toTabIndex } from './tab-utils';

const SecurityPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const symbol = params.symbol || '';
  const [searchParams] = useSearchParams();
  const selectedParamsTab = searchParams.get('tab') ?? '';
  const { selectedSecurityTab: selectedStateTab } = useAppSelector((gs) => gs.userSettingsState);
  const selectedTab = selectedParamsTab || selectedStateTab;
  const tabIndex = toTabIndex(selectedTab);

  const securityState = useAppSelector((gs) => gs.securityState);
  const { securities } = securityState;
  const security = securities.find((x) => x.symbol === symbol);

  const { loaded: favoritesLoaded } = useAppSelector((gs) => gs.foundationDataState);
  const { favorites } = useAppSelector((gs) => gs.favoriteContractState);

  const conFutState = useAppSelector((gs) => gs.securityContractState);
  const { contracts, initialLoaded, loaded: contractsLoaded } = getContractsByUnderlyingSymbolState(conFutState, symbol);

  const dispatch = useAppDispatch();

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const tabValue = fromTabIndex(newValue);
    dispatch(setSelectedSecurityTab(tabValue));
    navigate(`?tab=${tabValue}`);
  };

  useEffect(() => {
    if (security && !contractsLoaded) {
      dispatch(getSecurityContractsAsync(security));
    }
  }, [security]);

  const getFavoriteContract = () => {
    const favContracts = favorites.map((x) => x.details);
    for (let i = 0; i < contracts.length; i++) {
      const curr = contracts[i].contract;
      const fav = favContracts.find((x) => x.contract.conId === curr.conId);
      if (fav) {
        return fav;
      }
    }
  };

  const contract = useMemo(() => {
    if (contracts.length === 1) {
      return contracts[0].contract;
    }
    const fav = getFavoriteContract();
    if (fav) {
      return fav.contract;
    }
  }, [contracts, favorites, getFavoriteContract]);

  const loading = (!!contract && !contractsLoaded) || !favoritesLoaded;

  const toggleShortList = () => {
    if (security) {
      const copy = {
        ...security,
        shortList: !security.shortList
      } as Security;
      dispatch(updateSecurityAsync(copy));
    }
  };

  return (
    <div>
      <Container>
        <Button sx={{ mt: 2 }} variant="outlined" onClick={() => navigate('/securities')}>
          Back to Securities
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
          <Typography variant="h4" sx={{ my: 3 }}>
            {security?.name || 'Security'}
          </Typography>
          <IconButton color="primary" onClick={toggleShortList}>
            {security?.shortList ? <BookmarkIcon /> : <BookmarkBorderIcon />}
          </IconButton>
        </Box>
      </Container>
      <ResponsiveContainer>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <WrappingTabs value={tabIndex} onChange={handleChange}>
              <Tab label="General" />
              <Tab label="Fundamentals" />
              <Tab label="Contracts" />
              <Tab label="Options" />
              <Tab label="Indicators" />
              <Tab label="Graphs" />
              <Tab label="Positions" />
            </WrappingTabs>
          </Box>
        </Box>
      </ResponsiveContainer>
      <Spinner loading={loading} />

      <Container sx={{ mt: 3, mb: 3 }}>
        <CustomTabPanel value={tabIndex} index={0} />
        <CustomTabPanel value={tabIndex} index={1} />
        <CustomTabPanel value={tabIndex} index={2} />
        <CustomTabPanel value={tabIndex} index={3} />
        <CustomTabPanel value={tabIndex} index={4} />
        <CustomTabPanel value={tabIndex} index={5} />
        <CustomTabPanel value={tabIndex} index={6} />
      </Container>

      <ResponsiveContainer>
        <CustomTabPanel value={tabIndex} index={0}>
          <Card>
            <SecurityGeneralPanel security={security} securities={securities} />
          </Card>
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          {security && <FundamentalsTab security={security} />}
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={2}>
          {!initialLoaded ? (
            <Skeleton sx={{ mt: 2 }} variant="rounded" animation="wave" height={50} />
          ) : (
            <ContractsTab security={security} list={contracts} />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={3}>
          {contract ? (
            <ContractOptionsTab security={security} underlying={contract} />
          ) : (
            <Card sx={{ p: 2 }}>
              <Typography fontSize="smaller" component="div">
                No contracts found. Check if front runner is selected.
              </Typography>
            </Card>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={4}>
          {contract ? (
            <MarketDataPanel contract={contract} />
          ) : (
            <Card sx={{ p: 2 }}>
              <Typography fontSize="smaller" component="div">
                No contracts found. Check if front runner is selected.
              </Typography>
            </Card>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={5}>
          {contract ? (
            <MultiChartPanel contract={contract} />
          ) : (
            <Card sx={{ p: 2 }}>
              <Typography fontSize="smaller" component="div">
                No contracts found. Check if front runner is selected.
              </Typography>
            </Card>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={6}>
          <PositionListPanel symbol={symbol} />
        </CustomTabPanel>
      </ResponsiveContainer>
    </div>
  );
};

export default SecurityPage;
