import { ContractDetails } from '../types/entities';

export const getDaysAppart = (dt1: Date, dt2: Date) => {
  const ts = dt1.getTime() - dt2.getTime();
  const totalSeconds = ts / 1000;
  return Math.floor(Math.abs(totalSeconds) / 60 / 60 / 24);
};

export const getDurationString = (dt1: Date, dt2: Date) => {
  const ts = dt1.getTime() - dt2.getTime();
  const totalSeconds = ts / 1000;
  let minutes = Math.round(Math.abs(totalSeconds) / 60);
  let hours = 0;

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    const totalMinutesInHours = hours * 60;
    minutes = Math.round(minutes - totalMinutesInHours);
    // fix
    if (minutes === 60) {
      minutes -= 60;
      hours += 1;
    }
  }

  let formattedDays = '';
  let days = 0;
  if (hours > 23) {
    days = Math.floor(hours / 24);
    const totalHoursInDays = days * 24;
    hours = Math.round(hours - totalHoursInDays);
    if (hours === 24) {
      hours -= 24;
      days += 1;
    }
    formattedDays = `${days}d `;
  }

  const formattedHours = days > 0 || hours > 0 ? `${hours}h ` : '';
  const formattedMinutes = `${minutes}m`;
  return `${formattedDays}${formattedHours}${formattedMinutes}`;
};

export const getExpirationDate = (contractDetails: ContractDetails) => {
  const { realExpirationDate } = contractDetails;
  if (!!realExpirationDate) {
    const year = Number(realExpirationDate.substring(0, 4));
    const month = Number(realExpirationDate.substring(4, 6));
    const day = Number(realExpirationDate.substring(6, 8));
    return new Date(year, month, day);
  }
};

export const getFormattedExpireDateWithDuration = (expires: string | Date | undefined) => {
  if (!expires) {
    return undefined;
  }
  const formattedExpireDt = getFormattedExpireDate(expires);
  const timeToExpire = getTimeToExpire(expires);
  return `Expires ${formattedExpireDt} (${timeToExpire})`;
};

export const getFormattedExpireDate = (expires: string | Date | undefined) => {
  if (!expires) {
    return undefined;
  }
  const expiresDt = new Date(expires);
  return expiresDt.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric' });
};

export const getTimeToExpire = (expires: string | Date | undefined) => {
  if (!expires) {
    return undefined;
  }
  const now = new Date();
  const expiresDt = new Date(expires);
  return now > expiresDt ? 'expired' : `in ${getDurationString(now, expiresDt)}`;
};

export const getFormattedContractMonth = (contractMonth: string | undefined) => {
  if (!contractMonth) {
    return undefined;
  }
  const year = Number(contractMonth.substring(0, 4));
  const month = Number(contractMonth.substring(4, 6));
  const contractMonthDt = new Date(year, month - 1, 1);
  return contractMonthDt.toLocaleDateString('en-us', { year: '2-digit', month: 'short' });
};

export const getFuturesExpirationDate = (realExpirationDate: string) => {
  if (!!realExpirationDate) {
    const year = Number(realExpirationDate.substring(0, 4));
    const month = Number(realExpirationDate.substring(4, 6));
    const day = Number(realExpirationDate.substring(6, 8));
    return new Date(year, month, day);
  }
};
