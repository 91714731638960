import { FunctionComponent, useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { INDICATOR_VLT_COLOR } from '../../assets/colors';
import { PriceAlert, Security } from '../../types/entities';
import { PriceAlertType } from '../../types/enums';

interface Props {
  securities: Security[];
  priceAlerts: PriceAlert[];
}

const PriceAlertAccordion: FunctionComponent<Props> = ({ securities, priceAlerts }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setExpanded(priceAlerts.length > 0);
  }, [priceAlerts]);

  type ChipColor = 'info' | 'success' | 'warning' | 'error';
  const getChipColor = (priceAlert: PriceAlert): ChipColor => {
    switch (priceAlert.alertType) {
      case PriceAlertType.Above:
        return 'success';
      case PriceAlertType.Below:
        return 'error';
      default:
        return 'info';
    }
  };

  const handlePriceAlertChipClick = (priceAlert: PriceAlert) => {
    navigate(`/security/${priceAlert.details.contract.symbol}?tab=graphs`);
  };

  const priceAlertChips = priceAlerts.map((x) => {
    const securityName = securities.find((y) => y.symbol === x.details.contract.symbol)?.name || '';
    const label = `${securityName} - ${PriceAlertType[x.alertType]}`;
    return <Chip key={x.id} label={label} color={getChipColor(x)} onClick={() => handlePriceAlertChipClick(x)} />;
  });

  return (
    <Accordion disableGutters={true} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Box>
          <Typography variant="h6" color={priceAlerts.length > 0 ? INDICATOR_VLT_COLOR : 'inherit'}>
            Price Alerts
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {priceAlertChips.length > 0 && <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>{priceAlertChips}</Box>}
          {priceAlertChips.length === 0 && <Typography fontSize="smaller">No price alerts..</Typography>}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PriceAlertAccordion;
