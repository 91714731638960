import { FunctionComponent, useState } from 'react';

import { Box, Button, Divider, Grid, Skeleton, Typography } from '@mui/material';

import { useMarketDataStore } from '../../../store/market-data/hooks';
import { Contract } from '../../../types/entities';
import GutterBox from '../../ui/GutterBox';

import IndicatorTable from './IndicatorTable';
import KeyIndicatorPanel from './KeyIndicatorPanel';
import MarketDataTable from './MarketDataTable';
import VolatilityTable from './VolatilityTable';

interface Props {
  contract: Contract;
}

const MarketDataPanel: FunctionComponent<Props> = ({ contract }) => {
  const [showTables, setShowTables] = useState(false);
  const { fetch, marketData, loading } = useMarketDataStore(contract, true);

  const handleReloadMarketData = () => {
    fetch();
  };

  let tables: JSX.Element | undefined;
  if (showTables && marketData) {
    tables = (
      <Grid sx={{ mt: 1, mb: 3 }} container spacing={2}>
        <Grid item xs={12}>
          {/* <Box sx={{ p: 2 }}>
                <Typography variant="h6">{marketData.localSymbol}</Typography>
              </Box> */}
          <MarketDataTable marketData={marketData} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Box sx={{ p: 2 }}>
              <Typography variant="h6">Volatlity</Typography>
              <Typography variant="caption">{marketData.localSymbol}</Typography>
            </Box>
            <VolatilityTable marketData={marketData} historicalVolatility={marketData.volatility} optionSelected={undefined} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Box sx={{ p: 2 }}>
              <Typography variant="h6">Indicators</Typography>
              <Typography variant="caption">{marketData.localSymbol}</Typography>
            </Box>
            <IndicatorTable dayIndicators={marketData.dayIndicators} hourIndicators={marketData.hourIndicators} />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {loading && <Skeleton sx={{ mb: 2 }} variant="rounded" animation="wave" height={80} />}
      {!loading && marketData && (
        <>
          <KeyIndicatorPanel marketData={marketData} />
          <Divider />
          {tables}
        </>
      )}
      <GutterBox sx={{ my: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button size="small" variant={showTables ? 'contained' : 'outlined'} onClick={() => setShowTables(!showTables)}>
          Show Tables
        </Button>
        <Button size="small" variant="outlined" onClick={handleReloadMarketData}>
          Reload Marketdata
        </Button>
      </GutterBox>
    </>
  );
};

export default MarketDataPanel;
