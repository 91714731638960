import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PriceAlert } from '../../types/entities';

import { PriceAlertState, initialPriceAlertState } from './state';

const openOrderSlice = createSlice({
  name: 'price-alert',
  initialState: initialPriceAlertState,
  reducers: {
    reset: () => initialPriceAlertState,
    loading: (state: PriceAlertState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: PriceAlertState, action: PayloadAction<PriceAlert[]>) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.priceAlerts = action.payload;
    },
    fail: (state: PriceAlertState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.priceAlerts = [];
    }
  }
});

const { actions, reducer } = openOrderSlice;
export const { reset, loading, success, fail } = actions;
export default reducer;
