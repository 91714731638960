import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import BorderHorizontalIcon from '@mui/icons-material/BorderHorizontal';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  updateDuration,
  updateBarSize,
  initialize,
  updateWhatToShow,
  updateEndDate,
  updateUseRth
} from '../../../store/historical-data/reducer';
import { getHistoricalDataStateByKey } from '../../../store/historical-data/selectors';
import { getHistoricalDataAsync } from '../../../store/historical-data/service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTrendLineStateByConId } from '../../../store/trend-line/selectors';
import { getTrendLinesAsync } from '../../../store/trend-line/service';
import { CandleStickBar, Contract, HistoricalDataParams } from '../../../types/entities';
import { BarSize, WhatToShow } from '../../../types/enums';
import { getReduxDateString } from '../../../utils/redux-utils';
import SelectDateTimeControl from '../../form-controls/SelectDateTimeControl';
import AccordionWrapper from '../../ui/AccordionWrapper';
import TrendLineDialog from '../TrendLinePanel/TrendLineDialog';

import BarChart from './BarChart';

interface Props {
  barChartId: number;
  conId: number;
  symbol?: string;
  secType: string;
  title: string;
  exchange: string;
  filledDt?: Date | string | undefined;
  entryPrice?: number;
  strikePrice?: number;
  limitPrice?: number;
  stopPrice?: number;
  avgPrice?: number;
  initialBarSize?: BarSize;
  initialDuration?: number;
  showMoreBtn?: boolean;
  loadTrendLines?: boolean; // to block loading of trendlines more than once in multichartpanel
}

const BarChartPanel: FunctionComponent<Props> = ({
  barChartId,
  conId,
  symbol,
  secType,
  title,
  exchange,
  filledDt,
  entryPrice,
  strikePrice,
  limitPrice,
  stopPrice,
  avgPrice,
  initialBarSize,
  initialDuration,
  showMoreBtn = false,
  loadTrendLines = true
}: Props) => {
  const navigate = useNavigate();

  const [presetMenuExpanded, setPresetMenuExpanded] = useState(false);
  const [hideTrendLines, setHideTrendLines] = useState(false);

  const historicalDataState = useAppSelector((gs) => gs.historicalDataState);
  const historicalDataStateByKey = getHistoricalDataStateByKey(barChartId, conId, historicalDataState);
  const { initialized, loaded, loading, barSize, whatToShow, barDurationInDays, bars, endDate, useRth } = historicalDataStateByKey;
  const trendLineState = useAppSelector((gs) => gs.trendLineState);
  const { trendLines, loading: trendLinesLoading, loaded: trendLinesLoaded } = getTrendLineStateByConId(trendLineState, conId);

  const [yAxisValue, setYAxisValue] = useState<number | undefined>();
  const [showTrendLineDialog, setShowTrendLineDialog] = useState(false);
  const closeTrendLineDialog = () => setShowTrendLineDialog(false);
  const [enableTrendLines, setEnableTrendLines] = useState(false);

  const contract = {
    conId,
    symbol,
    secType,
    exchange,
    currency: 'USD'
  } as Contract;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!initialized) {
      dispatch(initialize({ id: barChartId, conId, barSize: initialBarSize, duration: initialDuration }));
    }
  }, [conId, initialized]);

  useEffect(() => {
    if (loadTrendLines && !trendLinesLoading && !trendLinesLoaded) {
      dispatch(getTrendLinesAsync(conId));
    }
  }, [conId, loadTrendLines, trendLinesLoading, trendLinesLoaded]);

  const params: HistoricalDataParams = useMemo(() => {
    return {
      conId,
      secType,
      exchange,
      barSize,
      durationInDays: barDurationInDays,
      whatToShow,
      endDate,
      useRth
    };
  }, [conId, secType, exchange, barSize, barDurationInDays, whatToShow, endDate, useRth]);

  const reloadHistoryData = () => {
    if (barSize && barDurationInDays) {
      dispatch(getHistoricalDataAsync(barChartId, params));
    }
  };

  useEffect(() => {
    if (initialized && !loaded) {
      reloadHistoryData();
    }
  }, [initialized]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateHistoryDataParams = (field: keyof HistoricalDataParams, value: any) => {
    if (barSize && barDurationInDays) {
      const copy = {
        ...params,
        [field]: value
      };
      dispatch(getHistoricalDataAsync(barChartId, copy));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChartMouseClick = (yAxis: number, _bar: CandleStickBar) => {
    if (enableTrendLines) {
      setYAxisValue(Number(yAxis.toFixed(2)));
      setShowTrendLineDialog(true);
    }
  };

  const handleSelectBarSize = (bs: BarSize) => {
    dispatch(updateBarSize({ id: barChartId, conId, barSize: bs }));
    handleUpdateHistoryDataParams('barSize', bs);
  };

  const handleChangeBarDurationInDays = (days: number) => {
    dispatch(updateDuration({ id: barChartId, conId, duration: days }));
    handleUpdateHistoryDataParams('durationInDays', days);
  };

  const handleSelectWhatToShow = (wts: WhatToShow) => {
    dispatch(updateWhatToShow({ id: barChartId, conId, whatToShow: wts }));
    handleUpdateHistoryDataParams('whatToShow', wts);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSetEndDate = (date: Date | undefined) => {
    const endDate = getReduxDateString(date);
    dispatch(updateEndDate({ id: barChartId, conId, endDate }));
    handleUpdateHistoryDataParams('endDate', endDate);
  };

  const handleToggleUseRth = (rth: boolean) => {
    dispatch(updateUseRth({ id: barChartId, conId, useRth: rth }));
    handleUpdateHistoryDataParams('useRth', rth);
  };

  const handleMoreChartsBtnClick = () => {
    navigate(`/security/${symbol}?tab=graphs`);
  };

  return (
    <Box>
      <BarChart
        title={title}
        barSize={barSize}
        bars={bars}
        filledDt={filledDt}
        entry={entryPrice}
        strike={strikePrice}
        limit={limitPrice}
        stop={stopPrice}
        avg={avgPrice}
        onChartMouseClick={handleChartMouseClick}
        trendLines={hideTrendLines ? undefined : trendLines}
      />

      <Box sx={{ mx: 1 }}>
        <AccordionWrapper title="Presets" collapsed={!presetMenuExpanded} onChange={() => setPresetMenuExpanded(!presetMenuExpanded)}>
          <Grid sx={{ p: 1 }} item container spacing={2} xs={12} alignItems="center">
            <Grid item xs={12} md={6}>
              <ButtonGroup variant="outlined" fullWidth>
                <Button
                  onClick={() => handleSelectWhatToShow(WhatToShow.TRADES)}
                  variant={whatToShow === WhatToShow.TRADES ? 'contained' : 'outlined'}
                >
                  Trades
                </Button>
                <Button
                  onClick={() => handleSelectWhatToShow(WhatToShow.MIDPOINT)}
                  variant={whatToShow === WhatToShow.MIDPOINT ? 'contained' : 'outlined'}
                >
                  Mid
                </Button>
                <Button
                  onClick={() => handleSelectWhatToShow(WhatToShow.ASK)}
                  variant={whatToShow === WhatToShow.ASK ? 'contained' : 'outlined'}
                >
                  Ask
                </Button>
                <Button
                  onClick={() => handleSelectWhatToShow(WhatToShow.BID)}
                  variant={whatToShow === WhatToShow.BID ? 'contained' : 'outlined'}
                >
                  Bid
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <ButtonGroup variant="outlined" fullWidth>
                <Button
                  onClick={() => handleSelectBarSize(BarSize._15_MIN)}
                  variant={barSize === BarSize._15_MIN ? 'contained' : 'outlined'}
                >
                  15M
                </Button>
                <Button onClick={() => handleSelectBarSize(BarSize._1_HR)} variant={barSize === BarSize._1_HR ? 'contained' : 'outlined'}>
                  HOUR
                </Button>
                <Button onClick={() => handleSelectBarSize(BarSize._1_DAY)} variant={barSize === BarSize._1_DAY ? 'contained' : 'outlined'}>
                  DAY
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  size="small"
                  label="Duration In Days"
                  variant="outlined"
                  type="number"
                  value={barDurationInDays}
                  onChange={(e) => handleChangeBarDurationInDays(Number(e.target.value))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectDateTimeControl
                label="End Date"
                selectedValue={endDate ? new Date(endDate) : undefined}
                onDateSelected={handleSetEndDate}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
              <FormControlLabel
                control={<Switch sx={{ ml: 1 }} onChange={() => handleToggleUseRth(!useRth)} checked={useRth} />}
                label={<Typography variant="caption">Use Regular Trading Hours</Typography>}
              />
            </Grid>
          </Grid>
        </AccordionWrapper>
      </Box>

      <Box sx={{ p: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
            {showMoreBtn && (
              <Tooltip title="More charts.." placement="top-end">
                <IconButton size="small" onClick={handleMoreChartsBtnClick} color="primary">
                  <MoreHorizIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={enableTrendLines ? 'Add Trade Line Mode' : 'Graph Mode'} placement="top">
              <IconButton color="primary" onClick={() => setEnableTrendLines(!enableTrendLines)}>
                {enableTrendLines ? <BorderHorizontalIcon /> : <CandlestickChartIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Toggle Show/Hide Trend Lines" placement="top">
              <IconButton color="primary" onClick={() => setHideTrendLines(!hideTrendLines)}>
                {hideTrendLines ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Tooltip>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button sx={{ ml: 1 }} variant="outlined" onClick={() => reloadHistoryData()}>
              Reload Chart {loading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
            </Button>
          </Box>
        </Box>
      </Box>
      <TrendLineDialog contract={contract} isOpen={showTrendLineDialog} onClose={closeTrendLineDialog} yAxis={yAxisValue} />
    </Box>
  );
};

export default BarChartPanel;
