import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContractDetails, OptionRight, Portfolio, PositionOld, Security, TradingClass } from '../../types/entities';
import { SecurityCategory } from '../../types/enums';

import { UserSettingsState, initialState } from './state';

const userSlice = createSlice({
  name: 'user-settings',
  initialState,
  reducers: {
    setUseFrozen: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.useFrozen = action.payload;
    },
    setIgnoreMissingUnderMarketPrice: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.ignoreMissingUnderMarketPrice = action.payload;
    },
    setUseSnapshot: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.useSnapshot = action.payload;
    },
    setNewsPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.newsPanelCollapsed = action.payload;
    },
    setIndicatorPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.indicatorPanelCollapsed = action.payload;
    },
    setHourGraphCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.hourGraphCollapsed = action.payload;
    },
    setDailyGraphCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.dailyGraphCollapsed = action.payload;
    },
    setSelectedCategory: (state: UserSettingsState, action: PayloadAction<SecurityCategory>) => {
      state.selectedSecurityCategory = action.payload;
    },
    setSelectedContractDetails: (state: UserSettingsState, action: PayloadAction<ContractDetails | undefined>) => {
      state.selectedContractDetails = action.payload;
    },
    setSecurityPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.securityPanelCollapsed = action.payload;
    },
    setFavoritesPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.favoritesPanelCollapsed = action.payload;
    },
    setSelectedSecurity: (state: UserSettingsState, action: PayloadAction<Security | undefined>) => {
      state.selectedSecurity = action.payload;
    },
    setTrendLinesCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.trendLinesCollapsed = action.payload;
    },
    setSelectedPortfolio: (state: UserSettingsState, action: PayloadAction<Portfolio | undefined>) => {
      state.selectedPortfolio = action.payload;
    },
    setSelectedContractSection: (state: UserSettingsState, action: PayloadAction<'all-contracts' | 'front-runners'>) => {
      state.selectedContractSection = action.payload;
    },
    setSelectedBackOfficeTab: (state: UserSettingsState, action: PayloadAction<'securities' | 'portfolios' | 'fundamentals'>) => {
      state.selectedBackOfficeTab = action.payload;
    },
    setSelectedSecurityTab: (
      state: UserSettingsState,
      action: PayloadAction<'general' | 'contracts' | 'options' | 'fundamentals' | 'graphs' | 'indicators' | 'positions'>
    ) => {
      state.selectedSecurityTab = action.payload;
    },
    setSelectedDashboardTab: (state: UserSettingsState, action: PayloadAction<'positions' | 'portfolios' | 'browse'>) => {
      state.selectedDashboardTab = action.payload;
    },
    setSelectedExitPosition: (state: UserSettingsState, action: PayloadAction<PositionOld | undefined>) => {
      state.selectedExitPosition = action.payload;
    },
    setSelectedTradingClass: (
      state: UserSettingsState,
      action: PayloadAction<{ symbol: string; tradingClass: TradingClass | undefined }>
    ) => {
      state.optionSettings[action.payload.symbol] = {
        ...state.optionSettings[action.payload.symbol],
        selectedTradingClass: action.payload.tradingClass
      };
    },
    setSelectedOptionRight: (state: UserSettingsState, action: PayloadAction<{ symbol: string; right: OptionRight }>) => {
      state.optionSettings[action.payload.symbol] = {
        ...state.optionSettings[action.payload.symbol],
        selectedRight: action.payload.right
      };
    }
  }
});

const { actions, reducer } = userSlice;
export const {
  setUseFrozen,
  setIgnoreMissingUnderMarketPrice,
  setUseSnapshot,
  setNewsPanelCollapsed,
  setIndicatorPanelCollapsed,
  setHourGraphCollapsed,
  setDailyGraphCollapsed,
  setSelectedCategory,
  setSelectedSecurity,
  setSelectedContractDetails,
  setSecurityPanelCollapsed,
  setFavoritesPanelCollapsed,
  setTrendLinesCollapsed,
  setSelectedPortfolio,
  setSelectedContractSection,
  setSelectedBackOfficeTab,
  setSelectedSecurityTab,
  setSelectedDashboardTab,
  setSelectedExitPosition,
  setSelectedTradingClass,
  setSelectedOptionRight
} = actions;
export default reducer;
