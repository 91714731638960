import { useState } from 'react';

import { Box, Button, Divider, Grid, Typography } from '@mui/material';

import { OptionContract, OrderAction } from '../../../types/entities';

import OptionVolatilityPanel from './OptionVolatilityPanel';
import TimeDetailsTable from './TimeDetailsTable';
import VolatilityDetailsTable from './VolatilityDetailsTable';

interface Props {
  option: OptionContract;
  orderAction?: OrderAction;
}

export default function OptionIndicatorPanel({ option, orderAction = 'BUY' }: Props) {
  const [showTables, setShowTables] = useState(false);

  const tables = (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" align="left" sx={{ m: 1, mb: 0.5 }}>
          Volatility
        </Typography>
        <VolatilityDetailsTable option={option} orderAction={orderAction} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" align="left" sx={{ m: 1, mb: 0.5 }}>
          Time
        </Typography>
        <TimeDetailsTable optionContract={option} orderAction={orderAction} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <OptionVolatilityPanel
        orderAction={orderAction}
        entryToExpireHistVlt={option.historicVolatility}
        entryToExpireImpliedVlt={option.impliedVolatility}
        entryToStrikeVlt={option.priceDiffVlt}
      />
      <Divider sx={{ mt: 1 }} />
      {showTables && tables}
      <Box sx={{ mt: 1, mb: 1, display: 'flex', justifyContent: 'space-between' }}>
        <Button size="small" variant={showTables ? 'contained' : 'outlined'} onClick={() => setShowTables(!showTables)}>
          Show Tables
        </Button>
      </Box>
    </>
  );
}
