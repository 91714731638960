import { PriceAlert } from '../../types/entities';

export interface PriceAlertState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  priceAlerts: PriceAlert[];
}

export const initialPriceAlertState: PriceAlertState = {
  loading: false,
  loaded: false,
  error: undefined,
  priceAlerts: []
};
