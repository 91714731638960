import { FunctionComponent } from 'react';

import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import { GRAPH_PAGE_HOUR_CHART_ID } from '../../../assets/constants';
import { Position } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import BarChartPanel from '../../panels/BarChartPanel';
import DialogCloseIconButton from '../../ui/DialogCloseIconButton';

import './styles.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
  filledDt?: Date | string | undefined;
  stopPrice?: number | undefined;
  limitPrice?: number | undefined;
  avgPrice?: number | number;
}

const PositionGraphDialog: FunctionComponent<Props> = ({ isOpen, onClose, position, filledDt, stopPrice, limitPrice, avgPrice }: Props) => {
  const { conId: posConId, localSymbol, secType: posSecType, exchange, strike } = position.details.contract;
  const isUnderlying = posSecType === 'FUT' || posSecType === 'STK';
  const conId = isUnderlying ? posConId : position.details.underConId;
  const symbol = isUnderlying ? position.details.contract.symbol : position.details.underSymbol;
  const secType = isUnderlying ? posSecType : position.details.underSecType;

  return (
    <Dialog fullWidth open={isOpen} maxWidth="xl" onClose={onClose} classes={{ paper: 'graph-dialog__paper' }}>
      <DialogTitle sx={{ m: 0, pl: 1, pt: 2 }}>
        <Typography variant="body2">
          <b>{`${symbol} - ${localSymbol}`}</b>
        </Typography>
      </DialogTitle>
      <DialogCloseIconButton onClose={onClose} />

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ mt: 2 }}>
          <BarChartPanel
            barChartId={GRAPH_PAGE_HOUR_CHART_ID}
            conId={conId}
            symbol={symbol}
            secType={secType}
            exchange={exchange}
            title={''}
            filledDt={filledDt}
            strikePrice={strike}
            limitPrice={limitPrice}
            stopPrice={stopPrice}
            avgPrice={avgPrice}
            initialBarSize={BarSize._1_HR}
            initialDuration={5}
            showMoreBtn={true}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PositionGraphDialog;
