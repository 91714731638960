import { OrderAction, OrderType } from '../../types/entities';

export const getOrderCombinationHelperMessage = (orderAction: OrderAction, orderType: OrderType, positionSize: number) => {
  if (orderType === 'MKT') {
    return undefined;
  }
  const isEntryOrder = positionSize === 0;
  if (isEntryOrder) {
    if (orderType === 'LMT') {
      if (orderAction === 'BUY') {
        return 'Buy long position at lower price';
      } else if (orderAction === 'SELL') {
        return 'Buy short position at higher price';
      }
    } else if (orderType === 'STP') {
      if (orderAction === 'BUY') {
        return 'Buy long position at higher price';
      } else if (orderAction === 'SELL') {
        return 'Buy short position at lower price';
      }
    }
  } else {
    const isLongPosition = positionSize > 0;
    if (orderType === 'LMT') {
      if (orderAction === 'BUY') {
        return isLongPosition ? 'Add to long position-size at lower price' : 'Flatten short position at lower price';
      } else if (orderAction === 'SELL') {
        return isLongPosition ? 'Flatten long position at higher price' : 'Add to short position-size at higher price';
      }
    } else if (orderType === 'STP') {
      if (orderAction === 'BUY') {
        return isLongPosition ? 'Add to long position-size at higher price' : 'Flatten short position at higher price';
      } else if (orderAction === 'SELL') {
        return isLongPosition ? 'Flatten long position at lower price' : 'Add to short position-size at lower price';
      }
    }
  }
};

export const getOrderCombinationErrorMessage = (
  orderAction: OrderAction,
  orderType: OrderType,
  triggerPrice: number,
  marketPrice: number
) => {
  if (!marketPrice) {
    return 'Market price is 0 - unable to validate trigger price';
  }
  if (orderType === 'LMT') {
    if (orderAction === 'BUY' && triggerPrice > marketPrice) {
      return 'BUY-LMT above market price will trigger instantly';
    } else if (orderAction === 'SELL' && triggerPrice < marketPrice) {
      return 'SELL-LMT below market price will trigger instantly';
    }
  } else if (orderType === 'STP') {
    if (orderAction === 'BUY' && triggerPrice < marketPrice) {
      return 'BUY-STP below market price will trigger instantly';
    } else if (orderAction === 'SELL' && triggerPrice > marketPrice) {
      return 'SELL-STP above market price will trigger instantly';
    }
  }
};

export const getPriceConditionErrorMessage = (isAbove: boolean, triggerPrice: number, marketPrice: number) => {
  if (!marketPrice) {
    return 'Market price is 0 - unable to validate trigger price';
  }
  if (isAbove && marketPrice > triggerPrice) {
    return 'Market price is already above price condition and will trigger instantly';
  }
  if (!isAbove && triggerPrice > marketPrice) {
    return 'Market price is already below price condition and will trigger instantly';
  }
};
