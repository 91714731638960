import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { updatePosition, completePosition, createPosition, deletePosition } from '../../api';
import { Position } from '../../types/entities';
import { Logger } from '../../utils/Logger';
import { add as addPorfolio } from '../position-portfolio/reducer';

import { add, fail, loading, update, remove } from './reducer';

export const createPositionAsync = (position: Position) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    createPosition(position)
      .then((response) => {
        const posWithId = response.data;
        Logger.log(posWithId);
        dispatch(add(posWithId));
        dispatch(addPorfolio({ conId: position.details.contract.conId }));
        toast.success('Position created successfully!');
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const updatePositionAsync = (pos: Position) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    updatePosition(pos)
      .then(() => {
        // Logger.log(response);
        dispatch(update(pos));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const completePositionAsync = (pos: Position) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    completePosition(pos)
      .then((response) => {
        Logger.log(response.data);
        dispatch(update(response.data));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const deletePositionAsync = (pos: Position) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    deletePosition(pos)
      .then(() => {
        dispatch(remove(pos));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
