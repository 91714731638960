import { FunctionComponent } from 'react';

import { Box, Card, Container, Skeleton, Typography } from '@mui/material';

import PositionListPanel from '../../components/panels/PositionListPanel';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import { useAppSelector } from '../../store/hooks';

import { CashOverviewPanel } from './CashOverviewPanel';
import DashboardFundamentalsPanel from './DashboardFundamentalsPanel';
import PriceAlertAccordion from './PriceAlertAccordion';

const Dashboard: FunctionComponent = () => {
  const { initialLoaded } = useAppSelector((gs) => gs.dashboardState);
  const { positions } = useAppSelector((gs) => gs.positionState);
  const { portfolios } = useAppSelector((gs) => gs.positionPortfolioState);

  const { securities } = useAppSelector((gs) => gs.securityState);
  const { loading: alertsLoading, priceAlerts } = useAppSelector((gs) => gs.priceAlertState);

  return (
    <>
      <Container sx={{ my: 3, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <Typography variant="h4">Positions</Typography>
        <CashOverviewPanel positions={positions} portfolios={portfolios} />
      </Container>
      <ResponsiveContainer>
        <Card sx={{ mb: 2 }}>
          {alertsLoading ? (
            <Skeleton sx={{ m: 2 }} variant="rounded" animation="wave" height={80} />
          ) : (
            <PriceAlertAccordion securities={securities} priceAlerts={priceAlerts} />
          )}
        </Card>
        {!initialLoaded && (
          <Box display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="rounded" animation="wave" height={60} />
            <Skeleton variant="rounded" animation="wave" height={60} />
            <Skeleton variant="rounded" animation="wave" height={60} />
          </Box>
        )}

        <PositionListPanel />

        <DashboardFundamentalsPanel mt={5} />
      </ResponsiveContainer>
    </>
  );
};

export default Dashboard;
