import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../assets/colors';
import { Position } from '../../types/entities';

interface Props {
  positions: Position[];
}

export default function PositionSummary({ positions }: Props) {
  const getPnlColor = (pnl: number) => {
    let pnlColor = 'inherit';
    if (pnl > 0) {
      pnlColor = INDICATOR_GREEN_COLOR;
    } else if (pnl < 0) {
      pnlColor = INDICATOR_RED_COLOR;
    }
    return pnlColor;
  };

  const getTradesPnl = (position: Position) => position.trades.map((x) => x.realizedPnl).reduce((a, b) => a + b, 0);

  const sumRealizedPnl = positions.reduce((partialSum, pos) => partialSum + getTradesPnl(pos), 0);
  const pnlColor = getPnlColor(sumRealizedPnl);

  const tableSummary = (
    <TableHead>
      <TableRow>
        <TableCell>Sum:</TableCell>
        <TableCell align="right" sx={{ color: pnlColor }}>
          ${sumRealizedPnl.toFixed(0)}
        </TableCell>
      </TableRow>
    </TableHead>
  );

  return (
    <TableContainer>
      <Table size="small">{tableSummary}</Table>
    </TableContainer>
  );
}
