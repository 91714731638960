import { FunctionComponent, useEffect, useState } from 'react';

import { Alert, Box, Button, ButtonGroup, Skeleton, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { fetchOptionChain } from '../../../api';
import { useAppSelector } from '../../../store/hooks';
import { Contract, OptionChainParams, OptionContract, OptionRight, TradingClass } from '../../../types/entities';
import { Logger } from '../../../utils/Logger';

import OptionChainTable from './OptionChainTable';

interface Props {
  underlying: Contract;
  tradingClass: TradingClass;
  goBack: () => void;
  right: OptionRight;
  onOptionRightChanged: (right: OptionRight) => void;
  selected: OptionContract | undefined;
  onOptionSelected: (opt: OptionContract | undefined) => void;
}

const OptionChainPanel: FunctionComponent<Props> = ({
  underlying,
  tradingClass,
  goBack,
  right,
  selected,
  onOptionSelected,
  onOptionRightChanged
}: Props) => {
  const { useFrozen } = useAppSelector((gs) => gs.userSettingsState);
  const requestParams: OptionChainParams = {
    conId: underlying.conId,
    symbol: underlying.symbol,
    localSymbol: underlying.localSymbol,
    secType: underlying.secType,
    exchange: underlying.exchange,
    currency: underlying.currency,
    multiplier: underlying.secType === 'FUT' ? underlying.multiplier : '100', // 100 is default for stock multiplier
    right: right,
    tradingClass: tradingClass?.name || '',
    tradingClassLastTradeDate: tradingClass?.lastTradeDate || '',
    useSnapshot: false,
    useFrozen,
    showInTheMoney: true
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [options, setOptions] = useState<OptionContract[]>([]);

  const fetchOptionChainAsync = () => {
    onOptionSelected(undefined); // reset selection
    setIsLoading(true);
    setError(undefined);
    fetchOptionChain(requestParams)
      .then((response) => {
        Logger.log(response.data);
        const options = response.data;
        setOptions(options);
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        setError(msg);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchOptionChainAsync();
  }, [right]);

  const day = dayjs(tradingClass.expires).format('ddd D.').toUpperCase();
  const daysApart = dayjs(tradingClass.expires).diff(dayjs(), 'day');
  const subTitle = `${day} (${daysApart}d)`;

  let errorPanel: JSX.Element | undefined;
  if (error) {
    errorPanel = (
      <Alert sx={{ my: 1, mx: 2 }} severity="error">
        {error}
      </Alert>
    );
  }

  return (
    <Box>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="caption">SELECT OPTION CHAIN CONTRACT</Typography>
        <Typography variant="caption">{subTitle}</Typography>
      </Box>
      <Box sx={{ px: 2, pb: 2 }}>
        <ButtonGroup variant="outlined" fullWidth>
          <Button onClick={() => onOptionRightChanged('CALL')} variant={right === 'CALL' ? 'contained' : 'outlined'}>
            Call
          </Button>
          <Button onClick={() => onOptionRightChanged('PUT')} variant={right === 'PUT' ? 'contained' : 'outlined'}>
            Put
          </Button>
        </ButtonGroup>
      </Box>

      {isLoading && <Skeleton sx={{ mx: 2 }} variant="rounded" animation="wave" height="200px" />}

      {error && errorPanel}

      {!isLoading && !error && (
        <OptionChainTable options={options} right={right} optionSelected={selected} onOptionSelected={onOptionSelected} />
      )}

      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button size="small" color="warning" variant="outlined" onClick={goBack}>
          Go Back
        </Button>
        <Button size="small" variant="outlined" onClick={fetchOptionChainAsync}>
          Reload Option Chain
        </Button>
      </Box>
    </Box>
  );
};

export default OptionChainPanel;
