import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FavoriteContract } from '../../types/entities';

import { FavoriteContractState, initialState } from './state';

const favoriteContractSlice = createSlice({
  name: 'favorite-contract',
  initialState,
  reducers: {
    reset: () => initialState,
    loading: (state: FavoriteContractState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: FavoriteContractState, action: PayloadAction<FavoriteContract[]>) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.favorites = action.payload;
    },
    fail: (state: FavoriteContractState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.favorites = [];
    },
    remove: (state: FavoriteContractState, action: PayloadAction<FavoriteContract>) => {
      const conId = action.payload.details.contract.conId;
      const entry = state.favorites.find((x) => x.details.contract.conId === conId);
      const index = entry ? state.favorites.indexOf(entry) : -1;
      if (index !== -1) {
        state.favorites.splice(index, 1);
      }
      state.loading = false;
      state.loaded = true;
    },
    set: (state: FavoriteContractState, action: PayloadAction<FavoriteContract>) => {
      const favs = state.favorites.filter((x) => x.details.contract.symbol !== action.payload.details.contract.symbol);
      state.favorites = [...favs, action.payload];
      state.loading = false;
      state.loaded = true;
    }
  }
});

const { actions, reducer } = favoriteContractSlice;
export const { reset, loading, success, fail, set, remove } = actions;
export default reducer;
