import { toast } from 'react-toastify';

import { fetchMarketData as fetchMarketDataApiCall } from '../../api';
import { ContractMarketData, MarketDataParams, Contract } from '../../types/entities';

export const useMarketDataQuery = () => {
  const fetchMarketData = (contract: Contract, useFrozen: boolean, pricesOnly = true, noIndicators = true) => {
    const params: MarketDataParams = {
      conId: contract.conId,
      symbol: contract.symbol,
      localSymbol: contract.localSymbol,
      exchange: contract.exchange,
      currency: 'USD',
      secType: contract.secType,
      right: contract.right,
      strike: contract.strike,
      lastTradeDateOrContractMonth: contract.lastTradeDateOrContractMonth,
      multiplier: contract.multiplier,
      noIndicators,
      pricesOnly,
      useFrozen
    };

    return new Promise<ContractMarketData>((resolve, reject) => {
      fetchMarketDataApiCall(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const msg = error.response?.data.message || error.message;
          toast.error(msg);
          reject();
        });
    });
  };

  return fetchMarketData;
};
