import { Typography } from '@mui/material';

import { INDICATOR_YELLOW_COLOR } from '../../../../assets/colors';
import { Position } from '../../../../types/entities';
import { getDaysAppart, getFuturesExpirationDate } from '../../../../utils/datetime-utils';

export const getDaysApartValue = (expires: string | Date) => {
  const expiresDt = expires ? new Date(expires) : undefined;
  if (expiresDt) {
    const daysAppart = getDaysAppart(expiresDt, new Date());
    const daysAppartLabel = `${daysAppart}d`;
    const daysAppartColor = daysAppart < 10 ? INDICATOR_YELLOW_COLOR : 'inherit';
    return (
      <Typography variant="caption" color={daysAppartColor}>
        {daysAppartLabel}
      </Typography>
    );
  }
};

export const getDetails = (position: Position) => {
  const secType = position.details.contract.secType;
  const isOption = secType === 'FOP' || secType === 'OPT';
  if (isOption) {
    const expires = position.optionExpireDate;
    if (expires && !position.closed) {
      return getDaysApartValue(expires);
    } else {
      return <Typography variant="caption">-</Typography>;
    }
  }
  const isFutures = secType === 'FUT';
  if (isFutures) {
    const realExpirationDate = position.details.realExpirationDate;
    const expires = getFuturesExpirationDate(realExpirationDate);
    if (expires && !position.closed) {
      return getDaysApartValue(expires);
    } else {
      return <Typography variant="caption">-</Typography>;
    }
  }
};
