import { Position } from '../types/entities';

export const getAveragePriceFromTrades = (trades: { price: number; size: number }[]) => {
  if (trades.length > 0) {
    //const avgPrice = avgTrades.map((x) => x.price).reduce((a, b) => a + b, 0) / avgTrades.length;
    const prices = [];
    let totalSize = 0;
    for (let i = 0; i < trades.length; i++) {
      const trade = trades[i];
      for (let y = 0; y < trade.size; y++) {
        prices.push(trade.price);
        totalSize += 1;
      }
    }
    const avgPrice = prices.reduce((a, b) => a + b, 0) / totalSize;
    const twoDecimalSpaces = avgPrice.toFixed(2);
    return Number(twoDecimalSpaces);
  }
};

export const calculatePositionAveragePrice = (position: Position) => {
  const averaging = position.averaging ?? { bearish: false, resetAveragePriceDate: undefined };

  const trades = position.trades;
  if (trades.length === 0) {
    return undefined;
  }

  const BUY = 1;
  const SELL = 2;
  const averagingDirection = !averaging.bearish ? BUY : SELL;

  let avgTrades = trades.filter((x) => x.action === averagingDirection);
  const resetDt = averaging.resetAveragePriceDate ? new Date(averaging.resetAveragePriceDate) : undefined;
  if (resetDt) {
    avgTrades = avgTrades.filter((x) => new Date(x.filled) >= resetDt);
  }

  return getAveragePriceFromTrades(avgTrades);
};
