import { FunctionComponent } from 'react';

import { Chip } from '@mui/material';

import { GRAY_COLOR, INDICATOR_GREEN_COLOR, INDICATOR_YELLOW_COLOR } from '../../assets/colors';
import { MarketTradingHours } from '../../types/enums';

interface Props {
  status: MarketTradingHours;
}

const MarketHoursIndicator: FunctionComponent<Props> = ({ status }: Props) => {
  let btnColor = 'Inherit';
  let btnTooltip = '';
  switch (status) {
    case MarketTradingHours.Closed:
      btnColor = GRAY_COLOR;
      btnTooltip = 'Market Closed';
      break;
    case MarketTradingHours.Open:
      btnColor = INDICATOR_YELLOW_COLOR;
      btnTooltip = 'Pre/Post Market';
      break;
    case MarketTradingHours.OpenLiquid:
      btnColor = INDICATOR_GREEN_COLOR;
      btnTooltip = 'Liquid Market';
      break;
  }

  return <Chip size="small" sx={{ color: btnColor }} label={btnTooltip} />;
};

export default MarketHoursIndicator;
