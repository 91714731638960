import { useMemo } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR, TABLE_BORDER_COLOR } from '../../assets/colors';
import { Security } from '../../types/entities';
import { SecurityCategory, SecuritySector } from '../../types/enums';

interface Props {
  securities: Security[];
  onSecuritySelected: (sec: Security) => void;
}

export default function SecurityListTable({ securities, onSecuritySelected }: Props) {
  const tableHeader = (
    <TableHead>
      <TableRow sx={{ whiteSpace: 'nowrap' }}>
        <TableCell>Security</TableCell>
        <TableCell>Symbol</TableCell>
        <TableCell>Exchange</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Sector</TableCell>
        <TableCell align="center">Subscribed</TableCell>
        <TableCell align="center">
          <Tooltip title="Security is hidden">
            <span>Disabled</span>
          </Tooltip>
        </TableCell>
        <TableCell align="right">Multiplier</TableCell>
        <TableCell align="right">Tick Incr</TableCell>
      </TableRow>
    </TableHead>
  );

  const sortByName = (a: Security, b: Security) => {
    if (!a || !b) {
      return 0;
    }
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  };

  const sortedSecurities = useMemo(() => {
    if (!securities || securities.length == 0) {
      return [];
    }
    return [...securities].sort(sortByName);
  }, [securities]);

  const tableRows = sortedSecurities.map((sec, index) => {
    return (
      <TableRow hover key={index} sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => onSecuritySelected(sec)}>
        <TableCell>{sec.name}</TableCell>
        <TableCell>{sec.symbol}</TableCell>
        <TableCell>{sec.exchange}</TableCell>
        <TableCell>{SecurityCategory[sec.category]}</TableCell>
        <TableCell>{SecuritySector[sec.sector]}</TableCell>
        <TableCell align="center">
          {sec.subscribed ? (
            <CheckBoxIcon sx={{ color: INDICATOR_GREEN_COLOR, fontSize: 18, mt: 0.5 }} />
          ) : (
            <CheckBoxOutlineBlankIcon sx={{ fontSize: 18, mt: 0.5 }} />
          )}
        </TableCell>
        <TableCell align="center">{sec.disabled && <CheckBoxIcon sx={{ color: INDICATOR_RED_COLOR, fontSize: 18, mt: 0.5 }} />}</TableCell>
        <TableCell align="right" sx={{ borderLeft: `solid 1px ${TABLE_BORDER_COLOR}` }}>
          {sec.multiplier}
        </TableCell>
        <TableCell align="right">{sec.tickIncrement}</TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer>
      <Table size="small" aria-label="Security List">
        {tableHeader}
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );
}
