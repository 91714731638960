import { FunctionComponent, useState } from 'react';

import { Box, Button, Card } from '@mui/material';

import GutterBox from '../../../components/ui/GutterBox';
import { useAppDispatch } from '../../../store/hooks';
import { createPositionAsync } from '../../../store/position/service';
import { ContractDetails, Security } from '../../../types/entities';
import { createUnderlyingPosition } from '../../../utils/position-utils';

import ContractDetailsTable from './ContractDetailsTable';

interface Props {
  security?: Security;
  list: ContractDetails[];
}

const ContractsTab: FunctionComponent<Props> = ({ security, list }: Props) => {
  const [selectedContractDetails, setSelectedContractDetails] = useState<ContractDetails | undefined>();
  const selectedConId = selectedContractDetails?.contract?.conId || 0;

  const dispatch = useAppDispatch();

  const handleCreatePositionBtnClick = () => {
    if (security && selectedContractDetails) {
      const position = createUnderlyingPosition(selectedContractDetails);
      dispatch(createPositionAsync(position));
    }
  };

  return (
    <>
      <Card>
        <Box sx={{ py: 2 }}>
          <ContractDetailsTable list={list} maxRows={6} selectedConId={selectedConId} onSelect={setSelectedContractDetails} />
        </Box>
      </Card>
      <GutterBox sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="success" variant="contained" onClick={handleCreatePositionBtnClick} disabled={!selectedContractDetails}>
          Create Position
        </Button>
      </GutterBox>
    </>
  );
};

export default ContractsTab;
