import { FunctionComponent, useEffect, useState } from 'react';

import { Alert, Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { completePositionAsync, deletePositionAsync } from '../../../store/position/service';
import { Position } from '../../../types/entities';
import { useConfirm } from '../../hooks/useConfirm';

interface Props {
  position: Position;
  onClose: () => void;
}

const ClosePositionPanel: FunctionComponent<Props> = ({ onClose, position }: Props) => {
  const [confirm, ConfirmDialog] = useConfirm();
  const { loaded, error } = useAppSelector((gs) => gs.positionState);
  const [actionPressed, setActionPressed] = useState(false);

  const conId = position.details.contract.conId;
  const { portfolios } = useAppSelector((gs) => gs.positionPortfolioState);
  const portfolio = portfolios.find((x) => x.conId === conId);
  const positionSize = portfolio?.size ?? 0;

  const openOrderState = useAppSelector((gs) => gs.openOrderState);
  const openOrders = openOrderState.orders.filter((x) => x.conId === conId);
  const canCompletePosition = openOrders.length === 0 && positionSize === 0;

  const handleCloseDialog = () => {
    setActionPressed(false);
    onClose();
  };

  // auto-close on save successfully
  useEffect(() => {
    if (actionPressed && loaded && !error) {
      handleCloseDialog();
    }
  });

  const dispatch = useAppDispatch();

  const removePosition = async () => {
    setActionPressed(true);
    const pos = {
      ...position,
      closed: true,
      completed: true
    };
    dispatch(deletePositionAsync(pos));
  };

  const handleRemoveBtnClick = async () => {
    if (await confirm('Are you sure you want to remove the position?')) {
      removePosition();
    }
  };

  const handleCompleteBtnClick = async () => {
    setActionPressed(true);
    const pos = {
      ...position,
      closed: true,
      complete: true
    };
    dispatch(completePositionAsync(pos));
  };

  return (
    <Box>
      <DialogContent sx={{ pt: 2, pb: 0.5, px: 2 }}>
        <Alert variant="filled" color="info">
          <Typography variant="body2">
            Position size: {positionSize}, Active orders: {openOrders.length}
          </Typography>
        </Alert>
      </DialogContent>
      <DialogActions sx={{ p: 2, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: 1 }}>
          <Button variant="contained" color="success" onClick={handleCompleteBtnClick} disabled={!canCompletePosition} fullWidth>
            Complete
          </Button>
          <Button variant="outlined" color="error" onClick={handleRemoveBtnClick} fullWidth>
            Remove
          </Button>
        </Box>
      </DialogActions>
      <ConfirmDialog />
    </Box>
  );
};

export default ClosePositionPanel;
