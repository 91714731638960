import { FunctionComponent, useState } from 'react';

import AddLinkIcon from '@mui/icons-material/AddLink';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import { FundamentalLink, Security } from '../../../types/entities';

import NewsLinkDialog from './NewsLinkDialog';
import NewsLinkTable from './NewsLinkTable';

interface Props {
  security?: Security | undefined;
}

const NewsPanel: FunctionComponent<Props> = ({ security }: Props) => {
  const symbol = security?.parentSymbol || security?.symbol;

  const [selectedLink, setSelectedLink] = useState<FundamentalLink | undefined>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const closeDialog = () => setShowEditDialog(false);

  const handleEditLinkBtnClick = (link: FundamentalLink | undefined) => {
    setSelectedLink(link);
    setShowEditDialog(true);
  };

  return (
    <>
      <Box sx={{ p: 2, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6">News Links</Typography>
        <Tooltip title="Add News Link" placement="left-start">
          <IconButton sx={{ mt: 0.5 }} size="small" color="primary" onClick={() => handleEditLinkBtnClick(undefined)}>
            <AddLinkIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <NewsLinkTable security={security} onEditBtnClick={handleEditLinkBtnClick} />
      {showEditDialog && <NewsLinkDialog symbol={symbol} link={selectedLink} isOpen={showEditDialog} onClose={closeDialog} />}
    </>
  );
};

export default NewsPanel;
