import { PositionOld } from '../../types/entities';

export interface OrderSummaryBySymbolState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  groupId?: string | undefined;
  orders: PositionOld[];
}

export const initialOrderSummeryBySymbolState: OrderSummaryBySymbolState = {
  loading: false,
  loaded: false,
  error: undefined,
  orders: []
};

export interface OrderSummaryState {
  [symbol: string]: OrderSummaryBySymbolState;
}

export const initialOrderSummaryState: OrderSummaryState = {};
