import { useState } from 'react';

import { toast } from 'react-toastify';

import { placeOrder } from '../../api';
import { PlaceOrderParams, PlaceOrderReport } from '../../types/entities';
import { OrderStatusType } from '../../types/enums';
import { Logger } from '../../utils/Logger';

export const usePlaceOrderAsync = () => {
  const [loading, setLoading] = useState(false);

  const placeOrderCall = (args: PlaceOrderParams) => {
    return new Promise<PlaceOrderReport>((resolve, reject) => {
      placeOrder(args)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const msg = error.response?.data.message || error.message;
          toast.error(msg);
          reject();
        });
    });
  };

  const placeOrderAsync = async (args: PlaceOrderParams) => {
    setLoading(true);
    try {
      const report = await placeOrderCall(args);
      if (report.status !== OrderStatusType.Cancelled && report.status !== OrderStatusType.ApiCancelled) {
        toast.success(`Order submitted with status: ${OrderStatusType[report.status]}`);
      } else {
        toast.warning(`Order submitted with status: ${OrderStatusType[report.status]}`);
      }
    } catch (error) {
      Logger.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    placeOrderAsync,
    loading
  };
};
