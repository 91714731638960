import { FunctionComponent, useEffect } from 'react';

import { CircularProgress, Alert, Box, Button, Skeleton, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getTradingClassByConId } from '../../../store/trading-class/selectors';
import { getTradingClassesAsync } from '../../../store/trading-class/service';
import { Contract, TradingClass, TradingClassParams } from '../../../types/entities';

import TradingClassTable from './TradingClassTable';

interface Props {
  underlying: Contract;
  onSelect: (tc: TradingClass | undefined) => void;
}
const TradingClassPanel: FunctionComponent<Props> = ({ underlying, onSelect }) => {
  const tradingClassState = useAppSelector((gs) => gs.tradingClassState);
  const tradingClassStateByConId = getTradingClassByConId(tradingClassState, underlying.conId);
  const loading = tradingClassStateByConId.loading;
  const loaded = tradingClassStateByConId.loaded;
  const error = tradingClassStateByConId.error;
  const tradingClasses = tradingClassStateByConId.tradingClasses;

  const dispatch = useAppDispatch();

  const reloadTradingClassesHandler = () => {
    const params: TradingClassParams = {
      conId: underlying.conId,
      secType: underlying.secType,
      symbol: underlying.symbol,
      exchange: underlying.exchange
    };
    dispatch(getTradingClassesAsync(params));
  };

  useEffect(() => {
    if (!loaded) {
      reloadTradingClassesHandler();
    }
  }, []);

  let alertPanel: JSX.Element | undefined;
  if (error) {
    alertPanel = (
      <Alert sx={{ m: 2 }} severity="error">
        {error}
      </Alert>
    );
  }

  return (
    <Box>
      <Typography sx={{ p: 2 }} variant="caption" component="div">
        SELECT EXPIRE DATE
      </Typography>
      {alertPanel}

      {loading && <Skeleton sx={{ mx: 2 }} variant="rounded" animation="wave" height="200px" />}

      {!loading && !error && <TradingClassTable list={tradingClasses} onSelect={onSelect} />}

      <Box sx={{ m: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button size="small" variant="outlined" onClick={reloadTradingClassesHandler}>
          Reload Trading Classes {loading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
        </Button>
      </Box>
    </Box>
  );
};

export default TradingClassPanel;
