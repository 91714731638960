import { TrendLine } from '../../types/entities';

export interface TrendLineStateByConId {
  loading: boolean;
  loaded: boolean;
  error?: string;
  trendLines: TrendLine[];
}

export const initialTrendLineStateByConId: TrendLineStateByConId = {
  loading: false,
  loaded: false,
  error: undefined,
  trendLines: []
};

export interface TrendLineState {
  [conId: number]: TrendLineStateByConId;
}

export const initialTrendLineState: TrendLineState = {};
