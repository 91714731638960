import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { addFavorite, fetchFavorites, removeFavorite } from '../../api';
import { FavoriteContract } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading, set, remove } from './reducer';

export const getFavoritesAsync = () => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    fetchFavorites()
      .then((response) => {
        const favorites = response.data;
        Logger.log(favorites);
        dispatch(success(favorites));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const addFavoriteAsync = (fav: FavoriteContract) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    addFavorite(fav)
      .then((response) => {
        dispatch(set(response.data));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const removeFavoriteAsync = (fav: FavoriteContract) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    removeFavorite(fav)
      .then(() => {
        dispatch(remove(fav));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
