import { PositionPortfolio } from '../../types/entities';

export interface PositionPortfolioState {
  loading: boolean;
  loaded: boolean;
  error?: string;
  portfolios: PositionPortfolio[];
}

export const initialPositionPortfolioState: PositionPortfolioState = {
  loading: false,
  loaded: false,
  error: undefined,
  portfolios: []
};
