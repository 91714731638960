import { FunctionComponent } from 'react';

import { Button, Container, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { POSTION_ZEBRA_COLOR_EVEN } from '../../assets/constants';
import ContractInsightPanel from '../../components/panels/ContactInsightPanel';
import PositionPanel from '../../components/panels/PositionPanel';
import ReloadDashboardFab from '../../components/ui/ReloadDashboardFab';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import { useAppSelector } from '../../store/hooks';

import PlaceOrderPanel from './PlaceOrderPanel';

const PlaceOrderPage: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const conIdParam = params.conId || 0;
  const conId = Number(conIdParam);

  const { loaded } = useAppSelector((gs) => gs.dashboardState);
  const { positions } = useAppSelector((gs) => gs.positionState);
  const { portfolios } = useAppSelector((gs) => gs.positionPortfolioState);
  const { orders } = useAppSelector((gs) => gs.openOrderState);
  const position = positions.find((x) => x.details.contract.conId === conId);
  const portfolio = portfolios.find((x) => x.conId === conId);
  const posOrders = orders.filter((x) => x.conId === conId);
  const positionExists = position && portfolio;

  const goBackBtnClick = () => {
    const canGoBack = location.key !== 'default';
    if (canGoBack) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const notFound = loaded && !positionExists && (
    <Typography fontSize="smaller" component="div">
      {'Could not find position..'}
    </Typography>
  );

  const content = position && portfolio && (
    <ResponsiveContainer>
      <PositionPanel position={position} portfolio={portfolio} orders={posOrders} bg={POSTION_ZEBRA_COLOR_EVEN} />

      <ContractInsightPanel my={2} position={position} />

      <PlaceOrderPanel position={position} portfolio={portfolio} />
    </ResponsiveContainer>
  );

  return (
    <>
      <Container>
        <Button sx={{ mt: 2 }} variant="outlined" onClick={goBackBtnClick}>
          Go Back
        </Button>
        <Typography variant="h4" sx={{ my: 3 }}>
          Place Order
        </Typography>
        {notFound}
      </Container>

      {content}
      <ReloadDashboardFab />
    </>
  );
};

export default PlaceOrderPage;
