import { FunctionComponent, useMemo } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
  Divider
} from '@mui/material';

import { getPriceConditionErrorMessage } from './utils';

interface Props {
  orderType: string;
  usePriceCondition: boolean;
  setUsePriceCondition: (val: boolean) => void;
  underlyingMarketPrice: number;
  underlyingTriggerPrice: number;
  setUnderlyingTriggerPrice: (price: number) => void;
  isAbovePrice: boolean;
  setIsAbovePrice: (isAbove: boolean) => void;
  onRefreshUnderlying: () => void;
  loadingUnderlying: boolean;
}

const OrderOptionPanel: FunctionComponent<Props> = ({
  orderType,
  usePriceCondition,
  setUsePriceCondition,
  underlyingTriggerPrice,
  setUnderlyingTriggerPrice,
  underlyingMarketPrice,
  isAbovePrice,
  setIsAbovePrice,
  onRefreshUnderlying,
  loadingUnderlying
}: Props) => {
  const priceConditionErrorMessage = useMemo(() => {
    if (!usePriceCondition) {
      return undefined;
    }
    return getPriceConditionErrorMessage(isAbovePrice, underlyingTriggerPrice, underlyingMarketPrice);
  }, [usePriceCondition, isAbovePrice, underlyingTriggerPrice, underlyingMarketPrice]);

  return (
    <>
      <Divider sx={{ mt: 2, mb: 1 }}>
        <Typography variant="caption" sx={{ color: '#ccc' }}>
          Option Contract
        </Typography>
      </Divider>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                sx={{ ml: 1 }}
                onChange={() => setUsePriceCondition(!usePriceCondition)}
                checked={usePriceCondition}
                disabled={orderType !== 'MKT'}
              />
            }
            label={<Typography variant="body2">Use Price Condition</Typography>}
          />
        </Grid>
        {usePriceCondition && (
          <Grid item xs={12} md={6}>
            <Box sx={{ position: 'relative', display: 'flex', gap: 1, alignItems: 'center' }}>
              <FormControl sx={{ width: '100%' }} error={!!priceConditionErrorMessage}>
                <InputLabel>Underlying Price</InputLabel>
                <OutlinedInput
                  sx={{ paddingRight: '32px' }}
                  value={underlyingTriggerPrice}
                  onChange={(e) => setUnderlyingTriggerPrice(Number(e.target.value))}
                  size="small"
                  type="number"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label="Underlying Price"
                />
                {priceConditionErrorMessage && <FormHelperText>{priceConditionErrorMessage}</FormHelperText>}
              </FormControl>
              <Tooltip title="Refresh Market Data" sx={{ position: 'absolute', top: '5px', right: '8px' }}>
                <IconButton size="small" onClick={onRefreshUnderlying}>
                  <RefreshIcon sx={{ fontSize: 20 }} />
                  {loadingUnderlying && (
                    <CircularProgress
                      size={30}
                      sx={{
                        color: 'white',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        )}
        {usePriceCondition && (
          <Grid item xs={12} md={6}>
            <ButtonGroup variant="outlined" disableElevation fullWidth>
              <Button onClick={() => setIsAbovePrice(true)} variant={isAbovePrice ? 'contained' : 'outlined'}>
                Above
              </Button>
              <Button onClick={() => setIsAbovePrice(false)} variant={!isAbovePrice ? 'contained' : 'outlined'}>
                Below
              </Button>
            </ButtonGroup>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default OrderOptionPanel;
