import { useState } from 'react';

import { toast } from 'react-toastify';

import { testRunAveragingTask as testRunAveragingTaskApiCall } from '../../api';
import { Logger } from '../../utils/Logger';

export const useTestRunAveragingTask = () => {
  const [loading, setLoading] = useState(false);

  const testRunAveragingTaskCall = (conId: number) => {
    return new Promise((resolve, reject) => {
      testRunAveragingTaskApiCall(conId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          const msg = error.response?.data.message || error.message;
          toast.error(msg);
          reject();
        });
    });
  };

  const testRunAveragingTask = async (conId: number) => {
    setLoading(true);
    try {
      const response = await testRunAveragingTaskCall(conId);
      Logger.log(response);
    } catch (error) {
      Logger.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    testRunAveragingTask,
    loading
  };
};
