// import FavoriteIcon from '@mui/icons-material/Favorite';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Box, IconButton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { GOLD_COLOR, WHITE_COLOR } from '../../../assets/colors';
import useToggleFavorite from '../../../components/hooks/useToggleFavorite';
import Spinner from '../../../components/ui/Spinner';
import { ContractDetails } from '../../../types/entities';
import { getFormattedContractMonth, getFuturesExpirationDate } from '../../../utils/datetime-utils';

const INVALID_FUT_SEC_TYPE = 'CONTFUT';
const DEFAULT_STOCK_MULTIPLIER = '100';

interface Props {
  list: ContractDetails[];
  maxRows: number;
  selectedConId: number;
  onSelect?: (selected: ContractDetails) => void;
}

export default function ContractDetailsTable({ list, selectedConId, onSelect, maxRows }: Props) {
  const [isFavorite, toggleFavorite, loading] = useToggleFavorite();

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Sec Type</TableCell>
        <TableCell>Symbol</TableCell>
        <TableCell>Local Symbol</TableCell>
        <TableCell align="center">Front Runner</TableCell>
        <TableCell align="right">Multiplier</TableCell>
        <TableCell align="right">Contract Month</TableCell>
        <TableCell align="right">Expires</TableCell>
      </TableRow>
    </TableHead>
  );

  const handleClick = (d: ContractDetails) => {
    if (d.contract.secType === INVALID_FUT_SEC_TYPE) {
      const details = {
        ...d,
        contract: {
          ...d.contract,
          secType: 'FUT'
        }
      };
      if (onSelect) {
        onSelect(details);
      }
    } else {
      if (onSelect) {
        onSelect(d);
      }
    }
  };

  const tableRows = list.slice(0, maxRows).map((details, index) => {
    const contract = details.contract;
    const { contractMonth, realExpirationDate } = details;
    const formattedContractMonth = getFormattedContractMonth(contractMonth);
    const expiresDate = getFuturesExpirationDate(realExpirationDate);
    const formattedExpiresDate = expiresDate ? expiresDate.toLocaleDateString() : 'N/A';
    const { conId, symbol, localSymbol, secType, multiplier } = contract;

    // bugfix
    const secTypeFix = secType === INVALID_FUT_SEC_TYPE ? 'FUT' : secType;
    const multiplierFix = secType === 'STK' ? DEFAULT_STOCK_MULTIPLIER : multiplier;

    const handleToggleFavorite = (event: React.MouseEvent<HTMLButtonElement>, details: ContractDetails) => {
      toggleFavorite(details);
      event.stopPropagation();
    };

    const isFavoriteContract = isFavorite(details);

    return (
      <TableRow key={index} selected={conId === selectedConId} onClick={() => handleClick(details)} sx={{ cursor: 'pointer' }}>
        <TableCell>{secTypeFix}</TableCell>
        <TableCell>{symbol}</TableCell>
        <TableCell>{localSymbol}</TableCell>
        <TableCell align="center">
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <IconButton color="primary" onClick={(e) => handleToggleFavorite(e, details)}>
              {isFavoriteContract ? (
                <StarIcon sx={{ fontSize: 20, color: GOLD_COLOR }} />
              ) : (
                <StarOutlineIcon sx={{ fontSize: 20, color: WHITE_COLOR }} />
              )}
            </IconButton>
          </Box>
        </TableCell>
        <TableCell align="right">{multiplierFix}</TableCell>
        <TableCell align="right">{formattedContractMonth || 'N/A'}</TableCell>
        <TableCell align="right">{formattedExpiresDate}</TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          {tableHeader}
          <TableBody>{tableRows}</TableBody>
        </Table>
      </TableContainer>
      <Spinner loading={loading} />
    </>
  );
}
