import { FunctionComponent, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tab,
  Tooltip,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TABLE_BORDER_COLOR } from '../../assets/colors';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import WrappingTabs from '../../components/ui/WrappingTabs';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSelectedCategory } from '../../store/user-settings/reducer';
import { Security } from '../../types/entities';
import { SecurityCategory } from '../../types/enums';

import SearchDialog from './SearchDialog';
import SecurityDialog from './SecurityDialog';
import SecurityListTable from './SecurityListTable';

const SecurityListPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const securityState = useAppSelector((gs) => gs.securityState);
  const { securities } = securityState;

  const [securitySelected, setSecuritySelected] = useState<Security | undefined>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  const [securityFilter, setSecurityFilter] = useState('');

  const { selectedSecurityCategory } = useAppSelector((gs) => gs.userSettingsState);
  const dispatch = useAppDispatch();

  const securitiesByCategory = useMemo(() => {
    switch (selectedSecurityCategory) {
      case SecurityCategory.Futures:
        return securities.filter((x) => x.category === SecurityCategory.Futures);
      case SecurityCategory.MicroFutures:
        return securities.filter((x) => x.category === SecurityCategory.MicroFutures);
      case SecurityCategory.Stock:
        return securities.filter((x) => x.category === SecurityCategory.Stock);
      case SecurityCategory.Shortlist:
        return securities.filter((x) => x.shortList);
    }
  }, [securities, selectedSecurityCategory]);

  const filteredSecurities = useMemo(() => {
    if (securityFilter === '') {
      return securitiesByCategory;
    }
    const lowerCaseFilterKeyword = securityFilter.toLocaleLowerCase();
    return securitiesByCategory.filter(
      (x) => x.name.toLowerCase().includes(lowerCaseFilterKeyword) || x.symbol.toLowerCase().includes(lowerCaseFilterKeyword)
    );
  }, [securitiesByCategory, securityFilter]);

  const onSecuritySelected = (sec: Security) => {
    // setSecuritySelected(sec);
    // setShowEditDialog(true);
    navigate(`/security/${sec.symbol}`);
  };

  const handleSecuritySearchBtnClick = () => {
    setSecuritySelected(undefined);
    setShowSearchDialog(true);
  };

  const handleAddSecurityBtnClick = () => {
    setSecuritySelected(undefined);
    setShowEditDialog(true);
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: SecurityCategory) => {
    dispatch(setSelectedCategory(newValue));
  };

  return (
    <div>
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
          <Typography variant="h4" sx={{ my: 3 }}>
            Securities
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Tooltip title="Security Search" placement="top">
              <Button size="small" color="primary" variant="outlined" onClick={handleSecuritySearchBtnClick}>
                <SearchIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Add Security" placement="top-end">
              <Button size="small" color="primary" variant="outlined" onClick={handleAddSecurityBtnClick}>
                <AddIcon />
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Container>
      <ResponsiveContainer>
        <WrappingTabs value={selectedSecurityCategory} onChange={handleTabChange} aria-label="Security Categories">
          <Tab value={SecurityCategory.Shortlist} label="Shortlist" />
          <Tab value={SecurityCategory.Stock} label="Stocks" />
          <Tab value={SecurityCategory.Futures} label="Futures" />
          <Tab value={SecurityCategory.MicroFutures} label="Micro Futures" />
        </WrappingTabs>
        <Card>
          <Box sx={{ m: 2 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password" size="small">
                Filter
              </InputLabel>
              <OutlinedInput
                fullWidth
                size="small"
                type="text"
                value={securityFilter}
                onChange={(e) => setSecurityFilter(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSecurityFilter('')} edge="end">
                      <HighlightOffIcon sx={{ color: TABLE_BORDER_COLOR }} />
                    </IconButton>
                  </InputAdornment>
                }
                label="Filter"
              />
            </FormControl>
          </Box>
          <SecurityListTable securities={filteredSecurities} onSecuritySelected={onSecuritySelected} />
        </Card>
      </ResponsiveContainer>
      {showEditDialog && (
        <SecurityDialog
          isOpen={showEditDialog}
          onClose={() => setShowEditDialog(false)}
          securities={securities}
          security={securitySelected}
          category={selectedSecurityCategory}
        />
      )}
      {showSearchDialog && (
        <SearchDialog
          isOpen={showSearchDialog}
          onClose={() => setShowSearchDialog(false)}
          securities={securities}
          category={selectedSecurityCategory}
        />
      )}
    </div>
  );
};

export default SecurityListPage;
