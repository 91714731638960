import { FunctionComponent, useState, useEffect, useMemo } from 'react';

import { Box, Button, ButtonGroup, Card, Container, Divider, Typography } from '@mui/material';

import { POSTION_ZEBRA_COLOR_EVEN, POSTION_ZEBRA_COLOR_ODD } from '../../assets/constants';
import PositionPanel from '../../components/panels/PositionPanel';
import GutterBox from '../../components/ui/GutterBox';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import Spinner from '../../components/ui/Spinner';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getTradeHistoryStateByDate } from '../../store/trade-history/selectors';
import { getTradeHistoryAsync } from '../../store/trade-history/service';
import { Position, PositionPortfolio } from '../../types/entities';
import { MarketTradingHours } from '../../types/enums';
import { months } from '../../utils/month-utils';

import PositionSummary from './PositionSummary';

const PositionHistoryPage: FunctionComponent = () => {
  const now = new Date();
  const yearNow = now.getFullYear();
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [selectedMonth, setSelectedMonth] = useState(now.getMonth() + 1);

  const tradeHistoryState = useAppSelector((gs) => gs.tradeHistoryState);
  const { loading, loaded, positions } = getTradeHistoryStateByDate(tradeHistoryState, selectedYear, selectedMonth);

  const dispatch = useAppDispatch();

  const reloadPositionHistory = () => {
    dispatch(getTradeHistoryAsync(selectedYear, selectedMonth));
  };

  useEffect(() => {
    if (!loaded) {
      reloadPositionHistory();
    }
  }, [selectedYear, selectedMonth]);

  const sortPositions = (a: Position, b: Position) => {
    if (!a.completedDate || !b.completedDate) {
      return 0;
    }
    return a.completedDate < b.completedDate ? 1 : -1;
  };

  const historyPositions = useMemo(() => {
    return positions
      .slice()
      .sort(sortPositions)
      .map((pos, index) => {
        const { conId } = pos.details.contract;
        const emptyPortfolio = {
          conId,
          size: 0,
          marketPrice: 0,
          marketValue: 0,
          unrealizedPnl: 0,
          underMarketPrice: 0,
          marketTradingHours: MarketTradingHours.Closed
        } as PositionPortfolio;
        return (
          <PositionPanel
            key={pos.id}
            position={pos}
            portfolio={emptyPortfolio}
            orders={[]}
            bg={index % 2 === 0 ? POSTION_ZEBRA_COLOR_EVEN : POSTION_ZEBRA_COLOR_ODD}
          />
        );
      });
  }, [positions]);

  const noPositionsPanel =
    loaded && positions.length === 0 ? (
      <GutterBox>
        <Typography fontSize="smaller" component="div">
          No positions exists..
        </Typography>
      </GutterBox>
    ) : undefined;

  return (
    <>
      <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Position History
        </Typography>
      </Container>
      <ResponsiveContainer>
        <Card sx={{ mb: 2 }}>
          <Box sx={{ p: 2, pb: 0 }}>
            <Typography variant="caption" component="div" sx={{ mb: 0.5 }}>
              SELECT YEAR
            </Typography>
            <ButtonGroup variant="outlined" disableElevation fullWidth>
              <Button onClick={() => setSelectedYear(yearNow - 2)} variant={selectedYear === yearNow - 2 ? 'contained' : 'outlined'}>
                {yearNow - 2}
              </Button>
              <Button onClick={() => setSelectedYear(yearNow - 1)} variant={selectedYear === yearNow - 1 ? 'contained' : 'outlined'}>
                {yearNow - 1}
              </Button>
              <Button onClick={() => setSelectedYear(yearNow)} variant={selectedYear === yearNow ? 'contained' : 'outlined'}>
                {yearNow}
              </Button>
            </ButtonGroup>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography variant="caption" component="div" sx={{ mb: 0.5 }}>
              SELECT MONTH
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {months.map((month, i) => (
                <Button key={i} onClick={() => setSelectedMonth(i + 1)} variant={selectedMonth === i + 1 ? 'contained' : 'outlined'}>
                  {month}
                </Button>
              ))}
            </Box>
          </Box>
          <Divider />
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button size="small" variant="outlined" onClick={reloadPositionHistory}>
              Reload History Month
            </Button>
          </Box>
        </Card>
      </ResponsiveContainer>
      <Spinner loading={loading} />
      <ResponsiveContainer>
        {noPositionsPanel}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>{historyPositions}</Box>
        <Box mt={2}>
          <PositionSummary positions={positions} />
        </Box>
      </ResponsiveContainer>
    </>
  );
};

export default PositionHistoryPage;
