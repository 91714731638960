import { useState } from 'react';

import { toast } from 'react-toastify';

import { checkOrderMargin } from '../../api';
import { OrderState, PlaceOrderParams } from '../../types/entities';
import { Logger } from '../../utils/Logger';

export const useCheckMarginAsync = () => {
  const [loading, setLoading] = useState(false);

  const checkMarginCall = (args: PlaceOrderParams) => {
    return new Promise<OrderState>((resolve, reject) => {
      checkOrderMargin(args)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const msg = error.response?.data.message || error.message;
          toast.error(msg);
          reject();
        });
    });
  };

  const checkMarginAsync = async (args: PlaceOrderParams, callback: (result: OrderState) => void) => {
    setLoading(true);
    try {
      const orderState = await checkMarginCall(args);
      callback(orderState);
    } catch (error) {
      Logger.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    checkMarginAsync,
    loading
  };
};
