import { RaceDriver, RaceDriverIcon } from '../../../../components/panels/RaceTrack';
import { Position, PositionOld } from '../../../../types/entities';

export const getRaceDuractionPct = (position: Position) => {
  const entryDate = position.created;
  const expires = position.optionExpireDate;
  if (!entryDate || !expires) {
    return;
  }

  const now = new Date();
  const createdDt = new Date(entryDate);
  const expiresDt = new Date(expires);
  const tsWhole = expiresDt.getTime() - createdDt.getTime();
  const tsPart = now.getTime() - createdDt.getTime();
  return (tsPart / tsWhole) * 100;
};

export const getRaceDuractionPctOld = (position: PositionOld) => {
  const entryDate = position.entryDate;
  const expires = position.expirationDate;
  if (!entryDate || !expires) {
    return;
  }

  const now = new Date();
  const createdDt = new Date(entryDate);
  const expiresDt = new Date(expires);
  const tsWhole = expiresDt.getTime() - createdDt.getTime();
  const tsPart = now.getTime() - createdDt.getTime();
  return (tsPart / tsWhole) * 100;
};

const getDriverIcon = (positionSize: number, right: string | undefined): RaceDriverIcon => {
  if (positionSize == 0) {
    return 'car-gray';
  }
  if (right) {
    if (positionSize > 0) {
      return right === 'C' ? 'car-green' : 'car-red';
    } else {
      return right === 'C' ? 'car-red' : 'car-green';
    }
  }

  return positionSize > 0 ? 'car-green' : 'car-red';
};

export const getRaceCarDriver = (position: Position, positionSize: number, profitPct: number, lossPct: number) => {
  const { localSymbol, right } = position.details.contract;

  const fullRange = 200;
  let progress = 100;
  if (profitPct > 0) {
    progress += profitPct;
  } else if (lossPct > 0) {
    progress -= lossPct;
  }
  const progressPct = (progress * 100) / fullRange;
  const icon = getDriverIcon(positionSize, right);
  const driver: RaceDriver = {
    name: localSymbol,
    icon,
    progressPct
  };

  return driver;
};

export const getRaceCarDriverOld = (position: PositionOld, profitPct: number, lossPct: number) => {
  const { localSymbol } = position;

  const fullRange = 200;
  let progress = 100;
  if (profitPct > 0) {
    progress += profitPct;
  } else if (lossPct > 0) {
    progress -= lossPct;
  }
  const progressPct = (progress * 100) / fullRange;
  const icon = getDriverIcon(position.size, position.right);
  const driver: RaceDriver = {
    name: localSymbol,
    icon,
    progressPct
  };

  return driver;
};
