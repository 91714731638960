import { FunctionComponent, useMemo, useState } from 'react';

import ScheduleIcon from '@mui/icons-material/Schedule';
import { IconButton, Tooltip } from '@mui/material';

import { GRAY_COLOR, INDICATOR_GREEN_COLOR, INDICATOR_YELLOW_COLOR } from '../../assets/colors';
import { Position, PositionPortfolio } from '../../types/entities';
import { MarketTradingHours } from '../../types/enums';
import PositionLogDialog from '../dialogs/PositionLogDialog';

interface Props {
  position: Position;
  portfolio: PositionPortfolio;
}

const ShowPositionLogButton: FunctionComponent<Props> = ({ position, portfolio }: Props) => {
  const [showPositionLogDialog, setShowPositionLogDialog] = useState(false);

  const handleScheduleBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowPositionLogDialog(true);
  };

  const positionLogBtn = useMemo(() => {
    let btnColor = 'Inherit';
    let btnTooltip = '';
    switch (portfolio.marketTradingHours) {
      case MarketTradingHours.Closed:
        btnColor = GRAY_COLOR;
        btnTooltip = 'Market Closed';
        break;
      case MarketTradingHours.Open:
        btnColor = INDICATOR_YELLOW_COLOR;
        btnTooltip = 'Pre/Post Market';
        break;
      case MarketTradingHours.OpenLiquid:
        btnColor = INDICATOR_GREEN_COLOR;
        btnTooltip = 'Liquid Market';
        break;
    }

    return (
      <Tooltip title={btnTooltip} placement="top">
        <IconButton size="small" onClick={handleScheduleBtnClick} sx={{ color: btnColor }}>
          <ScheduleIcon sx={{ fontSize: 20, mx: 0.3 }} />
        </IconButton>
      </Tooltip>
    );
  }, [portfolio]);

  return (
    <>
      {positionLogBtn}
      {showPositionLogDialog && (
        <PositionLogDialog show={showPositionLogDialog} onClose={() => setShowPositionLogDialog(false)} position={position} />
      )}
    </>
  );
};

export default ShowPositionLogButton;
