import { FunctionComponent, useMemo, useState } from 'react';

import { Box, Button, CircularProgress } from '@mui/material';

import { BACKTEST_PAGE_CHART_ID, POSTION_BACKTEST_COLOR } from '../../../assets/constants';
import { useBacktestPosition } from '../../../components/hooks/useBacktestPostion';
import BarChartPanel from '../../../components/panels/BarChartPanel';
import PositionPanel from '../../../components/panels/PositionPanel';
import AccordionWrapper from '../../../components/ui/AccordionWrapper';
import GutterBox from '../../../components/ui/GutterBox';
import { getHistoricalDataStateByKey } from '../../../store/historical-data/selectors';
import { useAppSelector } from '../../../store/hooks';
import {
  Averaging,
  BacktestParams,
  BacktestResult,
  CandleStickBar,
  Position,
  PositionPortfolio,
  SimulatorSettings
} from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import { deepCopyPosition } from '../../../utils/position-utils';

interface Props {
  position: Position;
  portfolio: PositionPortfolio;
  averagingForm: Averaging;
  onSave: (settings: SimulatorSettings) => void;
}

const BacktestPanel: FunctionComponent<Props> = ({ position, portfolio }: Props) => {
  const { loading, backtestPositionAsync } = useBacktestPosition();
  const [backtestResult, setBacktestResult] = useState<BacktestResult | undefined>({ position, portfolio });
  const [backtestChartCollapsed, setBacktestChartCollapsed] = useState(false);

  const contract = position.details.contract;
  const historicalDataState = useAppSelector((gs) => gs.historicalDataState);
  const historyData = getHistoricalDataStateByKey(BACKTEST_PAGE_CHART_ID, contract.conId, historicalDataState);

  const bars: CandleStickBar[] = useMemo(() => {
    if (!historyData || !historyData.bars) {
      return [];
    }
    return historyData.bars.map((bar) => ({
      x: new Date(bar.x),
      y: bar.y
    }));
  }, [historyData]);

  async function handleBacktestBtnClick() {
    // clean
    const copy = deepCopyPosition(position);
    copy.logs = [];
    copy.trades = [];
    const backtestParams: BacktestParams = {
      position: copy,
      candleStickBars: bars
    };
    const pos = await backtestPositionAsync(backtestParams);
    setBacktestResult(pos);
  }

  return (
    <>
      <AccordionWrapper
        title="Backtest Period"
        collapsed={backtestChartCollapsed}
        onChange={() => setBacktestChartCollapsed(!backtestChartCollapsed)}
      >
        <Box sx={{ p: 1 }}>
          <BarChartPanel
            barChartId={BACKTEST_PAGE_CHART_ID}
            symbol={contract.symbol}
            conId={contract.conId}
            title={contract.localSymbol}
            exchange={contract.exchange}
            secType={contract.secType}
            initialBarSize={BarSize._1_HR}
            initialDuration={5}
          />
        </Box>
      </AccordionWrapper>
      {backtestResult && (
        <PositionPanel mt={2} position={backtestResult.position} portfolio={backtestResult.portfolio} bg={POSTION_BACKTEST_COLOR} />
      )}

      <GutterBox sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" color="success" onClick={() => handleBacktestBtnClick()}>
          Backtest {loading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
        </Button>
      </GutterBox>
    </>
  );
};

export default BacktestPanel;
