import { CandleStickBar } from '../../types/entities';
import { BarSize, WhatToShow } from '../../types/enums';

export interface HistoricalDataStateByKey {
  initialized: boolean;
  loading: boolean;
  loaded: boolean;
  error?: string;
  barSize: BarSize;
  barDurationInDays: number;
  whatToShow: WhatToShow;
  endDate?: string | undefined;
  useRth: boolean;
  bars: CandleStickBar[];
}

export const initialHistoricalDataStateByKey: HistoricalDataStateByKey = {
  initialized: false,
  loading: false,
  loaded: false,
  error: undefined,
  barSize: BarSize._1_DAY,
  barDurationInDays: 100,
  whatToShow: WhatToShow.TRADES,
  useRth: false,
  bars: []
};

export interface HistoricalDataState {
  [key: string]: HistoricalDataStateByKey;
}

export const initialHistoricalDataState: HistoricalDataState = {};
