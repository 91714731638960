import { FunctionComponent, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Link } from 'react-router-dom';

import { createFundamentalNoteAsync, removeFundamentalNoteAsync, updateFundamentalNoteAsync } from '../../store/fundamental-note/service';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FundamentalNote, Security } from '../../types/entities';
import { FundamentalTrend, FundamentalType, PeriodMonthPart } from '../../types/enums';
import { months } from '../../utils/month-utils';

interface Props {
  security: Security;
  isOpen: boolean;
  onClose: () => void;
  note?: FundamentalNote;
}

const FundamentalNoteDialog: FunctionComponent<Props> = ({ security, isOpen, onClose, note }: Props) => {
  const { loaded, error } = useAppSelector((gs) => gs.fundamentalNoteState);
  const [actionPressed, setActionPressed] = useState(false);

  const symbol = security.parentSymbol || security.symbol;
  const now = new Date();

  const noteTemplate: FundamentalNote = {
    id: '',
    symbol,
    type: FundamentalType.Period,
    periodMonth: now.getMonth() + 1,
    periodMonthPart: PeriodMonthPart.Beginning,
    eventDate: 1,
    trend: FundamentalTrend.Volatile,
    note: ''
  };

  const [noteForm, setNoteForm] = useState<FundamentalNote>(note || noteTemplate);

  useEffect(() => {
    if (note) {
      setNoteForm(note);
    }
  }, [note]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateNoteForm = (field: keyof FundamentalNote, value: any) => {
    const copy = {
      ...noteForm,
      [field]: value
    } as FundamentalNote;
    setNoteForm(copy);
  };

  const handleCloseDialog = () => {
    setActionPressed(false);
    onClose();
  };

  // auto-close on save successfully
  useEffect(() => {
    if (actionPressed && loaded && !error) {
      handleCloseDialog();
    }
  });

  const dispatch = useAppDispatch();

  const handleSaveNote = () => {
    setActionPressed(true);
    const isNewNote = !note;
    if (isNewNote) {
      dispatch(createFundamentalNoteAsync(noteForm));
    } else {
      dispatch(updateFundamentalNoteAsync(noteForm));
    }
  };

  const handleRemoveNote = () => {
    setActionPressed(true);
    const isNewNote = !note;
    if (isNewNote) {
      handleCloseDialog();
    } else {
      dispatch(removeFundamentalNoteAsync(noteForm));
    }
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogContent sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <Typography variant="h6">Fundamental Note</Typography>
          {!!security && <Typography>{security.name}</Typography>}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Typography variant="caption" component="div" sx={{ mb: 0.5 }}>
            NOTE TYPE
          </Typography>
          <ButtonGroup variant="outlined" disableElevation fullWidth>
            <Button
              onClick={() => updateNoteForm('type', FundamentalType.Period)}
              variant={noteForm.type === FundamentalType.Period ? 'contained' : 'outlined'}
            >
              Period
            </Button>
            <Button
              onClick={() => updateNoteForm('type', FundamentalType.Event)}
              variant={noteForm.type === FundamentalType.Event ? 'contained' : 'outlined'}
            >
              Event
            </Button>
          </ButtonGroup>
        </Box>

        <FormControl fullWidth>
          <InputLabel id="month-label" size="small">
            Month
          </InputLabel>
          <Select
            name="month-label"
            labelId="month-label"
            value={noteForm.periodMonth ?? ''}
            label="Month"
            onChange={(e) => updateNoteForm('periodMonth', Number(e.target.value))}
            size="small"
          >
            <MenuItem key="" value="">
              Velg..
            </MenuItem>
            {}
            {months.map((x, i) => (
              <MenuItem key={x} value={i + 1}>
                {x}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {noteForm.type === FundamentalType.Period && (
          <Box>
            <Typography variant="caption" component="div" sx={{ mb: 0.5 }}>
              MONTH PART
            </Typography>
            <ButtonGroup variant="outlined" disableElevation fullWidth>
              <Button
                onClick={() => updateNoteForm('periodMonthPart', PeriodMonthPart.Beginning)}
                variant={noteForm.periodMonthPart === PeriodMonthPart.Beginning ? 'contained' : 'outlined'}
              >
                Beginning
              </Button>
              <Button
                onClick={() => updateNoteForm('periodMonthPart', PeriodMonthPart.End)}
                variant={noteForm.periodMonthPart === PeriodMonthPart.End ? 'contained' : 'outlined'}
              >
                End
              </Button>
              <Button
                onClick={() => updateNoteForm('periodMonthPart', PeriodMonthPart.Full)}
                variant={noteForm.periodMonthPart === PeriodMonthPart.Full ? 'contained' : 'outlined'}
              >
                Full
              </Button>
            </ButtonGroup>
          </Box>
        )}

        {noteForm.type === FundamentalType.Event && (
          <TextField
            name="note"
            size="small"
            label="Event Date"
            variant="outlined"
            type="number"
            value={noteForm.eventDate}
            onChange={(e) => updateNoteForm('eventDate', Number(e.target.value))}
          />
        )}

        <Box>
          <Typography variant="caption" component="div" sx={{ mb: 0.5 }}>
            TREND
          </Typography>
          <ButtonGroup variant="outlined" disableElevation fullWidth>
            <Button
              onClick={() => updateNoteForm('trend', FundamentalTrend.Volatile)}
              variant={noteForm.trend === FundamentalTrend.Volatile ? 'contained' : 'outlined'}
            >
              Volatile
            </Button>
            <Button
              onClick={() => updateNoteForm('trend', FundamentalTrend.Bullish)}
              variant={noteForm.trend === FundamentalTrend.Bullish ? 'contained' : 'outlined'}
            >
              Bullish
            </Button>
            <Button
              onClick={() => updateNoteForm('trend', FundamentalTrend.Bearish)}
              variant={noteForm.trend === FundamentalTrend.Bearish ? 'contained' : 'outlined'}
            >
              Bearish
            </Button>
          </ButtonGroup>
        </Box>

        <TextField
          name="note"
          size="small"
          label="Note"
          variant="outlined"
          type="text"
          value={noteForm.note || ''}
          onChange={(e) => updateNoteForm('note', e.target.value)}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            name="note-url-ref"
            size="small"
            label="Url Ref"
            variant="outlined"
            type="text"
            value={noteForm.urlRef || ''}
            onChange={(e) => updateNoteForm('urlRef', e.target.value)}
          />
          {noteForm.urlRef && (
            <Box sx={{ my: 1 }}>
              <Link to={noteForm.urlRef} target="_blank">
                <Button size="small" variant="text" color="info">
                  Go To Url
                </Button>
              </Link>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', gap: 1, flexGrow: 1, justifyContent: 'flex-start' }}>
          <Button variant="contained" color="error" onClick={handleRemoveNote}>
            Remove
          </Button>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={handleSaveNote}>
            Save
          </Button>
          <Button onClick={handleCloseDialog} variant="outlined">
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FundamentalNoteDialog;
