import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContractMarketData } from '../../types/entities';

import { MarketDataState, initialMarketDataState } from './state';

export type MarketDataSuccessAction = {
  conId: number;
  marketData: ContractMarketData;
};

export type MarketDataFailedAction = {
  conId: number;
  error: string | undefined;
};

export type MarketDataLoadingAction = {
  conId: number;
  loading: boolean;
};

const marketDataSlice = createSlice({
  name: 'market-data',
  initialState: initialMarketDataState,
  reducers: {
    reset: () => initialMarketDataState,
    loading: (state: MarketDataState, action: PayloadAction<MarketDataLoadingAction>) => {
      state[action.payload.conId] = {
        error: undefined,
        loading: action.payload.loading,
        loaded: !action.payload.loading,
        initiallyLoaded: state[action.payload.conId]?.initiallyLoaded,
        marketData: state[action.payload.conId]?.marketData
      };
    },
    success: (state: MarketDataState, action: PayloadAction<MarketDataSuccessAction>) => {
      state[action.payload.conId] = {
        error: undefined,
        loading: false,
        loaded: true,
        initiallyLoaded: true,
        marketData: action.payload.marketData
      };
    },
    fail: (state: MarketDataState, action: PayloadAction<MarketDataFailedAction>) => {
      state[action.payload.conId] = {
        error: action.payload.error,
        loading: false,
        loaded: true,
        initiallyLoaded: true,
        marketData: undefined
      };
    }
  }
});

const { actions, reducer } = marketDataSlice;
export const { reset, loading, success, fail } = actions;
export default reducer;
