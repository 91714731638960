import { FunctionComponent, useEffect, useState } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { updatePositionAsync } from '../../../store/position/service';
import { Position } from '../../../types/entities';
import { deepCopyPosition } from '../../../utils/position-utils';
import { CustomTabPanel } from '../../ui/CustomTabPanel';
import DialogCloseIconButton from '../../ui/DialogCloseIconButton';

import ClosePositionPanel from './ClosePositionPanel';

import './styles.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
}

const EditPositionDialog: FunctionComponent<Props> = ({ isOpen, onClose, position }: Props) => {
  const { loaded, error } = useAppSelector((gs) => gs.positionState);
  const { securities } = useAppSelector((gs) => gs.securityState);
  const security = securities.find((x) => x.symbol === position.details.contract.symbol);
  const [actionPressed, setActionPressed] = useState(false);

  const [positionForm, setPositionForm] = useState(position);

  useEffect(() => {
    const copy = deepCopyPosition(position);
    setPositionForm(copy);
  }, [position]);

  const setTargetProfit = (targetProfit: number) => {
    const copy = deepCopyPosition(positionForm);
    copy.targetProfit = targetProfit;
    setPositionForm(copy);
  };

  const togglePositionClosed = () => {
    const copy = deepCopyPosition(positionForm);
    copy.closed = !copy.closed;
    setPositionForm(copy);
  };

  const dispatch = useAppDispatch();

  const handleSaveBtnClick = () => {
    setActionPressed(true);
    dispatch(updatePositionAsync(positionForm));
  };

  const handleCloseDialog = () => {
    setActionPressed(false);
    onClose();
  };

  // auto-close on save successfully
  useEffect(() => {
    if (actionPressed && loaded && !error) {
      handleCloseDialog();
    }
  });

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_e: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const subTitle = position.details.underSymbol
    ? `${position.details.underSymbol} - ${position.details.contract.localSymbol}`
    : position.details.contract.localSymbol;

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose} classes={{ paper: 'edit-position-dialog__paper' }}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Box>
          <Typography variant="h6">Edit Position</Typography>
          {security && (
            <Typography variant="body2" sx={{ my: 1 }}>
              {security.name}
            </Typography>
          )}
          <Typography variant="body2">{subTitle}</Typography>
        </Box>
      </DialogTitle>
      <DialogCloseIconButton onClose={handleCloseDialog} />

      <Box sx={{ mx: 2, mb: 1, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          <Tab label="General" />
          <Tab label="Finalize" />
        </Tabs>
      </Box>

      <CustomTabPanel value={selectedTab} index={0}>
        <Box>
          <DialogContent sx={{ p: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="caption" sx={{ mb: 0.5 }} component="div">
                  SCHEDULE STATUS
                </Typography>
                <FormControl fullWidth>
                  <ButtonGroup variant="outlined" disableElevation fullWidth>
                    <Button onClick={togglePositionClosed} variant={!positionForm.closed ? 'contained' : 'outlined'}>
                      Active
                    </Button>
                    <Button onClick={togglePositionClosed} variant={positionForm.closed ? 'contained' : 'outlined'}>
                      Closed
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    name="targetpnl"
                    size="small"
                    label="Target Profit"
                    variant="outlined"
                    type="number"
                    value={positionForm.targetProfit}
                    onChange={(e) => setTargetProfit(Number(e.target.value))}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 2, flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: 1 }}>
              <Button variant="contained" color="primary" onClick={handleSaveBtnClick}>
                Save
              </Button>
              <Button onClick={handleCloseDialog} variant="outlined">
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <ClosePositionPanel onClose={onClose} position={position} />
      </CustomTabPanel>
    </Dialog>
  );
};

export default EditPositionDialog;
