import { FunctionComponent } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

interface Props {
  title: string;
  collapsed?: boolean;
  onChange?: (e: React.SyntheticEvent<Element, Event>, expanded: boolean) => void;
  my?: number | undefined;
  p?: number | undefined;
  children?: React.ReactNode;
}

const AccordionWrapper: FunctionComponent<Props> = ({ title, collapsed, onChange, my = 0, p = 0, children }) => {
  return (
    <Accordion disableGutters={true} expanded={!collapsed} onChange={onChange} sx={{ my, backgroundColor: 'transparent' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionWrapper;
