import { FunctionComponent } from 'react';

import { Button, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';

import { FundamentalNote } from '../../types/entities';
import { FundamentalTrend, FundamentalType, PeriodMonthPart } from '../../types/enums';
import { getMonthName } from '../../utils/month-utils';

interface Props {
  notes: FundamentalNote[];
  onOpenDialog: (note?: FundamentalNote) => void;
}

const FundamentalNotePanel: FunctionComponent<Props> = ({ notes, onOpenDialog }) => {
  const getTrendChip = (trend: FundamentalTrend) => {
    let color: 'warning' | 'success' | 'error' = 'warning';
    switch (trend) {
      case FundamentalTrend.Volatile:
        color = 'warning';
        break;
      case FundamentalTrend.Bullish:
        color = 'success';
        break;
      case FundamentalTrend.Bearish:
        color = 'error';
        break;
    }
    return <Chip color={color} variant="outlined" size="small" label={FundamentalTrend[trend]} />;
  };

  const getPeriod = (note: FundamentalNote) => {
    if (note.type === FundamentalType.Period) {
      return PeriodMonthPart[note.periodMonthPart];
    } else {
      return `${note.eventDate}.`;
    }
  };

  const noteRecords = notes
    .slice()
    .sort((a, b) => (a.symbol < b.symbol ? -1 : 1))
    .map((note) => {
      return (
        <TableRow key={note.id} sx={{ whiteSpace: 'nowrap' }}>
          <TableCell>{FundamentalType[note.type]}</TableCell>
          <TableCell>{getMonthName(note.periodMonth)}</TableCell>
          <TableCell align="center">{getPeriod(note)}</TableCell>
          <TableCell align="center">{getTrendChip(note.trend)}</TableCell>
          <TableCell align="center">
            {note.urlRef && (
              <Link to={note.urlRef} target="_blank">
                <Button size="small" variant="text" color="info">
                  Link
                </Button>
              </Link>
            )}
          </TableCell>
          <TableCell align="right">
            <Button size="small" onClick={() => onOpenDialog(note)}>
              Edit
            </Button>
          </TableCell>
        </TableRow>
      );
    });

  return (
    <TableContainer sx={{ mb: 2 }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Month</TableCell>
            <TableCell align="center">Part</TableCell>
            <TableCell align="center">Trend</TableCell>
            <TableCell align="center">Source</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{noteRecords}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default FundamentalNotePanel;
