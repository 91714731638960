import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderType, PositionOld } from '../../types/entities';

import { OrderSummaryState, initialOrderSummaryState, initialOrderSummeryBySymbolState } from './state';

export type AddOrUpdateOrderAction = {
  symbol: string;
  order: PositionOld;
};

export type RemoveOrderAction = {
  symbol: string;
  conId: number;
  orderType: OrderType;
};

export type SetGroupAction = {
  symbol: string;
  groupId: string | undefined;
};

const securityContractSlice = createSlice({
  name: 'order-summary',
  initialState: initialOrderSummaryState,
  reducers: {
    reset: () => initialOrderSummaryState,
    addSummaryOrder: (state: OrderSummaryState, action: PayloadAction<AddOrUpdateOrderAction>) => {
      const curr = state[action.payload.symbol] || initialOrderSummeryBySymbolState;
      state[action.payload.symbol] = {
        ...curr,
        orders: [...curr.orders, action.payload.order]
      };
    },
    updateSummaryOrder: (state: OrderSummaryState, action: PayloadAction<AddOrUpdateOrderAction>) => {
      const curr = state[action.payload.symbol] || initialOrderSummeryBySymbolState;
      const item = curr.orders.find((x) => x.conId === action.payload.order.conId && x.orderType === action.payload.order.orderType);
      if (!item) {
        return;
      }
      const index = curr.orders.indexOf(item);
      curr.orders[index] = { ...action.payload.order };
    },
    removeSummaryOrder: (state: OrderSummaryState, action: PayloadAction<RemoveOrderAction>) => {
      const curr = state[action.payload.symbol] || initialOrderSummeryBySymbolState;
      const findByConId = (x: PositionOld) => x.conId === action.payload.conId && x.orderType === action.payload.orderType;
      const index = curr.orders.findIndex(findByConId);
      curr.orders.splice(index, 1);
      state[action.payload.symbol] = {
        ...curr,
        orders: [...curr.orders]
      };
    },
    setGroupId: (state: OrderSummaryState, action: PayloadAction<SetGroupAction>) => {
      const curr = state[action.payload.symbol] || initialOrderSummeryBySymbolState;
      state[action.payload.symbol] = {
        ...curr,
        orders: [...curr.orders],
        groupId: action.payload.groupId
      };
    }
  }
});

const { actions, reducer } = securityContractSlice;
export const { reset, addSummaryOrder, updateSummaryOrder, removeSummaryOrder, setGroupId } = actions;
export default reducer;
