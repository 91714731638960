import { FunctionComponent } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';

import { TABLE_BORDER_COLOR } from '../../../../assets/colors';
import { useAppDispatch } from '../../../../store/hooks';
import { cancelOpenOrderAsync } from '../../../../store/open-order/service';
import { OpenOrderReport, Position } from '../../../../types/entities';
import { formatNum } from '../../../../utils/currency-utils';
import { useConfirm } from '../../../hooks/useConfirm';
import ShowGraphIconButton from '../../../ui/ShowGraphIconButton';

interface Props {
  position: Position;
  orders: OpenOrderReport[];
}

const PositionOrderTable: FunctionComponent<Props> = ({ position, orders }: Props) => {
  const [confirm, ConfirmDialog] = useConfirm();
  const dispatch = useAppDispatch();

  const handleCancelOrderBtnClick = async (event: React.MouseEvent<HTMLButtonElement>, openOrder: OpenOrderReport) => {
    event.stopPropagation();
    if (await confirm('Are you sure you want to cancel this order?')) {
      dispatch(cancelOpenOrderAsync(openOrder));
    }
  };

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Action</TableCell>
        <TableCell>Type</TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center">Size</TableCell>
        <TableCell align="right">Trigger Price</TableCell>
        <TableCell align="center">Cond.</TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center">Status</TableCell>
        <TableCell align="center">Canceled</TableCell>
        <TableCell align="center"></TableCell>
      </TableRow>
    </TableHead>
  );

  const orderRows = orders.map((order: OpenOrderReport, i) => {
    const triggerPrice = order.triggerPrice != 0 ? `$${formatNum(order.triggerPrice)}` : '-';

    let orderInfoText = 'N/A';
    switch (order.type) {
      case 'MKT':
        orderInfoText = order.action === 'BUY' ? 'Adds to position-size when filled' : 'Subtracts from position-size when filled';
        break;
      case 'LMT':
        orderInfoText =
          order.action === 'BUY'
            ? 'Adds to position-size when price falls below trigger price'
            : 'Subtracts from position-size when price rises above trigger price';
        break;
      case 'STP':
        orderInfoText =
          order.action === 'BUY'
            ? 'Adds to position-size when price rises above trigger price'
            : 'Subtracts from position-size when price falls below trigger price';
        break;
    }

    const conditionText = order.usePriceCondition ? (order.isAbove ? 'ABOVE' : ' BELOW') : '-';

    const limitPrice = order.type === 'LMT' && order.triggerPrice !== 0 ? order.triggerPrice : undefined;
    const stopPrice = order.type === 'STP' && order.triggerPrice !== 0 ? order.triggerPrice : undefined;

    return (
      <TableRow key={`${order.conId}-${i}`} sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
        <TableCell>{order.action}</TableCell>
        <TableCell>{order.type}</TableCell>
        <TableCell align="center">
          <Tooltip title={orderInfoText} placement="top" arrow>
            <IconButton size="small" color="info">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell align="center">{order.size}</TableCell>
        <TableCell align="right">{triggerPrice}</TableCell>
        <TableCell align="center">{conditionText}</TableCell>
        <TableCell align="center">
          <ShowGraphIconButton position={position} limitPrice={limitPrice} stopPrice={stopPrice} />
        </TableCell>
        <TableCell align="center">{order.orderStatus}</TableCell>
        <TableCell align="center">{order.canceled ? 'Y' : 'N'}</TableCell>
        <TableCell align="right" sx={{ width: '80px', px: 1, borderLeft: `1px solid ${TABLE_BORDER_COLOR}` }}>
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={(e) => handleCancelOrderBtnClick(e, order)}
            disabled={order.size === 0}
          >
            Cancel
          </Button>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <TableContainer>
        <Table size="small">
          {tableHeader}
          <TableBody>{orderRows}</TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog />
    </>
  );
};

export default PositionOrderTable;
