export const toTabIndex = (tabValue: string) => {
  switch (tabValue.toLowerCase()) {
    case 'fundamentals':
      return 1;
    case 'contracts':
      return 2;
    case 'options':
      return 3;
    case 'indicators':
      return 4;
    case 'graphs':
      return 5;
    case 'positions':
      return 6;
    default:
      return 0;
  }
};

export const fromTabIndex = (index: number) => {
  switch (index) {
    case 1:
      return 'fundamentals';
    case 2:
      return 'contracts';
    case 3:
      return 'options';
    case 4:
      return 'indicators';
    case 5:
      return 'graphs';
    case 6:
      return 'positions';
    default:
      return 'general';
  }
};
