import { FunctionComponent, useEffect, useState } from 'react';

import {
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Divider,
  Box,
  CircularProgress,
  Typography,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  ButtonGroup,
  Card,
  Tabs,
  Tab,
  FormHelperText
} from '@mui/material';

import { useCheckMarginAsync } from '../../components/hooks/useCheckMarginAsync';
import { useTestRunAveragingTask } from '../../components/hooks/useTestRunAveragingTask';
import GutterBox from '../../components/ui/GutterBox';
import { useAppDispatch } from '../../store/hooks';
import { updatePositionAsync } from '../../store/position/service';
import { Position, PositionPortfolio, Averaging, PlaceOrderParams, OrderState, SimulatorSettings } from '../../types/entities';
import { Logger } from '../../utils/Logger';
import { deepCopyPosition } from '../../utils/position-utils';

import BacktestPanel from './BacktestPanel';
import SimulatorPanel from './SimulatorPanel';

interface Props {
  position: Position;
  portfolio: PositionPortfolio;
}
const AutomationPanel: FunctionComponent<Props> = ({ position, portfolio }) => {
  const initialAveraging: Averaging = {
    useAveraging: false,
    bearish: false,
    upChangePct: 0,
    downChangePct: 0,
    averageOnLowestPriceOnly: false,
    useExponentialAveraging: false,
    initialSize: portfolio.size,
    orderSize: 0,
    maxSize: 0,
    marginRequirement: 0,
    keepOpen: false
  };

  const [targetProfit, setTargetProfit] = useState(position.targetProfit);
  const [averagingForm, setAveragingForm] = useState(position.averaging ?? initialAveraging);
  useEffect(() => {
    setTargetProfit(position.targetProfit);
    const copy = position.averaging ? { ...position.averaging } : initialAveraging;
    setAveragingForm(copy);
  }, [position]);

  const { checkMarginAsync, loading: checkMarginLoading } = useCheckMarginAsync();

  const [selectedTab, setSelectedTab] = useState(1);
  const handleTabChange = (_e: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const dispatch = useAppDispatch();

  const isDeveloperMode = process.env.REACT_APP_DEVELOPER_MODE === 'true';
  const { testRunAveragingTask, loading: runTaskLoading } = useTestRunAveragingTask();
  const handleTestRunBtnClick = () => {
    testRunAveragingTask(position.details.contract.conId);
  };

  const handleSaveBtnClick = () => {
    const copy = deepCopyPosition(position);
    copy.targetProfit = targetProfit;
    copy.averaging = averagingForm;

    // Depricated..
    // const resetDate = copy.averaging.resetAveragePriceDate;
    // if (resetDate) {
    //   const isDate = resetDate && resetDate instanceof Date;
    //   const resetDateString = isDate ? resetDate.toISOString() : resetDate;
    //   copy.averaging.resetAveragePriceDate = resetDateString; // redux: not accepting non-serializable
    // }

    dispatch(updatePositionAsync(copy));
  };

  const onSaveSimulatorSettings = (sim: SimulatorSettings) => {
    const copy = deepCopyPosition(position);
    copy.simulator = sim;
    dispatch(updatePositionAsync(copy));
  };

  const onSaveBacktestSettings = (sim: SimulatorSettings) => {
    const copy = deepCopyPosition(position);
    copy.simulator = sim;
    dispatch(updatePositionAsync(copy));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateForm = (field: keyof Averaging, value: any) => {
    const copy = {
      ...averagingForm,
      [field]: value
    } as Averaging;
    setAveragingForm(copy);
  };

  /*
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSetResetAveragePriceDate = (date: Date | undefined) => {
    const copy = {
      ...averagingForm,
      resetAveragePriceDate: date
    } as Averaging;
    setAveragingForm(copy);
  };
  */

  useEffect(() => {
    if (averagingForm.initialSize === 0) {
      handleUpdateForm('initialSize', portfolio.size);
    }
  }, [portfolio]);

  // todo: fix loading variable for update margin req
  const handleUpdateMarginReqBtnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const params = {
      details: {
        ...position.details,
        contract: { ...position.details.contract }
      },
      orderAction: averagingForm.bearish ? 'SELL' : 'BUY',
      orderType: 'MKT',
      orderSize: averagingForm.maxSize,
      triggerPrice: 0,
      outsideRth: false,
      tif: 'GTC',
      usePriceCondition: false,
      underlyingTriggerPrice: 0,
      isAbove: false
    } as PlaceOrderParams;
    checkMarginAsync(params, (orderState: OrderState) => {
      Logger.log(orderState);
      const { initMarginChange } = orderState;
      const initMarginChangeNum = Number(initMarginChange) || 0;
      const initMarginChangeNumFormatted = Number(initMarginChangeNum.toFixed(0));
      handleUpdateForm('marginRequirement', initMarginChangeNumFormatted);
    });
  };

  // options are bullish based on BUY-CALL and SELL-PUT
  const right = position.details.contract.right;
  const bullishLabel = !!right ? `BUY ${right === 'C' ? 'CALL' : 'PUT'}` : 'Bullish';
  const bearishLabel = !!right ? `SELL ${right === 'C' ? 'CALL' : 'PUT'}` : 'Bearish';

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <Grid p={2} container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ ml: 1 }}
                  onChange={() => handleUpdateForm('useAveraging', !averagingForm.useAveraging)}
                  checked={averagingForm.useAveraging}
                />
              }
              label="Use Automation"
            />
          </Grid>
        </Grid>

        <Divider />

        <Typography variant="button" component="div" pt={2} pl={2}>
          Thresholds
        </Typography>
        <Grid p={2} container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Flattening Pct"
              variant="outlined"
              type="number"
              value={averagingForm.upChangePct}
              onChange={(e) => handleUpdateForm('upChangePct', Number(e.target.value))}
              helperText="Sell-high trigger"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Averaging Pct"
              variant="outlined"
              type="number"
              value={averagingForm.downChangePct}
              onChange={(e) => handleUpdateForm('downChangePct', Number(e.target.value))}
              helperText="Buy-low trigger"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ ml: 1 }}
                  onChange={() => handleUpdateForm('useExponentialAveraging', !averagingForm.useExponentialAveraging)}
                  checked={averagingForm.useExponentialAveraging}
                />
              }
              label={<Typography variant="body2">Use exponential averaging (averaging pct doubling for every avg trade)</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ ml: 1 }}
                  onChange={() => handleUpdateForm('averageOnLowestPriceOnly', !averagingForm.averageOnLowestPriceOnly)}
                  checked={averagingForm.averageOnLowestPriceOnly}
                />
              }
              label={<Typography variant="body2">Average on lowest price only</Typography>}
            />
          </Grid>
        </Grid>

        <Divider />
        <Typography variant="button" component="div" pt={2} pl={2}>
          ORDER SETUP
        </Typography>
        <Grid p={2} container spacing={2}>
          <Grid item xs={12}>
            <ButtonGroup variant="outlined" disableElevation fullWidth>
              <Button onClick={() => handleUpdateForm('bearish', false)} variant={!averagingForm.bearish ? 'contained' : 'outlined'}>
                {bullishLabel}
              </Button>
              <Button onClick={() => handleUpdateForm('bearish', true)} variant={averagingForm.bearish ? 'contained' : 'outlined'}>
                {bearishLabel}
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid p={2} container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Initial Size"
              variant="outlined"
              type="number"
              value={averagingForm.initialSize}
              onChange={(e) => handleUpdateForm('initialSize', Number(e.target.value))}
              helperText="Initial Trade Size"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Order Size"
              variant="outlined"
              type="number"
              value={averagingForm.orderSize}
              onChange={(e) => handleUpdateForm('orderSize', Number(e.target.value))}
              helperText="Flattenging or averaging order size"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Max Size"
              variant="outlined"
              type="number"
              value={averagingForm.maxSize}
              onChange={(e) => handleUpdateForm('maxSize', Number(e.target.value))}
              helperText="Threshold for number of buy-ins"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel>Target Profit</InputLabel>
              <OutlinedInput
                value={targetProfit}
                onChange={(e) => setTargetProfit(Number(e.target.value))}
                size="small"
                type="number"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label="Target Profit"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Divider />
        <Box sx={{ mt: 2, mx: 2, display: 'flex', justifyContent: 'center', alignItems: 'flex-start', gap: 1 }}>
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel>Margin Requirement</InputLabel>
            <OutlinedInput
              value={averagingForm.marginRequirement}
              onChange={(e) => handleUpdateForm('marginRequirement', Number(e.target.value))}
              size="small"
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Margin Requirement"
            />
            <FormHelperText>Bases on max size</FormHelperText>
          </FormControl>
          <Button sx={{ mt: 0.25 }} variant="outlined" onClick={handleUpdateMarginReqBtnClick}>
            <Box sx={{ display: 'inline-box', whiteSpace: 'nowrap' }}>Margin Requirement</Box>{' '}
            {checkMarginLoading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
          </Button>
        </Box>
        <Box sx={{ m: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button variant="contained" onClick={() => handleSaveBtnClick()}>
            Save
          </Button>
        </Box>
      </Card>

      {isDeveloperMode && (
        <GutterBox sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="text" onClick={() => handleTestRunBtnClick()}>
            Debug Run AVG Task {runTaskLoading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
          </Button>
        </GutterBox>
      )}

      {/* 
      <Card sx={{ mt: 2 }}>
        <Alert sx={{ m: 2 }} variant="outlined" color="info">
          <b>Denne funksjonaliten er fjernet så foreløpig.</b>
          <p>
            Det er flere grunner til det; hvis det finnes trades for en flat-position, så må disse eksluderes fra average-price, fordi det
            blir vanskeligere å regne ut samlet profitt. Hvis price action fortsetter å gå oppover, så vil price change pct aldri trigge en
            ny averaging. Derfor må finne price change pct basert på en moving price, typisk last 3-days eller weekly average.
          </p>
        </Alert>
        <Grid p={2} container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ ml: 1 }}
                  onChange={() => handleUpdateForm('keepOpen', !averagingForm.keepOpen)}
                  checked={averagingForm.keepOpen}
                />
              }
              label={<Typography variant="body2">Keep Open (flat positon will not close)</Typography>}
            />
          </Grid>
        </Grid>
        <Divider />
        <Typography variant="button" component="div" pt={2} pl={2}>
          Averaging Price
        </Typography>
        <Grid p={2} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SelectDateTimeControl
              label="Reset Average Price Date"
              selectedValue={averagingForm.resetAveragePriceDate ? new Date(averagingForm.resetAveragePriceDate) : undefined}
              onDateSelected={handleSetResetAveragePriceDate}
            />
            <FormHelperText>Only trades after reset date are basis for the averaging price</FormHelperText>
          </Grid>
        </Grid>
      </Card>
      */}

      <Box sx={{ my: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          <Tab label="Simulator" />
          <Tab label="Backtester" />
        </Tabs>
      </Box>

      {selectedTab === 0 && <SimulatorPanel position={position} averagingForm={averagingForm} onSave={onSaveSimulatorSettings} />}
      {selectedTab === 1 && (
        <BacktestPanel position={position} portfolio={portfolio} averagingForm={averagingForm} onSave={onSaveBacktestSettings} />
      )}
    </>
  );
};

export default AutomationPanel;
