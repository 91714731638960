// v3: Removed min number of turns

import { Averaging, SimulatorSettings } from '../../../types/entities';
import { Logger } from '../../../utils/Logger';

const getRandomPercent = () => {
  return Math.floor(Math.random() * 101); // Generates a number between 0 and 100 (inclusive)
};

const getPriceAction = (price: number, biasPct: number, volatilityPct: number, stockPriceChangePct: number, useBiasRange: boolean) => {
  // meaning: change in stock price will normalize the bias pct
  if (useBiasRange) {
    biasPct -= stockPriceChangePct;
  }

  let fluctuationAmount = (price * volatilityPct) / 100;

  const priceActionDirectionPct = getRandomPercent();
  const isIncreasing = priceActionDirectionPct < biasPct;
  if (!isIncreasing) {
    fluctuationAmount *= -1;
  }
  price += fluctuationAmount;
  // console.log('>>> ', biasPct, stockPriceChangePct, price);
  return price;
};

export interface AvgTrade {
  filledPrice: number;
  pnl: number;
  multiplier: number;
  size: number;
  type: 'averaging' | 'flatten';
}

export interface AveragingResult {
  trades: AvgTrade[];
  sumPnl: number;
  avgPnl: number;
  numFlattening: number;
  numAveraging: number;
  totalSize: number;
  turns: number;
  maxPositionSize: number;
}

const ONE_MILLION_TURNS = 100000;

const getAveragePrice = (initialMarketPrice: number, trades: AvgTrade[]) => {
  if (trades.length === 0) {
    return initialMarketPrice;
  }
  // average price based on averaging trades only
  const filteredTrades = trades.filter((x) => x.type === 'averaging');
  if (filteredTrades.length === 0) {
    return initialMarketPrice;
  }
  return filteredTrades.map((x) => x.filledPrice).reduce((a, b) => a + b, 0) / filteredTrades.length;
};

export const testAveraging = (multiplier: number, averaging: Averaging, simulator: SimulatorSettings): AveragingResult => {
  const { upChangePct, initialSize, orderSize, maxSize, useExponentialAveraging } = averaging;

  const { initialTradePrice, volatilityPct, trendBiasPct, useBiasRangeBound } = simulator;

  let turns = 0;
  let maxPositionSize = 0;
  let priceAction = initialTradePrice;
  const trades: AvgTrade[] = [];

  if (initialSize > 0) {
    const numTrades = initialSize / orderSize;
    for (let i = 0; i < numTrades; i++) {
      trades.push({
        filledPrice: initialTradePrice,
        pnl: 0,
        multiplier: multiplier,
        size: orderSize,
        type: 'averaging'
      });
    }
  }

  // for (let i = 0; i < 20; i++) {
  while (true) {
    turns++;
    if (turns >= ONE_MILLION_TURNS) {
      Logger.log('Too many turns: ' + turns);
      break;
    }

    const averagingSize = trades
      .filter((x) => x.type === 'averaging')
      .map((x) => x.size)
      .reduce((a, b) => a + b, 0);

    const flatteningSize = trades
      .filter((x) => x.type === 'flatten')
      .map((x) => x.size)
      .reduce((a, b) => a + b, 0);

    const positionSize = averagingSize - flatteningSize;
    if (positionSize <= 0) {
      break;
    }

    maxPositionSize = positionSize > maxPositionSize ? positionSize : maxPositionSize;

    const averagingPrice = getAveragePrice(initialTradePrice, trades);
    const previousPriceChangePct = ((priceAction - averagingPrice) / averagingPrice) * 100;
    priceAction = getPriceAction(priceAction, trendBiasPct, volatilityPct, previousPriceChangePct, useBiasRangeBound);
    if (priceAction <= 0) {
      break;
    }

    let downChangePct = averaging.downChangePct;
    if (useExponentialAveraging) {
      const numAveragingTrades = trades.filter((x) => x.type === 'averaging').length;
      if (numAveragingTrades > 0) {
        downChangePct = downChangePct * numAveragingTrades;
      }
    }

    const diff = priceAction - averagingPrice;
    const diffPct = (diff / averagingPrice) * 100;
    if (diffPct >= upChangePct && positionSize > 0) {
      const trade: AvgTrade = {
        filledPrice: priceAction,
        pnl: diff * multiplier * orderSize,
        multiplier: multiplier,
        size: orderSize,
        type: 'flatten'
      };
      trades.push(trade);
    } else if (diffPct <= downChangePct * -1 && positionSize < maxSize) {
      const trade: AvgTrade = {
        filledPrice: priceAction,
        pnl: 0,
        multiplier: multiplier,
        size: orderSize,
        type: 'averaging'
      };

      if (averaging.averageOnLowestPriceOnly) {
        const averagingPrices = trades.filter((x) => x.type === 'averaging').map((x) => x.filledPrice);
        const minAvgPrice = Math.min(...averagingPrices);
        if (priceAction < minAvgPrice) {
          trades.push(trade);
        }
      } else {
        trades.push(trade);
      }
    }
  }

  const sum = trades.map((x) => x.pnl).reduce((a, b) => a + b, 0);
  const sumTrimmed = Math.round(sum);

  const numAveraging = trades.filter((x) => x.type === 'averaging').length;

  const numFlattening = trades.filter((x) => x.type === 'flatten').length;

  const avgPnl = numFlattening > 0 ? sum / numFlattening : 0;
  const avgPnlTrimmed = Math.round(avgPnl);

  return {
    trades,
    sumPnl: sumTrimmed,
    avgPnl: avgPnlTrimmed,
    numFlattening: numFlattening,
    numAveraging: numAveraging,
    totalSize: trades.length,
    turns,
    maxPositionSize
  };
};
