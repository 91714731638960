import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { loadDashboard } from '../../api';
import { Logger } from '../../utils/Logger';
import { success as accountInfoSuccess } from '../account-info/reducer';
import { success as openOrderSuccess } from '../open-order/reducer';
import { success as positionSuccess } from '../position/reducer';
import { success as positionPortfolioSuccess } from '../position-portfolio/reducer';
import { success as priceAlertSuccess } from '../price-alert/reducer';

import { loading, success, fail } from './reducer';

export const loadDashboardAsync = (ignoreMissingUnderMarketPrice: boolean, frozen: boolean) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    loadDashboard(ignoreMissingUnderMarketPrice, frozen)
      .then((response) => {
        const dashboard = response.data;
        Logger.log(dashboard);
        dispatch(success());
        dispatch(positionSuccess(dashboard.positions));
        dispatch(positionPortfolioSuccess(dashboard.positionPortfolios));
        dispatch(openOrderSuccess(dashboard.openOrders));
        dispatch(accountInfoSuccess(dashboard.accountInfo));
        dispatch(priceAlertSuccess(dashboard.priceAlerts));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
